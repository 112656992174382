import Dexie from 'dexie';

// Initialize the Dexie database
const db = new Dexie('rectrix-link-travel-agent');

// Define the database schema
db.version(1).stores({
    transfer_types: '&uuid, transfer_type_special_name, transfer_type_special_slug, start_time, return_time, sic_start_time, sic_return_time, is_private, is_sic_per_head, is_sic_per_hotel, is_private_tour_guide, is_sic_tour_guide, created_at, updated_at, deleted_at, location_uuid',
    locations: '&uuid, description, main_text, secondary_text, created_at, updated_at, deleted_at, detail_uuid',
    location_details: '&uuid, formatted_address, latitude, longitude, created_at, updated_at, deleted_at',

    dtc_requests: '&uuid, requester_uuid, user_uuid, status, created_at, updated_at, deleted_at',
    dtc_request_details: '&uuid, dtc_request_uuid, price, sic, transfer_type_special_uuid, created_at, updated_at, deleted_at',
    users: '&uuid, first_name, last_name, email_address, image_path, email_verified_at, remarks, created_at, updated_at, deleted_at, role, status, contact_numbers',

    jobs: '&uuid, no_of_adults, no_of_childs, no_of_infants, job_status, priority, stays, tourist_uuid, user_uuid, created_at, updated_at, deleted_at',
    transfers: '&uuid, pick_up_time, attendances, from_uuid, to_uuid, remarks, tickets, transfer_type_name, transfer_type_slug, transfer_type_special_uuid, created_at, updated_at, deleted_at',
    stays: '&uuid, job_uuid, start_date, end_date, stay_uuid, created_at, updated_at, deleted_at',

    job_transfers: '&uuid, job_uuid, transfer_uuid, children, created_at, updated_at, deleted_at',

    assigned_job_requests: '&uuid, status, requester_uuid, user_uuid, transfer_type_special_request_uuid, created_at, updated_at, deleted_at',
    assigned_job_request_details: '&uuid, assigned_job_request_uuid, job_transfer_uuid, created_at, updated_at, deleted_at',

    notifications: '&uuid, user_uuid, title, message, notification_type, notification, is_log, starts_at, expires_at, created_at, updated_at, deleted_at',

    // Priority Infomation
    job_priority_types: '&uuid, type_name, type_slug, type_desc, created_at, updated_at, deleted_at',
    job_pings: '&uuid,  ping_name, ping_slug, ping_desc, created_at, updated_at, deleted_at',
    job_payment_types: '&uuid, type_name, type_slug, created_at, updated_at, deleted_at',
    vehicle_types: '&uuid, type_name, type_slug, created_at, updated_at, deleted_at',
});

export default db;