<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Requests</h1>
                <p>Dashboard > Requests</p>
            </div>

            <div class="tabs">
                <div class="horizontal-nav">
                    <ul class="nav-list">
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('pending')"
                                :class="{ 'button-1': currentFilter === 'pending' }">Pending</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('approved')"
                                :class="{ 'button-1': currentFilter === 'approved' }">Active</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('rejected')"
                                :class="{ 'button-1': currentFilter === 'rejected' }">Rejected</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('cancelled')"
                                :class="{ 'button-1': currentFilter === 'cancelled' }">Cancelled</a>
                        </li>
                    </ul>
                </div>

                <div class="table-container">
                    <v-data-table v-if="data && data.length" :headers="headers" :items="data" :items-per-page="-1"
                        disable-pagination hide-default-footer>
                        <template v-slot:[`item.index`]="{ index }">
                            <span>{{ index + 1 }}.</span>
                        </template>
                        <template v-slot:[`item.assigned_to`]="{ item }">
                            <div class="user">
                                <div class="image-container">
                                    <Image :imagePath="item.user.image_path"></Image>
                                </div>
                                <p>{{ (item.user.first_name ?? '') + ' ' + item.user.last_name }}</p>
                            </div>
                        </template>
                        <template v-slot:[`item.guest_names`]="{ item }">
                            <div class="user" v-for="guest in item.guests" :key="guest.uuid">
                                <div class="image-container">
                                    <Image :imagePath="guest.image_path"></Image>
                                </div>
                                <p>{{ (guest.first_name ?? '') + ' ' + guest.last_name }}</p>
                            </div>
                        </template>

                        <template v-slot:[`item.date`]="{ item }">
                            <span v-if="item.details[0]?.job_transfer?.transfer?.pick_up_time">
                                {{
                                    this.$moment(item.details[0].job_transfer.transfer.pick_up_time).format('DD MMM yyyy')
                                }}
                            </span>
                            <span v-else>-</span>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span>{{ this.$moment(item.created_at).format('DD MMM yyyy') }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <a href="" class="button-3"
                                @click.prevent="this.$router.push('/requests/' + item.uuid)">View</a>
                        </template>
                    </v-data-table>
                    <table class="no-data" v-else>
                        <thead>
                            <tr>
                                <td class="td-start">There are currently no data</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'RequestsView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            headers: [
                { title: 'No.', value: 'index', sortable: false },
                { title: 'Assigned To', value: 'assigned_to', sortable: true },
                { title: 'Guest Name', value: 'guest_names', sortable: false },
                { title: 'Priority Date', value: 'date', sortable: true },
                { title: 'Requested On', value: 'created_at', sortable: true },
                { title: 'Action', value: 'action', sortable: false },
            ],
            orgData: [],
            data: [],
            currentFilter: 'pending',
            subscription: null,
        };
    },
    created() {
        indexedDBService.getAssignedJobRequestsData();
        this.fetchData()
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        fetchData() {
            const observableData = liveQuery(async () => {
                // Fetch all necessary data
                const assignedJobRequests = await db.assigned_job_requests.orderBy('created_at').reverse().toArray();
                const assignedJobRequestDetails = await db.assigned_job_request_details.toArray();
                const jobTransfers = await db.job_transfers.toArray();
                const transfers = await db.transfers.toArray();
                const jobs = await db.jobs.toArray();
                const users = await db.users.toArray();
                const stays = await db.stays.toArray();

                // Create maps for easy lookup
                const userMap = new Map(users.map(user => [user.uuid, user]));
                const transferMap = new Map(transfers.map(transfer => [transfer.uuid, transfer]));
                const jobTransferMap = new Map(jobTransfers.map(jt => [jt.uuid, jt]));

                // Create a map of jobs with their guests
                const jobMap = new Map();
                jobs.forEach(job => {
                    // For each job, find its tourist (guest) from users
                    const guest = job.tourist_uuid ? userMap.get(job.tourist_uuid) : null;
                    jobMap.set(job.uuid, {
                        ...job,
                        guest: guest
                    });
                });

                // Process each assigned job request
                const assignedJobRequestsWithDetails = assignedJobRequests.map(request => {
                    const user = userMap.get(request.user_uuid) || null;
                    const requester = userMap.get(request.requester_uuid) || null;

                    // Get request details and their related data
                    const requestDetails = assignedJobRequestDetails
                        .filter(detail => detail.assigned_job_request_uuid === request.uuid)
                        .map(detail => {
                            // Get job transfer and its related transfer
                            const jobTransfer = jobTransferMap.get(detail.job_transfer_uuid);
                            const transfer = jobTransfer ? transferMap.get(jobTransfer.transfer_uuid) : null;

                            // Find related job through job transfer
                            const job = jobTransfer?.job_uuid ? jobMap.get(jobTransfer.job_uuid) : null;

                            // Get stays for the job
                            const jobStays = job ? stays.filter(stay => stay.job_uuid === job.uuid) : [];

                            return {
                                ...detail,
                                job_transfer: {
                                    ...jobTransfer,
                                    transfer: transfer
                                },
                                job,
                                stays: jobStays
                            };
                        });

                    // Collect all guests from jobs in the details
                    const guests = requestDetails
                        .map(detail => detail.job?.guest)
                        .filter(guest => guest !== null && guest !== undefined);

                    // Remove duplicates based on guest UUID
                    const uniqueGuests = Array.from(new Map(
                        guests.map(guest => [guest.uuid, guest])
                    ).values());

                    return {
                        ...request,
                        user,
                        requester,
                        details: requestDetails,
                        guests: uniqueGuests
                    };
                });

                return assignedJobRequestsWithDetails;
            });

            this.subscription = observableData.subscribe((result) => {
                this.orgData = result;
                this.changeFilter(this.currentFilter);
            });
        },
        changeFilter(input) {
            this.currentFilter = input
            this.data = this.orgData
            if (this.currentFilter === 'pending') {
                this.data = this.orgData.filter((a) => a.status === 'Pending' && a.deleted_at === null)
            } else if (this.currentFilter === 'rejected') {
                this.data = this.orgData.filter((a) => a.status === 'Rejected' && a.deleted_at === null)
            } else if (this.currentFilter === 'approved') {
                this.data = this.orgData.filter((a) => a.status === 'Approved' && a.deleted_at === null)
            } else {
                this.data = this.orgData.filter((a) => a.deleted_at !== null)
            }
            console.log(this.data)
        },
        getEarliestDate(stays) {
            if (!stays || stays.length === 0) return 'N/A';
            const dates = stays.map(stay => new Date(stay.start_date));
            const earliestDate = new Date(Math.min(...dates));
            return earliestDate;
        },
    },
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
