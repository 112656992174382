<template>
    <section id="content" class="home">
        <div class="main-content">

            <a href="" class="logo">
                <img src="@/assets/logo/Main_1.png" alt="logo" class="full-logo">
            </a>

            <div class="title pa-1">
                <h1>Register</h1>
                <p>Enter your details to create an account</p>
            </div>

            <div class="register-form">
                <v-form ref="form" id="register-form" @submit.prevent="register">

                    <div class="display-image">
                        <v-col cols="12" class="pa-1 pb-3 text-center">
                            <v-avatar size="100" @click="triggerFileInput" class="avatar-container">
                                <template v-if="displayImage">
                                    <img :src="displayImage" alt="Display Image" class="avatar-image" />
                                    <p class="edit-text">Edit</p>
                                </template>
                                <template v-else>
                                    <v-icon size="48">mdi-camera</v-icon>
                                </template>
                            </v-avatar>
                            <v-file-input id="fileUpload" v-model="displayImageFile" accept=".jpg,.jpeg,.png"
                                label="Upload Display Image" @change="onImageChange" prepend-icon="mdi-camera"
                                style="display: none;"></v-file-input>
                        </v-col>
                    </div>

                    <div class="company-name d-flex w-100">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Company Name" v-model="companyName" :rules="companyNameValidation"
                                placeholder="Rectrix Link Pte. Ltd."></v-text-field>
                        </v-col>
                    </div>

                    <div class="contact-number">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-phone-input v-model="contactNumber" :rules="contactNumberValidation" />
                        </v-col>
                    </div>

                    <div class="email-address">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Email Address" v-model="emailAddress" :rules="emailValidation"
                                placeholder="john.doe@example.com"></v-text-field>
                        </v-col>
                    </div>

                    <div class="password">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Password" v-model="password" :rules="passwordValidation"
                                placeholder="********" type="password"></v-text-field>
                        </v-col>
                    </div>

                    <div class="confirm-password">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Confirm Password" v-model="confirmPassword"
                                :rules="confirmPasswordValidation" ref="confirmPassword" placeholder="********"
                                type="password"></v-text-field>
                        </v-col>
                    </div>

                    <v-col cols="12" class="pa-1 pb-3">
                        <v-btn block class="button-1" type="submit" form="register-form"
                            :loading="isLoading">Register</v-btn>
                    </v-col>

                    <v-col cols="12" class="text-center pa-1">
                        <v-btn variant="text" @click.prevent="this.$router.push('/login')">Already have an account?
                            Login</v-btn>
                    </v-col>
                </v-form>
            </div>

        </div>
    </section>
</template>

<script>
import { mapActions } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

// Toast Notification
import { toast } from 'vue3-toastify';
import { httpPost } from '@/services/http';
import global from '@/global'

export default {
    name: 'RegisterView',
    data() {
        return {
            displayImage: '',
            displayImageFile: null,
            companyName: '',
            contactNumber: '',
            emailAddress: '',
            password: '',
            confirmPassword: '',
            companyNameValidation: [
                v => !!v || 'Company name is required',
            ],
            contactNumberValidation: [
                v => !!v || 'Contact number is required',
            ],
            emailValidation: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordValidation: [
                v => !!v || 'Password is required',
                v => v.length >= 8 || 'Password must be at least 8 characters'
            ],
            confirmPasswordValidation: [
                v => !!v || 'Confirm Password is required',
                v => v === this.password || 'Passwords must match'
            ],
            isLoading: false,
        }
    },
    watch: {
        "password": function (newVal) {
            if (this.confirmPassword != '') {
                this.$refs.confirmPassword.validate(); // Manually trigger the validation for startDate
            }
        }
    },
    methods: {
        onImageChange(e) {
            const file = e.target.files[0];
            if (file) {
                this.displayImage = URL.createObjectURL(file);
            }
        },
        triggerFileInput() {
            let fileUpload = document.getElementById('fileUpload')
            if (fileUpload != null) {
                fileUpload.click()
            }
        },
        async register() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid && isValidPhoneNumber(this.contactNumber)) {
                const parsedNumber = parsePhoneNumberFromString(this.contactNumber);

                let countryCode = parsedNumber.countryCallingCode; // Country code
                let contactNumber = parsedNumber.nationalNumber; // National number

                let data = new FormData();
                data.append('last_name', this.companyName);
                data.append('email_address', this.emailAddress);
                data.append('password', this.password);
                data.append('confirm_password', this.confirmPassword);
                if (this.displayImageFile) {
                    data.append('image_path', this.displayImageFile);
                }

                data.append('contact_number', contactNumber);
                data.append('country_code', countryCode);

                const config = {
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                let id = toast.loading('Please wait...');
                this.isLoading = true
                await httpPost('/api/v1/travel_agents/register', data, id, config)
                    .then(async response => {
                        let data = new FormData();
                        data.append('grant_type', 'travel_agent_grant');
                        data.append('type', 'password');
                        data.append('client_id', process.env.VUE_APP_CLIENT_ID);
                        data.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);
                        data.append('email_address', this.emailAddress);
                        data.append('password', this.password);
                        data.append('scope', ''); // Assuming no specific scope is required

                        const config = {
                            method: 'post',
                            headers: { 'Content-Type': 'multipart/form-data' }
                        };
                        await httpPost('/oauth/token', data, id, config)
                            .then(async response => {
                                // Logic after successful login
                                const accessToken = response.data.access_token;
                                const refreshToken = response.data.refresh_token;
                                await this.setTokens(accessToken, refreshToken)
                                this.initializeNotificationPermissions(false)
                                global.masterFetch();
                                // Assuming 'router' is an instance of Vue Router passed to this function or available globally
                                toast.update(id, {
                                    render: "Registration successfully",
                                    type: "success",
                                    isLoading: false,
                                    autoClose: 5000
                                });
                                this.isLoading = false
                                this.$router.push('/');
                            })
                            .catch(error => {
                                this.isLoading = false
                                console.error(error);
                            });
                    })
                    .catch(error => {
                        this.isLoading = false
                        console.error(error);
                    });
            }
        },
        ...mapActions(useAuthStore, ['setTokens', 'initializeNotificationPermissions'])
    }
}
</script>
<style src="@/assets/css/register-form.css" scoped></style>