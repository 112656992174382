<template>
    <div class="driver-card" :class="cardClass">
        <!-- Priority Job -->
        <div v-if="isPriorityJob">
            <template v-if="role === 'driver'">
                <v-tooltip text="Waiting for drivers...">
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" size="19" color="primary">mdi-clock-outline</v-icon>
                        <v-chip color="primary" class="ml-3">Priority</v-chip>
                    </template>
                </v-tooltip>
            </template>
            <template v-else>
                <v-tooltip text="Not Available">
                    <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" size="19">mdi-close</v-icon>
                    </template>
                </v-tooltip>
            </template>
        </div>

        <!-- Ordinary Job -->
        <template v-else>
            <!-- Driver -->
            <div v-if="role === 'driver'">
                <template v-if="!isDriverAssigned && !isContractorAssigned && reAssigned.length === 0">
                    <template v-if="currentRole === null || currentRole === 'driver'">
                        <v-checkbox @click.stop.prevent="$emit('assign', 'driver')" :model-value="isSelected"
                            :disabled="currentRole !== null && currentRole !== 'driver'" hide-details></v-checkbox>
                    </template>
                    <template v-else>
                        <v-tooltip :text="`Assigning ${currentRole.replace(/-/g, ' ')} role`">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" size="19">mdi-close</v-icon>
                            </template>
                        </v-tooltip>
                    </template>
                </template>
                <template v-else-if="isDriverAssigned">
                    <div class="assigned-role" @click.stop="navigateToRequest('driver')">
                        <p>{{ driverName }}</p>
                        <div class="d-flex align-center">
                            <v-tooltip :text="driverStatus">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" :color="driverStatus === 'Pending' ? 'warning' : 'success'"
                                        size="24" class="status-icon">
                                        {{ driverStatus === 'Pending' ? 'mdi-clock' : 'mdi-check-circle' }}
                                    </v-icon>
                                </template>
                            </v-tooltip>
                            <v-tooltip v-if="attendances && attendances[0] && attendances[0].completed_at !== null"
                                :text="`Completed job at: ${$moment(attendances[0].completed_at).format('DD MMM yyyy HH:mm')}`">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" size="24" color="success">mdi-clock</v-icon>
                                </template>
                            </v-tooltip>
                            <v-tooltip
                                v-else-if="attendances && attendances[0] && attendances[0].attendance_at !== null"
                                :text="`Attendance taken at: ${$moment(attendances[0].attendance_at).format('DD MMM yyyy HH:mm')}`">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" size="24" color="primary">mdi-clock</v-icon>
                                </template>
                            </v-tooltip>
                            <v-tooltip
                                v-else-if="attendances && attendances[0] && attendances[0].no_shows && attendances[0].no_shows.length > 0 && attendances[0].no_shows[0] && attendances[0].no_shows[0].created_at"
                                :text="`No show report filled at: ${$moment(attendances[0].no_shows[0].created_at).format('DD MMM yyyy HH:mm')}`">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" size="24" color="error">mdi-clock</v-icon>
                                </template>
                            </v-tooltip>
                            <v-tooltip v-else-if="attendances && attendances[0] && attendances[0].start_job_at !== null"
                                :text="`Job started at: ${$moment(attendances[0].start_job_at).format('DD MMM yyyy HH:mm')}`">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" size="24" color="warning">mdi-clock</v-icon>
                                </template>
                            </v-tooltip>
                        </div>
                    </div>
                </template>
                <template v-else-if="reAssigned.length > 0">
                    <div @click.stop.prevent="getPriorityJob">
                        <v-tooltip text="Sent To Priority...">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" size="19" color="primary">
                                    mdi-clock-outline
                                </v-icon>
                                <v-chip color="primary" class="ml-3">
                                    Reassigned
                                </v-chip>
                            </template>
                        </v-tooltip>
                    </div>
                </template>
                <template v-else>
                    <v-tooltip text="Waiting for contractor...">
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props" size="19">mdi-clock-outline</v-icon>
                        </template>
                    </v-tooltip>
                </template>
            </div>

            <!-- Contractor -->
            <div v-else-if="role === 'contractor'">
                <template v-if="!isContractorAssigned && !isDriverAssigned && reAssigned.length === 0">
                    <template v-if="currentRole === null || currentRole === 'contractor'">
                        <v-checkbox @click.stop.prevent="$emit('assign', 'contractor')" :model-value="isSelected"
                            :disabled="currentRole !== null && currentRole !== 'contractor'" hide-details></v-checkbox>
                    </template>
                    <template v-else>
                        <v-tooltip :text="`Assigning ${currentRole.replace(/-/g, ' ')} role`">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" size="19">mdi-close</v-icon>
                            </template>
                        </v-tooltip>
                    </template>
                </template>
                <template v-else-if="isContractorAssigned">
                    <div class="assigned-role" @click.stop="navigateToRequest('contractor')">
                        <p>{{ contractorName }}</p>
                        <v-tooltip :text="contractorStatus">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" :color="contractorStatus === 'Pending' ? 'warning' : 'success'"
                                    size="24" class="status-icon">
                                    {{ contractorStatus === 'Pending' ? 'mdi-clock' : 'mdi-check-circle' }}
                                </v-icon>
                            </template>
                        </v-tooltip>
                    </div>
                </template>
                <template v-else>
                    <v-tooltip text="Not Available">
                        <template v-slot:activator="{ props }">
                            <v-icon v-bind="props" size="19">mdi-close</v-icon>
                        </template>
                    </v-tooltip>
                </template>
            </div>

            <!-- Tour Guide -->
            <div v-else-if="role === 'tour-guide'">
                <template v-if="!isTourGuideAssigned">
                    <template v-if="currentRole === null || currentRole === 'tour-guide'">
                        <v-checkbox @click.stop.prevent="$emit('assign', 'tour-guide')" :model-value="isSelected"
                            :disabled="currentRole !== null && currentRole !== 'tour-guide'" hide-details></v-checkbox>
                    </template>
                    <template v-else>
                        <v-tooltip :text="`Assigning ${currentRole.replace(/-/g, ' ')} role`">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" size="19">mdi-close</v-icon>
                            </template>
                        </v-tooltip>
                    </template>
                </template>
                <template v-else>
                    <div class="assigned-role" @click.stop="navigateToRequest('tour-guide')">
                        <p>{{ tourGuideName }}</p>
                        <v-tooltip :text="tourGuideStatus">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" :color="tourGuideStatus === 'Pending' ? 'warning' : 'success'"
                                    size="24" class="status-icon">
                                    {{ tourGuideStatus === 'Pending' ? 'mdi-clock' : 'mdi-check-circle' }}
                                </v-icon>
                            </template>
                        </v-tooltip>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import db from '@/databases/indexedDB';
export default {
    name: 'AssignmentCard',
    props: {
        isPriorityJob: {
            type: Boolean,
            default: false
        },
        role: {
            type: String,
            required: true,
            validator: (value) => ['driver', 'contractor', 'tour-guide'].includes(value)
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        reAssigned: {
            type: Array,
            default: null
        },
        currentRole: {
            type: String,
            default: null
        },
        assignedJobRequestDetails: {
            type: Array,
            default: () => []
        },
        attendances: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        isDriverAssigned() {
            return this.getAssignmentStatus('driver') !== null;
        },
        isContractorAssigned() {
            return this.getAssignmentStatus('contractor') !== null;
        },
        isTourGuideAssigned() {
            return this.getAssignmentStatus('tour-guide') !== null;
        },
        driverName() {
            return this.getAssignedName('driver');
        },
        contractorName() {
            return this.getAssignedName('contractor');
        },
        tourGuideName() {
            return this.getAssignedName('tour-guide');
        },
        driverStatus() {
            return this.getAssignmentStatus('driver');
        },
        contractorStatus() {
            return this.getAssignmentStatus('contractor');
        },
        tourGuideStatus() {
            return this.getAssignmentStatus('tour-guide');
        },
        cardClass() {
            if (this.isPriorityJob) return 'priority';
            if (this.isDriverAssigned || this.isContractorAssigned || this.isTourGuideAssigned) return 'assign';
            return 'unassigned';
        }
    },
    methods: {
        getAssignmentStatus(role) {
            const assignment = this.assignedJobRequestDetails.find(
                detail => detail.assigned_job_request.user.role.role_slug === role && detail.deleted_at === null
            );
            return assignment ? assignment.assigned_job_request.status : null;
        },
        getAssignedName(role) {
            const assignment = this.assignedJobRequestDetails.find(
                detail => detail.assigned_job_request.user.role.role_slug === role && detail.deleted_at === null
            );
            if (assignment) {
                const { first_name, last_name } = assignment.assigned_job_request.user;
                return `${first_name} ${last_name}`;
            }
            return '';
        },
        navigateToRequest(role) {
            const assignment = this.assignedJobRequestDetails.find(
                detail => detail.assigned_job_request.user.role.role_slug === role && detail.deleted_at === null
            );
            if (assignment) {
                const requestId = assignment.assigned_job_request.uuid;
                this.$router.push(`/requests/${requestId}`);
            }
        },
        async getPriorityJob() {
            console.log(this.reAssigned)
            if (this.reAssigned && this.reAssigned.length > 0) {
                const reassignedJob = this.reAssigned[0];
                if (reassignedJob && reassignedJob.uuid) {
                    try {
                        // Check if job exists in IndexedDB
                        const jobTransfer = await db.job_transfers.get(reassignedJob.uuid);
                        if (jobTransfer) {
                            this.$router.push(`/jobs/${jobTransfer.job_uuid}`);
                        } else {
                            console.warn('Job not found in IndexedDB');
                        }
                    } catch (error) {
                        console.error('Error checking job in IndexedDB:', error);
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.driver-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 10px;
    border-radius: var(--border-radius);
}

.image-container {
    height: 35px;
    width: 35px;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 20px;
}

.image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.status-icon {
    margin: 8px 0;
}

.assigned-role {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    padding: 5px;
    border-radius: 4px;
}

.assigned-role:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
}
</style>