<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Edit Fleet Drivers</h1>
                <p>Dashboard > DTC > Edit Fleet Driver</p>
            </div>

            <div class="request-details">
                <v-form ref="form" id="edit-form">

                    <div class="email-address">
                        <v-col cols="12" class="pa-1 pb-3">
                            <div class="user-details container">
                                <div v-if="data && data.user">
                                    <div class="user">
                                        <div class="image-container">
                                            <Image :imagePath="data.user.image_path">
                                            </Image>
                                        </div>
                                        <div class="details">
                                            <h1 class="name">{{ data.user.first_name + ' ' + data.user.last_name }}</h1>
                                            <p>+{{ data.user.contact_numbers.find((a) => a.is_primary ===
                                                1).country_code.country_code }}{{
                                                    data.user.contact_numbers.find((a) => a.is_primary === 1).contact_number
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="contacts">
                                        <a href="" @click.prevent="$router.push('/chats/' + data.user.uuid)"
                                            class="chat">
                                            <img src="@/assets/icons/chat.svg" alt="chat.svg">
                                        </a>
                                        <a href="" @click.prevent="sendWhatsapp(data.user.contact_numbers.find((a) => a.is_primary ===
                                            1).country_code.country_code +
                                            data.user.contact_numbers.find((a) => a.is_primary === 1).contact_number)"
                                            class="whatsapp">
                                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp.svg">
                                        </a>
                                    </div>
                                </div>
                                <div v-else>
                                    <p>Loading...</p>
                                </div>
                            </div>
                        </v-col>
                    </div>

                    <v-col cols="12">
                        <v-card>
                            <v-tabs v-model="tab"
                                style="background-color: var(--primary-blue); color: var(--secondary-white);">
                                <v-tab value="private">Private</v-tab>
                                <v-tab value="sic">SIC</v-tab>
                            </v-tabs>

                            <v-card-text>
                                <v-tabs-window v-model="tab">
                                    <v-tabs-window-item value="private" v-if="tab == 'private'">
                                        <v-col cols="6" sm="6" v-for="(category, index) in categories" :key="index"
                                            style="display: inline-block;">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPrices(category.price) : formatPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                    </v-tabs-window-item>

                                    <v-tabs-window-item value="sic" v-if="tab == 'sic'">
                                        <v-col cols="12">
                                            <v-radio-group v-model="sicType" @change="updateSICCategories">
                                                <!-- <v-radio label="Per Head" value="perHead"></v-radio> -->
                                                <v-radio label="Per Hotel" value="perHotel"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="6" sm="6" v-for="(category, index) in perHeadCategory" :key="index"
                                            style="display: inline-block;" v-if="sicType == 'perHead'">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPerHeadPrices(category.price) : formatPerHeadPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="6" v-for="(category, index) in perHotelCategory"
                                            :key="index" style="display: inline-block;" v-if="sicType == 'perHotel'">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPerHotelPrices(category.price) : formatPerHotelPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                    </v-tabs-window-item>
                                </v-tabs-window>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <div class=" buttons">
                        <v-btn class="button-1" @click.prevent="submitRequest" :loading="isLoading">Request</v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Loading from '@/components/Loading.vue'
import Image from '@/components/Image.vue'
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

// Toast Notification
import { toast } from 'vue3-toastify';
import { httpPost } from '@/services/http';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'DTCFleetEditView',
    components: {
        TopNav,
        Loading,
        Image,
    },
    data() {
        return {
            id: this.$route.params.id,
            data: '',
            tab: 'private',
            sicType: 'perHotel', // New data property for SIC type
            categories: [],
            perHotelCategory: [],
            perHeadCategory: [],
            priceValidation: [
                v => !!v || 'Price is required',
                v => !isNaN(parseFloat(v)) && v >= 0 || 'Invalid price', // Ensure value is a non-negative number
                v => /^(\d+(\.\d{0,2})?)$/.test(v) || 'Price must be a valid number with up to 2 decimal places', // Optional: Adjust regex as needed for your price format
            ],
            isLoading: false,
            subscription: null,
            transferTypesSubscription: null,
        };
    },
    computed: {
        sicCategories() {
            return this.sicType === 'perHead' ? this.perHeadCategory : this.perHotelCategory;
        },
    },
    created() {
        indexedDBService.getDtcsData();
        indexedDBService.getTransferTypesData();
        this.fetchData();
        this.fetchTransferTypesData();
    },
    beforeUnmount() {
        if (this.transferTypesSubscription) {
            this.transferTypesSubscription.unsubscribe();
        }
    },
    methods: {
        setDefaultValue() {
            if (this.data && this.data.details) {
                if (this.categories.length > 0) {
                    this.categories.forEach(category => {
                        const detail = this.data.details.find(a => a.is_sic === 0 && a.transfer_type_special?.transfer_type_special_slug === category.slug);
                        category.price = detail ? detail.price : '0.00';
                    });
                    this.perHeadCategory.forEach(category => {
                        const detail = this.data.details.find(a => a.is_sic === 1 && a.transfer_type_special?.transfer_type_special_slug === category.slug);
                        category.price = detail ? detail.price : '0.00';
                    });
                    this.perHotelCategory.forEach(category => {
                        const detail = this.data.details.find(a => a.is_sic === 1 && a.transfer_type_special?.transfer_type_special_slug === category.slug);
                        category.price = detail ? detail.price : '0.00';
                    });
                }
            }
        },
        fetchData() {
            const observableData = liveQuery(async () => {
                try {
                    // Fetch the single DTC request record
                    const dtcRequest = await db.dtc_requests.get({ uuid: this.id });

                    if (dtcRequest) {
                        // Fetch requester and user associated with the request
                        const requester = await db.users.get({ uuid: dtcRequest.requester_uuid });
                        const user = await db.users.get({ uuid: dtcRequest.user_uuid });

                        // Fetch details for the request and enhance with transfer type special data
                        const requestDetails = await db.dtc_request_details.where({ dtc_request_uuid: dtcRequest.uuid }).toArray();
                        for (let detail of requestDetails) {
                            const transferTypeSpecial = await db.transfer_types.get({ uuid: detail.transfer_type_special_uuid });
                            detail.transfer_type_special = transferTypeSpecial || null;
                        }

                        // Return the enhanced data
                        return {
                            ...dtcRequest,
                            requester,
                            user,
                            details: requestDetails,
                        };
                    }

                    // Return null or an empty object if the request does not exist
                    return null;
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                    return null;
                }
            });

            this.subscription = observableData.subscribe((result) => {
                this.data = result;
                this.setDefaultValue();
                console.log(this.data)
            });
        },
        fetchTransferTypesData() {
            const observableData =
                liveQuery(async () => {
                    // Fetch transfer types with their related locations and location details
                    const transfers = await db.transfer_types.orderBy('transfer_type_special_name').toArray();

                    const transfersWithDetails = transfers.map(transfer => {
                        return {
                            type: transfer.transfer_type_special_name,
                            slug: transfer.transfer_type_special_slug,
                            start_time: transfer.start_time,
                            return_time: transfer.return_time,
                            sic_start_time: transfer.sic_start_time,
                            sic_return_time: transfer.sic_return_time,
                            is_private: transfer.is_private,
                            is_sic_per_head: transfer.is_sic_per_head,
                            is_sic_per_hotel: transfer.is_sic_per_hotel,
                            is_private_tour_guide: transfer.is_private_tour_guide,
                            is_sic_tour_guide: transfer.is_sic_tour_guide,
                        };
                    });

                    return transfersWithDetails;
                });

            this.transferTypesSubscription = observableData.subscribe((result) => {
                this.allTypes = result;

                console.log(result)

                let privateTypes = this.allTypes.filter((a) => a.is_private === 1)
                this.categories = [];
                privateTypes.forEach((type) => {
                    this.categories.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })

                let perHeadSicTypes = this.allTypes.filter((a) => a.is_sic_per_head === 1)
                this.perHeadCategory = [];
                perHeadSicTypes.forEach((type) => {
                    this.perHeadCategory.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })

                let perHotelCategoryTypes = this.allTypes.filter((a) => a.is_sic_per_hotel === 1)
                this.perHotelCategory = [];
                perHotelCategoryTypes.forEach((type) => {
                    this.perHotelCategory.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })

                this.setDefaultValue();
            });
        },
        formatPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.categories.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.categories = updatedCategories;
        },
        // Example of replacing the entire array to ensure reactivity
        autoFillPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.categories.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.categories = updatedCategories;
            }

            this.formatPrices()
        },
        // Per Head Prices
        formatPerHeadPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.perHeadCategory.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.perHeadCategory = updatedCategories;
        },
        autoFillPerHeadPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.perHeadCategory.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.perHeadCategory = updatedCategories;
            }

            this.formatPerHeadPrices()
        },
        // Per Hotel Prices
        formatPerHotelPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.perHotelCategory.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.perHotelCategory = updatedCategories;
        },
        autoFillPerHotelPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.perHotelCategory.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.perHotelCategory = updatedCategories;
            }

            this.formatPerHotelPrices()
        },
        onImageChange(e) {
            const file = e.target.files[0];
            if (file) {
                this.displayImage = URL.createObjectURL(file);
            }
        },
        triggerFileInput() {
            let fileUpload = document.getElementById('fileUpload')
            if (fileUpload != null) {
                fileUpload.click()
            }
        },
        async submitRequest() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid && this.data && this.data.user) {
                // Check if response is successful & driver is created

                const privateFilled = this.categories.every(category => !isNaN(category.price) && category.price !== null);

                if (!privateFilled) {
                    toast.error('Please ensure all private prices are filled and valid.');
                    return;
                }

                const selectedSICCategories = this.sicType === 'perHead' ? this.perHeadCategory : this.perHotelCategory;

                const sicFilled = selectedSICCategories.every(category => !isNaN(category.price) && category.price !== null);

                if (!sicFilled) {
                    toast.error('Please ensure all SIC prices are filled and valid.');
                    return;
                }

                // Select the appropriate SIC categories based on sicType

                // Combine categories and selected SIC categories
                const allCategories = [
                    ...this.categories.map(category => ({
                        slug: category.slug,
                        price: parseFloat(category.price), // Ensure price is a number
                        is_sic: false
                    })),
                    ...selectedSICCategories.map(category => ({
                        slug: category.slug,
                        price: parseFloat(category.price), // Ensure price is a number
                        is_sic: true
                    }))
                ];

                let dataToSend = {
                    id: this.data.user.uuid,
                    request: allCategories
                };

                let id = toast.loading('Please wait...');
                this.isLoading = true
                await httpPost('/api/v1/travel_agents/dtcs/fleets/update', dataToSend, id)
                    .then(async response => {
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        });
                        this.isLoading = false;
                        this.$router.push('/dtc/' + response.data.data.uuid);
                    })
                    .catch(error => {
                        this.isLoading = false
                        console.error(error);
                    });
            }
        },
        sendWhatsapp(number) {
            // Remove all spaces, dashes, round brackets, and plus signs
            const formattedNumber = number.toString().replace(/[\s-()+]/g, '');
            console.log(formattedNumber);
            window.open(`https://api.whatsapp.com/send?phone=${formattedNumber}`, '_blank').focus();
        }
    },
};
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
<style src="@/assets/css/register-form.css" scoped></style>
<style scoped>
section#content .main-content {
    max-width: unset;
}

.edit-form {
    flex: 1;
}

#edit-form {
    margin: auto;
    max-width: 50%;
}

.user-details>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-details .contacts {
    display: flex;
    gap: 20px;
}

.user-details .contacts a {
    display: grid;
    place-items: center;
}

.user-details .contacts a.whatsapp img {
    height: 30px;
    width: 30px;
}

.user-details .contacts a.chat img {
    height: 25px;
    width: 25px;
}

.user .image-container {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}

.user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user .name {
    font-size: 17px;
}

.user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

section#content form>* {
    gap: unset;
}

@media screen and (max-width: 950px) {
    #edit-form {
        max-width: unset;
    }
}
</style>
