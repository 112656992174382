<template>
    <v-dialog v-model="isPriorityJobDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Add Priority Job</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="togglePriorityJobDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form ref="priorityJobForm">
                    <v-container>
                        <div class="guest-types">
                            <p>Fill in the infomation to push the job to priority</p>
                        </div>
                        <div class="date-price">
                            <div class="price">
                                <v-text-field v-model="job.price" name="price" id="no" class="form-control"
                                    label="Price for all transfers" :rules="guestValidation.priceValidation"
                                    @blur="formatPrice()" prefix="$"></v-text-field>
                            </div>
                        </div>
                        <div class="job-details">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div class="type">
                                        <v-select :items="jobTypes" item-title="type_name" item-value="type_slug"
                                            label="Type" v-model="job.type"></v-select>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="pings">
                                        <v-select :items="pings" item-title="ping_name" item-value="ping_slug"
                                            label="Ping" v-model="job.ping"></v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div class="payment-types">
                                        <v-select :items="paymentTypes" item-title="type_name" item-value="type_slug"
                                            label="Payment Type" v-model="job.paymentType"></v-select>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="vehicle-types">
                                        <v-select :items="vehicleTypes" item-title="type_name" item-value="type_slug"
                                            label="Vehicle Type" v-model="job.vehicleType"></v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col cols="6">
                                    <v-btn color="error" class="w-100" @click="togglePriorityJobDialog">
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn color="primary" class="w-100" @click="sendToPriorityJob"
                                        :loading="isLoading">
                                        Submit
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isDeleteCheckDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <v-chip color="error" v-if="data && data.data && data.data.priority">
                        Priority</v-chip>
                    <v-chip v-else>
                        <p>Standard</p>
                    </v-chip>
                    <span class="headline ml-3">Pre Deletion Check</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="toggleDeleteCheckDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form ref="deleteCheckForm">
                    <v-container>
                        <div class="alert-container">
                            <v-icon>
                                mdi-alert-circle
                            </v-icon>
                            <p class="ml-3">
                                Note: Jobs that are assigned and completed cannot be deleted. However, all other data
                                can be
                                deleted.
                            </p>
                        </div>

                        <!-- Data to be Deleted -->
                        <div class="mb-1">
                            <div class="d-flex align-center">
                                <v-progress-circular indeterminate size="20" width="2" color="primary" class="mr-2"
                                    v-if="isLoading"></v-progress-circular>
                                <v-icon color="success" size="small" class="mr-2" v-else>
                                    {{ dataToPreserve === 0 ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                </v-icon>

                                <div class="item-details">
                                    <div class="item-name">Can job be deleted?</div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-1">
                            <div class="d-flex align-center" v-if="dataToDelete !== 0">
                                <v-progress-circular indeterminate size="20" width="2" color="primary" class="mr-2"
                                    v-if="isLoading"></v-progress-circular>
                                <v-icon color="success" size="small" class="mr-2" v-else>
                                    mdi-check-circle
                                </v-icon>

                                <div class="item-details">
                                    <div class="item-name">Transfers that can be deleted - {{ dataToDelete }}
                                        items</div>
                                </div>
                            </div>
                        </div>

                        <!-- Data to be Preserved -->
                        <div class="mb-1" v-if="dataToPreserve !== 0">
                            <div class="d-flex align-center">
                                <v-progress-circular indeterminate size="20" width="2" color="primary" class="mr-2"
                                    v-if="isLoading"></v-progress-circular>
                                <v-icon color="error" size="small" class="mr-2" v-else>
                                    mdi-close-circle
                                </v-icon>
                                <div class="item-details">
                                    <div class="item-name">Transfers thaat can not be deleted - {{ dataToPreserve }}
                                        items</div>
                                </div>
                            </div>
                        </div>

                        <v-row class="mt-5">
                            <v-col cols="6">
                                <v-btn color="error" class="w-100" @click="toggleDeleteCheckDialog">
                                    Cancel
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn color="primary" class="w-100" @click="deleteJob" :loading="isLoading">
                                    Delete
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="returnTrip" max-width="400" persistent>
        <v-container class="pa-5 bg-white rounded">
            <div class="d-flex align-start mb-4">
                <v-icon size="18" class="mr-3 mt-1">mdi-map-marker</v-icon>
                <div>
                    <h6 class="text-h6 font-weight-medium mb-1">Return Pick Up?</h6>
                    <div>
                        {{ returnTripQueue.length > 0 ?
                            returnTripQueue.length + ' return pick-ups detected. Do you want to select?' :
                            'Return pick-up detected. Do you want to select?'
                        }}
                    </div>
                </div>
            </div>

            <div class="ml-5 mt-4">
                <v-row no-gutters>
                    <v-col cols="6" class="pr-2">
                        <v-btn @click="this.returnTrip = false" color="error" block>
                            No
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pl-2">
                        <v-btn @click="returnTripQueue.length > 0 ? processReturnTrips() : assignReturnTrip()"
                            color="primary" block>
                            Yes
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </v-dialog>

    <v-dialog v-model="isStayAddDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Add Stay Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeStayAddDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form ref="addStayForm">
                    <v-container>
                        <div class="start-date">
                            <v-text-field v-model="newStay.startDate" name="start-date" type="date" id="start-date"
                                ref="addStayStartDate" class="form-control" label="Start Date"
                                :rules="stayValidation.startDate"></v-text-field>
                        </div>
                        <div class="end-date">
                            <v-text-field v-model="newStay.endDate" name="end-date" type="date" id="end-date"
                                class="form-control" label="End Date" :rules="stayValidation.endDate"></v-text-field>
                        </div>
                        <div class="location">
                            <v-autocomplete :items="newStay.locationSuggestions" item-title="main_text"
                                item-value="uuid" v-model="newStay.location" label="Location"
                                :rules="stayValidation.locationRules" style="margin: 15px 0px" @keyup="
                                    debouncedFetchLocationSuggestions($event.target.value, 'stay', false)
                                    " :loading="locationLoading.stay ? true : false"></v-autocomplete>
                        </div>
                        <v-row>
                            <v-col>
                                <v-btn color="primary" class="w-100" @click="addStayDetail" :loading="isLoading">Add
                                    Stay Detail</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="editStayDetailModal.show" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Edit Stay Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeStayEditDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form ref="editStayForm">
                    <v-container>
                        <div class="start-date">
                            <v-text-field v-model="newStay.startDate" name="start-date" type="date" id="start-date"
                                ref="editStayStartDate" class="form-control" label="Start Date"
                                :rules="stayValidation.startDate"></v-text-field>
                        </div>
                        <div class="end-date">
                            <v-text-field v-model="newStay.endDate" name="end-date" type="date" id="end-date"
                                class="form-control" label="End Date" :rules="stayValidation.endDate"></v-text-field>
                        </div>
                        <div class="location">
                            <v-autocomplete :items="newStay.locationSuggestions" item-title="main_text"
                                item-value="uuid" v-model="newStay.location" label="Location"
                                :rules="stayValidation.locationRules" style="margin: 15px 0px" @keyup="
                                    debouncedFetchLocationSuggestions($event.target.value, 'stay', false)
                                    " :loading="locationLoading.stay ? true : false"></v-autocomplete>
                        </div>
                        <v-row>
                            <v-col :cols="data.data.stays.length == 1 ? 12 : 6" v-if="data.data.stays.length > 1">
                                <v-btn color="error" variant="outlined" class="w-100" @click="deleteStayDetail"
                                    :loading="isLoading">Delete</v-btn>
                            </v-col>
                            <v-col :cols="data.data.stays.length == 1 ? 12 : 6">
                                <v-btn color="primary" class="w-100" @click="editStayDetail" :loading="isLoading">Edit
                                    Stay Details</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="isPaxEditDialogOpen" persistent max-width="400px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Edit Guest Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closePaxEditDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="guestDetailsForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedPax.no_of_adults" label="Adults" type="number" min="0"
                                    :rules="guestValidation.numberValidation"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedPax.no_of_childs" label="Children" type="number" min="0"
                                    :rules="guestValidation.numberValidation"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedPax.no_of_infants" label="Infants" type="number" min="0"
                                    :rules="guestValidation.numberValidation"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="button-1 w-100" @click="updatePaxDetails()" :loading="isLoading">Edit
                                    Guest
                                    Details</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="addItineraryShow" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Add Itinerary</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="addItineraryShow = !addItineraryShow">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="addItineraryForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="types" item-title="type" item-value="slug" label="Type"
                                    v-model="newItinerary.type"
                                    @update:modelValue="(value) => onCategoryChange(newItinerary.category, false)"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="categories" item-title="type" item-value="slug" label="Category"
                                    v-model="newItinerary.category"
                                    @update:modelValue="(value) => onCategoryChange(value, false)"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select v-model="newItinerary.date" :items="availableDates" label="Date"
                                    :item-title="date => $moment(date).format('DD/MM/YYYY')" :item-value="date => date"
                                    required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field type="time" label="Time" v-model="newItinerary.pickUpTime"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-autocomplete :items="newItinerary.pickUpLocationSuggestions" item-title="main_text"
                                    item-value="uuid" label="Pick Up Location"
                                    :rules="itineraryLocationValidation.pickUpLocationRules"
                                    v-model="newItinerary.pickUpLocation" @keyup="
                                        debouncedFetchLocationSuggestions(
                                            $event.target.value,
                                            'pick_up',
                                            false
                                        )
                                        " :loading="locationLoading.pick_up ? true : false"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete :items="newItinerary.destinationLocationSuggestions"
                                    item-title="main_text" item-value="uuid" label="Drop Off Location"
                                    :rules="itineraryLocationValidation.destinationLocationRules"
                                    v-model="newItinerary.destinationLocation" @keyup="
                                        debouncedFetchLocationSuggestions(
                                            $event.target.value,
                                            'destination',
                                            false
                                        )
                                        " :loading="locationLoading.destination ? true : false"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-file-input v-model="newItinerary.tickets" label="Select ticket(s)" multiple clearable
                                    chips accept=".jpg,.jpeg,.png,.pdf"
                                    @change="(event) => addTickets(event, false)"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row> </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea label="Remarks" v-model="newItinerary.remarks" rows="1"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field type="time" label="Return Time (Optional)"
                                    v-model="newItinerary.returnTime" clearable></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-container>
                        <v-btn class="button-1 w-100" @click="addTransferDetail" :loading="isLoading">Add
                            Transfer</v-btn>
                    </v-container>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="editTransferModal.show" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Edit Itinerary</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeEditItineraryDialog">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="editItineraryForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="types" item-title="type" item-value="slug" label="Type"
                                    v-model="editItinerary.type"
                                    @update:modelValue="(value) => onCategoryChange(editItinerary.category, true)"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="categories" item-title="type" item-value="slug" label="Category"
                                    v-model="editItinerary.category"
                                    @update:modelValue="(value) => onCategoryChange(value, true)"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field type="date" label="Date" v-model="editItinerary.date"
                                    readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field type="time" label="Time"
                                    v-model="editItinerary.pickUpTime"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-autocomplete :items="editItinerary.pickUpLocationSuggestions" item-title="main_text"
                                    item-value="uuid" label="Pick Up Location"
                                    :rules="itineraryLocationValidation.pickUpLocationRules"
                                    v-model="editItinerary.pickUpLocation"
                                    @keyup="debouncedFetchLocationSuggestions($event.target.value, 'pick_up', true)"
                                    :loading="locationLoading.pick_up ? true : false"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete :items="editItinerary.destinationLocationSuggestions"
                                    item-title="main_text" item-value="uuid" label="Drop Off Location"
                                    :rules="itineraryLocationValidation.destinationLocationRules"
                                    v-model="editItinerary.destinationLocation"
                                    @keyup="debouncedFetchLocationSuggestions($event.target.value, 'destination', true)"
                                    :loading="locationLoading.destination ? true : false"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-file-input v-model="editItinerary.tickets" label="Select ticket(s)" multiple
                                    clearable chips accept=".jpg,.jpeg,.png,.pdf"
                                    @change="(event) => addTickets(event, true)"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"
                                v-if="editItinerary.uploadedTickets && editItinerary.uploadedTickets.length > 0">
                                <div class="d-flex flex-column gap-2">
                                    <p class="mb-2">Uploaded Tickets:</p>
                                    <div v-for="(ticket, index) in editItinerary.uploadedTickets" :key="index"
                                        class="d-flex align-center">
                                        <p>{{ index + 1 }}.</p>
                                        <v-chip class="ma-1">
                                            {{ ticket.original_name || ticket.name }}
                                        </v-chip>
                                        <v-btn icon size="x-small" color="error" class="ml-2"
                                            @click="deleteTicket(ticket.uuid)">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea label="Remarks" v-model="editItinerary.remarks" rows="1"></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-btn color="error" variant="outlined" class="w-100" @click="deleteItinerary">
                                    Delete
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn class="button-1 w-100" @click="updateItinerary">Update Itinerary</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="isAssignDetailDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Assign Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeAssignDetailDialog">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>Available {{ this.currentRole === 'tour-guide' ? 'tour guide' : this.currentRole }}:
                                    {{ dtcData.length }}</p>
                                <p>Selected Transfers: {{ assignIds.length }}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete :items="dtcData" item-title="full_name" item-value="uuid"
                                    label="Select Driver/Contractor/Tour Guide" v-model="dtcId">
                                    <!-- Custom display for selected item -->
                                    <template v-slot:selection="{ item }">
                                        <v-chip class="mr-2"
                                            :color="item.raw.user?.role?.role_name === 'Priority Jobs' ? 'black' : 'primary'">
                                            {{ item.raw.user?.role?.role_name ?? 'N/A' }}
                                        </v-chip>
                                        {{ item.raw.full_name }}
                                    </template>

                                    <!-- Custom display for items in the dropdown -->
                                    <template v-slot:item="{ props, item }">
                                        <v-list-item v-bind="props">
                                            <template v-slot:prepend>
                                                <v-chip class="mr-2"
                                                    :color="item.raw.user?.role?.role_name === 'Priority Jobs' ? 'black' : 'primary'">
                                                    {{ item.raw.user?.role?.role_name ?? 'N/A' }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:title>
                                                {{ item.raw.full_name }}
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="button-1 w-100" :disabled="dtcId == '' || assignIds.length == 0"
                                    @click="submitRequest" :loading="isLoading">Request</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Job Detail</h1>
                <p>Dashboard > Calendar > Job</p>
                <div class="job-infomation">
                    <v-chip color="error" class="pulse-animation" v-if="data && data.data && data.data.priority">
                        Priority</v-chip>
                    <v-chip v-else>
                        <p>Standard</p>
                    </v-chip>
                    <v-btn href="" color="error" prepend-icon="mdi-delete" :loading="isLoading"
                        @click="toggleDeleteCheckDialog"
                        v-if="data && data.data && data.data.deleted_at === null">Delete
                        Job</v-btn>
                    <v-chip color="error" v-else>
                        Job Deleted
                    </v-chip>
                </div>
            </div>

            <div class="user-details container">
                <div v-if="data && data.data && data.data.tourist">
                    <div class="user">
                        <div class="image-container">
                            <Image :imagePath="data.data.tourist.image_path"></Image>
                        </div>
                        <div class="details">
                            <h1 class="name">
                                {{ (data.data.tourist.first_name ?? '') + ' ' + (data.data.tourist.last_name ?? '') }}
                            </h1>
                            <p>+{{ data.data.tourist.contact_numbers.find((a) => a.is_primary ===
                                1).country_code.country_code +
                                data.data.tourist.contact_numbers.find((a) => a.is_primary === 1).contact_number
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="contacts">
                        <a href="" @click.prevent="$router.push('/chats/' + data.data.tourist.uuid)" class="chat">
                            <img src="@/assets/icons/chat.svg" alt="chat.svg">
                        </a>
                        <a href="" @click.prevent="sendWhatsapp(data.data.tourist.contact_numbers.find((a) => a.is_primary ===
                            1).country_code.country_code +
                            data.data.tourist.contact_numbers.find((a) => a.is_primary === 1).contact_number)"
                            class="whatsapp">
                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp.svg">
                        </a>
                    </div>
                </div>
                <div v-else>
                    <p>Loading...</p>
                </div>
            </div>

            <div class="stay-pax-details">
                <div class="stay-details container">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.873" height="22.676"
                            viewBox="0 0 15.873 22.676">
                            <path id="Icon_material-location-on" data-name="Icon material-location-on"
                                d="M15.437,3A7.931,7.931,0,0,0,7.5,10.937c0,5.953,7.937,14.74,7.937,14.74s7.937-8.787,7.937-14.74A7.931,7.931,0,0,0,15.437,3Zm0,10.771a2.835,2.835,0,1,1,2.835-2.835A2.836,2.836,0,0,1,15.437,13.771Z"
                                transform="translate(-7.5 -3)" fill="currentColor" />
                        </svg>

                        <p>Stay</p>
                    </div>

                    <div class="list">
                        <div class="list-item" v-for="(stay, index) in data.data.stays" :key="index" v-if="data"
                            @click="openStayEditDialog(index)">
                            <div class="list-item-title">{{ stay.stay.main_text }}</div>
                            <div class="list-item-subtitle">
                                {{ formatDateFull(stay.start_date) }} - {{ formatDateFull(stay.end_date) }}
                            </div>
                        </div>

                        <p v-if="data && data.data && data.data.stays && data.data.stays.length == 0">No Stay Details
                            Available</p>
                    </div>

                    <div class="icon-wrapper">
                        <v-btn density="compact" icon="mdi-plus" @click="openStayAddDialog" color="primary"
                            variant="text"
                            v-if="(data && data.data && !data.data.priority) && (data && data.data && data.data.deleted_at === null)"></v-btn>
                    </div>
                </div>

                <div class="pax-details container">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.768" height="18.493"
                            viewBox="0 0 28.768 18.493">
                            <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people"
                                d="M21.864,15.58a3.853,3.853,0,1,0-3.923-3.853A3.9,3.9,0,0,0,21.864,15.58Zm-10.461,0A3.853,3.853,0,1,0,7.48,11.728,3.9,3.9,0,0,0,11.4,15.58Zm0,2.825c-3.073,0-9.153,1.477-9.153,4.495v3.467H20.743V22.9C20.743,19.883,14.476,18.406,11.4,18.406Zm10.461.707a6.979,6.979,0,0,0-1.121.064c1.5,1.092,2.055,1.8,2.055,3.724v3.467h8.219V22.9C31.017,19.883,24.937,19.112,21.864,19.112Z"
                                transform="translate(-2.25 -7.875)" fill="currentColor" />
                        </svg>

                        <p>Guest</p>
                    </div>

                    <div class="content"
                        v-if="data && data.data && ('no_of_adults' in data.data) && ('no_of_childs' in data.data) && ('no_of_infants' in data.data)">
                        <p>Adults: {{ data.data.no_of_adults }}</p>
                        <p>Childs: {{ data.data.no_of_childs }}</p>
                        <p>Infants: {{ data.data.no_of_infants }}</p>
                    </div>

                    <div class="icon-wrapper">
                        <v-btn density="compact" icon="mdi-pencil" @click="openPaxEditDialog" color="primary"
                            variant="text"
                            v-if="(data && data.data && !data.data.priority) && (data && data.data && data.data.deleted_at === null)"></v-btn>
                    </div>
                </div>
            </div>

            <div class="priority-details" v-if="data && data.data && data.data.priority">
                <div class="container">
                    <div class="priority-details">
                        <p class="mt-0"><strong>Priority Details</strong></p>
                        <p class="mt-0"><v-icon class="mr-4">mdi-calendar</v-icon> Type: <strong>{{
                            data.data.priority.type.type_name
                                }}</strong></p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-signal</v-icon> Ping: <strong>{{
                            data.data.priority.ping.ping_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-cash</v-icon> Payment Type: <strong>{{
                            data.data.priority.payment_type.type_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-car</v-icon> Vehicle Type: <strong>{{
                            data.data.priority.vehicle_type.type_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-currency-usd</v-icon> Job Cost: <strong>${{
                            data.data.priority.job_cost
                                }}</strong></p>
                    </div>
                </div>
                <div class="container timer">
                    <v-icon class="mb-2">mdi-clock-outline</v-icon>
                    <p v-if="data && data.data && data.data.deleted_at === null">Searching for drivers...</p>
                    <v-chip color="error" v-else>Job have been deleted</v-chip>
                </div>
            </div>

            <div class="add-itinerary-btn" @click="addItinerary(date)"
                v-if="(groupedTransfers && groupedTransfers.length == 0) && (data && data.data && data.data.deleted_at === null)">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
                        <line id="Line_36" data-name="Line 36" x2="23" transform="translate(2 13.5)" fill="none"
                            stroke="#fff" stroke-linecap="round" stroke-width="4" />
                        <line id="Line_37" data-name="Line 37" x2="23" transform="translate(13.5 2) rotate(90)"
                            fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4" />
                    </svg>
                </span>
                Add itinerary for this job
            </div>

            <div class="transfer-container" v-if="groupedTransfers && groupedTransfers.length > 0"
                v-for="(group, index) in groupedTransfers" :key="index">
                <div>
                    <div class="transfer-header">
                        <h3>{{ group.date }}</h3>
                        <v-btn variant="text" prepend-icon="mdi-plus" @click="addItinerary(new Date(group.date))"
                            v-if="(data && data.data && !data.data.priority) && (data && data.data && data.data.deleted_at === null)">Add
                            Transfer</v-btn>
                    </div>

                    <v-data-table :headers="headers" :items="group.transfers" :items-per-page="-1"
                        :sort-by="[{ key: 'pick_up_time', order: 'asc' }]" disable-pagination hide-default-footer
                        item-key="uuid">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>
                                    {{ this.$moment(item.pick_up_time).format('HH:mm') }}
                                </td>
                                <td>{{ item.transfer_type_name }}</td>
                                <td>{{ item.transfer_type.transfer_type_special_slug }}</td>
                                <td>{{ item.from.main_text }}</td>
                                <td>{{ item.to.main_text }}</td>
                                <td>{{ item.remarks ?? '-' }}</td>
                                <td>
                                    <AssignmentCard :is-priority-job="data.data.priority !== null"
                                        v-if="data.data.deleted_at === null" role="driver"
                                        :is-selected="assignIds.includes(item.job_transfer_uuid)"
                                        :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        :reAssigned="item.children.filter(a => a.deleted_at === null)"
                                        :attendances="item.attendances ?? []"
                                        @assign="handleCheckboxClick(item.job_transfer_uuid, $event)" />
                                    <p v-else>Not Available</p>
                                </td>
                                <td>
                                    <AssignmentCard :is-priority-job="data.data.priority !== null"
                                        v-if="data.data.deleted_at === null" role="contractor"
                                        :is-selected="assignIds.includes(item.job_transfer_uuid)"
                                        :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        :reAssigned="item.children.filter(a => a.deleted_at === null)"
                                        @assign="handleCheckboxClick(item.job_transfer_uuid, $event)" />
                                    <p v-else>Not Available</p>
                                </td>
                                <td>
                                    <AssignmentCard :is-priority-job="data.data.priority !== null"
                                        v-if="data.data.deleted_at === null" role="tour-guide"
                                        :is-selected="assignIds.includes(item.job_transfer_uuid)"
                                        :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        :reAssigned="item.children.filter(a => a.deleted_at === null)"
                                        @assign="handleCheckboxClick(item.job_transfer_uuid, $event)" />
                                    <p v-else>Not Available</p>
                                </td>
                                <td>
                                    <v-btn density="compact" icon="mdi-pencil" @click="openEditItineraryDialog(item)"
                                        color="primary" variant="text"
                                        v-if="(data && data.data && !data.data.priority) && (data && data.data && data.data.deleted_at === null)"></v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </div>

            <div v-if="assignIds.length > 0" class="transfer-assign-shortcut d-flex align-center">
                <v-btn prepend-icon="mdi-close" color="error" @click="resetAssignIds()" class="mr-3">
                    Unselect
                </v-btn>
                <v-btn prepend-icon="mdi-check-circle-outline" class="button-1" @click="openAssignDetailDialog">
                    Assign Transfer
                </v-btn>
            </div>

            <div class="pdf-container" v-if="data && data.data.priority === null && data.data.deleted_at === null">
                <iframe :src="this.pdfData" frameborder="0" v-if="pdfData"></iframe>
                <div v-else>
                    Loading PDF...
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import TopNav from '@/components/TopNav.vue'
import Image from '@/components/Image.vue';
import AssignmentCard from '@/components/AssignmentCard.vue';
import { toast } from 'vue3-toastify';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { httpGet, httpPost } from '@/services/http';
import { indexedDBService } from '@/services/indexedDBService';
import { parsePhoneNumber } from 'libphonenumber-js';
import { debounce } from "vue-debounce";

export default {
    name: 'JobDetailView',
    components: {
        TopNav,
        Image,
        AssignmentCard,
    },
    data() {
        return {
            // Page Variables
            id: this.$route.params.id,
            isLoading: false,
            // Assign DTC Variables
            assignIds: [],
            isShiftKeyPressed: false,
            returnTripQueue: [],
            returnTrip: false,
            dtcData: [],
            dtcId: '',
            isAssignDetailDialogOpen: false,
            currentRole: null,
            // Data Variables
            data: null,
            pdfData: null,
            subscription: null,
            // Delete pre-checks
            isDeleteCheckDialogOpen: false,
            dataToDelete: 0,
            dataToPreserve: 0,
            // Stay Detail Add Variables
            isStayAddDialogOpen: false,
            newStay: {
                startDate: "",
                endDate: "",
                location: "",
                locationSuggestions: [],
            },
            // Stay Detail Edit Variables
            editStayDetailModal: {
                index: null,
                show: false,
            },
            // Guest Detail Edit Variables
            isPaxEditDialogOpen: false,
            editedPax: {
                no_of_adults: 0,
                no_of_childs: 0,
                no_of_infants: 0
            },
            // Itinerary Add Variables
            addItineraryShow: false,
            types: [
                {
                    type: "Private",
                    slug: "pte",
                },
                {
                    type: "SIC",
                    slug: "sic",
                },
            ],
            categories: [],
            orgCategories: [],
            newItinerary: {
                type: "pte",
                category: "ARR",
                date: "",
                pickUpTime: "",
                pickUpLocation: null,
                pickUpLocationSuggestions: [],
                destinationLocation: null,
                destinationLocationSuggestions: [],
                tickets: [],
                remarks: null,
            },
            // Transfer Edit Variables
            editItinerary: {
                type: "",
                category: "",
                date: "",
                pickUpTime: "",
                pickUpLocation: null,
                pickUpLocationSuggestions: [],
                destinationLocation: null,
                destinationLocationSuggestions: [],
                tickets: [],
                remarks: null,
            },
            editTransferModal: {
                index: null,
                show: false,
            },
            // List Data Table
            headers: [
                { title: 'Time', key: 'pick_up_time', sortable: false },
                { title: 'Transfer Type', key: 'transfer_type_name', sortable: false },
                { title: 'Slug', key: 'transfer_type.transfer_type_special_slug', sortable: false },
                { title: 'From', key: 'from.main_text', sortable: false },
                { title: 'To', key: 'to.main_text', sortable: false },
                { title: 'Remarks', key: 'remarks', sortable: false },
                { title: 'Driver', key: 'driver', sortable: false },
                { title: 'Contractor', key: 'contractor', sortable: false },
                { title: 'Tour Guide', key: 'tour_guide', sortable: false },
                { title: 'Actions', key: 'actions', sortable: false },
            ],
            // Loadings
            locationLoading: {
                stay: false,
                pick_up: false,
                destination: false,
            },
            // Prioirty Job Variables
            isPriorityJobDialogOpen: false,
            jobTypes: [],
            pings: [],
            paymentTypes: [],
            vehicleTypes: [],
            job: {
                price: '',
                type: 'scheduled',
                ping: 'fleet',
                paymentType: 'cash',
                vehicleType: 'sedan'
            },
            // Validations
            guestValidation: {
                firstName: [(v) => !!v || "First name is required"],
                lastName: [(v) => !!v || "Last name is required"],
                contactNumber: [(v) => !!v || "Contact number is required"],
                emailAddress: [
                    (v) => !!v || "Email address is required",
                    (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "Invalid email address",
                ],
                numberValidation: [
                    (v) => !v || /^(0|[1-9][0-9]*)$/.test(v) || "Only numbers are allowed",
                ],
                priceValidation: [
                    v => !!v || 'Price is required',
                    v => !isNaN(parseFloat(v)) && v >= 0 || 'Invalid price', // Ensure value is a non-negative number
                    v => !isNaN(parseFloat(v)) && v >= 40 || 'Minimum price is $40', // Ensure value is a non-negative number
                    v => /^(\d+(\.\d{0,2})?)$/.test(v) || 'Price must be a valid number with up to 2 decimal places', // Optional: Adjust regex as needed for your price format
                ],
            },
            stayValidation: {
                startDate: [
                    (v) => !!v || "Start date is required",
                    (v) => {
                        if (!this.newStay.endDate || !v) return true;
                        return (
                            new Date(v) <= new Date(this.newStay.endDate) ||
                            "Start date must be before or the same as end date"
                        );
                    },
                ],
                endDate: [
                    (v) => !!v || "End date is required",
                    (v) => {
                        if (!this.newStay.startDate || !v) return true;
                        return (
                            new Date(v) >= new Date(this.newStay.startDate) ||
                            "Start date must be before or the same as end date"
                        );
                    },
                ],
                locationRules: [(v) => !!v || "Location is required"],
            },
            itineraryLocationValidation: {
                pickUpLocationRules: [(v) => !!v || "Pick up location is required"],
                destinationLocationRules: [(v) => !!v || "Destination location is required"],
            },
            jobPriorityTypesSubscription: null,
            jobPingsSubscription: null,
            jobPriorityPaymentTypesSubscription: null,
            vehicleTypesSubscription: null,
        }
    },
    watch: {
        "id":
            async function (newId) {
                if (newId) {
                    this.init();
                }
            },
        "newStay.startDate": function (newVal, oldVal) {
            // When the start date is set and the end date is empty, autofill the end date
            if (newVal && !this.newStay.endDate) {
                this.newStay.endDate = newVal; // Set the end date to the same as start date
            }
        },
        "newStay.endDate": function (newVal) {
            if (this.editStayDetailModal.show && this.$refs.editStayForm) {
                this.$refs.editStayForm.validate();
            } else if (!this.editStayDetailModal.show && this.$refs.addStayForm) {
                this.$refs.addStayForm.validate();
            }
        },
        "newItinerary.type": function (newVal, oldVal) {
            // Your logic here
            console.log('Itinerary type changed from', oldVal, 'to', newVal);
            // Add your custom logic to handle the type change
            this.filterTransferTypes()

            // Check if the selected category is in the new list
            const foundCategory = this.categories.find((a) => a.slug === this.newItinerary.category);

            if (!foundCategory) {
                // If not found, select the first element in the list
                this.newItinerary.category = this.categories.length > 0 ? this.categories[0].slug : null;
            }
        },
    },
    created() {
        this.init();
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.jobPriorityTypesSubscription) {
            this.jobPriorityTypesSubscription.unsubscribe();
        }
        if (this.jobPingsSubscription) {
            this.jobPingsSubscription.unsubscribe();
        }
        if (this.jobPriorityPaymentTypesSubscription) {
            this.jobPriorityPaymentTypesSubscription.unsubscribe();
        }
        if (this.vehicleTypesSubscription) {
            this.vehicleTypesSubscription.unsubscribe();
        }
    },
    methods: {
        // Page Functions
        async init() {
            await Promise.all([
                indexedDBService.getDtcsData(),
                indexedDBService.getJobsData(),
                indexedDBService.getAssignedJobRequestsData(),
                indexedDBService.getTransferTypesData()
            ]);

            // Now that IndexedDB data is loaded, we can fetch the job details and other data
            this.fetchJobDetails();
            this.fetchDtcData();
            this.fetchTransferTypesData();
            this.fetchPriorityData();
        },
        fetchJobDetails() {
            const observableData = liveQuery(async () => {
                const job = await db.jobs.get(this.id);
                if (!job) return null;

                const tourist = await db.users.get(job.tourist_uuid);
                const jobTransfers = await db.job_transfers
                    .where('job_uuid')
                    .equals(job.uuid)
                    .filter(jt => job.deleted_at === null && jt.deleted_at === null)
                    .toArray();

                const stays = await db.stays
                    .where('job_uuid')
                    .equals(job.uuid)
                    .filter(stay => stay.deleted_at === null)
                    .toArray();

                // Fetch transfers for each job_transfer
                const transfers = await Promise.all(
                    jobTransfers.map(async jt => {
                        const transfer = await db.transfers.get(jt.transfer_uuid);
                        if (!transfer) return null;

                        const from = await db.locations.get(transfer.from_uuid);
                        const to = await db.locations.get(transfer.to_uuid);
                        const transferType = await db.transfer_types.get(transfer.transfer_type_special_uuid);

                        // Get assigned job request details using job_transfer_uuid
                        const assignedJobRequestDetails = await db.assigned_job_request_details
                            .where('job_transfer_uuid')
                            .equals(jt.uuid)
                            .toArray();

                        const assignedJobRequests = await Promise.all(
                            assignedJobRequestDetails.map(async detail => {
                                const request = await db.assigned_job_requests.get(detail.assigned_job_request_uuid);
                                const user = request ? await db.users.get(request.user_uuid) : null;
                                return {
                                    ...detail,
                                    assigned_job_request: {
                                        ...request,
                                        user
                                    }
                                };
                            })
                        );

                        // Get children transfers (reassigned transfers)
                        let children = [];
                        if (jt.children) {
                            try {
                                // Parse children if it's a string, otherwise use as is
                                children = typeof jt.children === 'string'
                                    ? JSON.parse(jt.children)
                                    : jt.children;

                                // Ensure children is an array and filter out deleted items
                                children = Array.isArray(children)
                                    ? children.filter(child => !child.deleted_at)
                                    : [];

                            } catch (error) {
                                console.error('Error parsing children:', error);
                                children = [];
                            }
                        }

                        // Parse attendances safely
                        let parsedAttendances = [];
                        if (transfer.attendances) {
                            try {
                                parsedAttendances = typeof transfer.attendances === 'string'
                                    ? JSON.parse(transfer.attendances)
                                    : transfer.attendances;

                                if (!Array.isArray(parsedAttendances)) {
                                    parsedAttendances = [];
                                }
                            } catch (error) {
                                console.error('Error parsing attendances:', error);
                                parsedAttendances = [];
                            }
                        }


                        return {
                            ...transfer,
                            uuid: transfer.uuid,
                            job_transfer_uuid: jt.uuid,
                            from,
                            to,
                            transfer_type: transferType,
                            assigned_job_request_details: assignedJobRequests,
                            attendances: parsedAttendances,
                            children,
                            job_transfer: jt
                        };
                    })
                ).then(transfers => transfers.filter(Boolean));

                // Fetch hotel locations for stays
                const staysWithLocationDetails = await Promise.all(
                    stays.map(async stay => {
                        const stayLocation = await db.locations.get(stay.stay_uuid);
                        return {
                            ...stay,
                            stay: stayLocation
                        };
                    })
                ).then(stays =>
                    stays.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
                );

                return {
                    data: {
                        ...job,
                        tourist,
                        transfers: transfers
                            .sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time)),
                        stays: staysWithLocationDetails,
                    }
                };
            });

            this.subscription = observableData.subscribe(result => {
                this.data = result;
                this.fetchAndDisplayPDF();
            });
        },
        async fetchAndDisplayPDF() {
            try {
                if (this.data && this.data.data && this.data.data.deleted_at !== null) return
                const response = await httpGet('/api/v1/travel_agents/jobs/pdf/' + this.id, {
                    responseType: 'arraybuffer'
                });

                // Convert ArrayBuffer to Base64
                const base64Data = btoa(
                    new Uint8Array(response.data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );

                this.pdfData = null;

                // Store the base64 data
                sessionStorage.setItem('job_details_pdf_base64', `data:application/pdf;base64,${base64Data}`);

                // Set the viewer URL
                this.pdfData = `/pdfjs-4.9.155-dist/web/viewer.html`;
            } catch (error) {
                console.error('Error loading PDF:', error);
            }
        },
        // Assign DTC Functions
        async fetchDtcData() {
            const observableData = liveQuery(async () => {
                const dtcRequests = await db.dtc_requests.orderBy('created_at').reverse().toArray();
                const users = await db.users.toArray();
                const dtcRequestDetails = await db.dtc_request_details.toArray();

                const userMap = new Map(users.map(user => [user.uuid, user]));

                return dtcRequests.map(request => {
                    const requester = userMap.get(request.requester_uuid) || null;
                    const user = userMap.get(request.user_uuid) || null;
                    const details = dtcRequestDetails.filter(detail => detail.dtc_request_uuid === request.uuid);

                    return {
                        ...request,
                        requester,
                        user,
                        details,
                    };
                });
            });

            this.subscription = observableData.subscribe(result => {
                this.dtcData = result
                    .filter(a => a.status === 'Accepted' && !a.deleted_at)
                    .map(a => ({
                        ...a,
                        full_name: `${a.user.first_name} ${a.user.last_name}`
                    }));
                this.filterDTCData();
            });
        },
        toggleDeleteCheckDialog() {
            this.isDeleteCheckDialogOpen = !this.isDeleteCheckDialogOpen;
            if (this.isDeleteCheckDialogOpen) {
                this.deleteJobCheck();
            }
        },
        async deleteJobCheck() {
            this.isLoading = true;
            try {
                httpPost('/api/v1/travel_agents/jobs/deletes/pre-check', { id: this.id }, null)
                    .then((response) => {
                        console.log(response.data.data);
                        this.dataToPreserve = response.data.data.completed_transfers;
                        this.dataToDelete = response.data.data.not_completed_transfers;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.isLoading = false;
                    });

            } catch (error) {
                console.error(error);
            }
        },
        async deleteJob() {
            let id = toast.loading("Please wait...");
            this.isLoading = true;
            // Constructing data object to be sent to API
            let formData = new FormData();

            formData.append("id", this.id);
            try {
                httpPost('/api/v1/travel_agents/jobs/deletes', formData, id)
                    .then(async (response) => {
                        await this.init();
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                        });
                        this.isLoading = false;
                        this.toggleDeleteCheckDialog();
                    })
                    .catch((error) => {
                        console.error(error);
                        this.isLoading = false;
                    });

            } catch (error) {
                console.error(error);
                toast.remove(id);
            }
        },
        togglePriorityJobDialog() {
            this.isPriorityJobDialogOpen = !this.isPriorityJobDialogOpen;
        },
        async sendToPriorityJob() {
            const isValid = await this.$refs.priorityJobForm.validate();

            if (isValid.valid) {
                let id = toast.loading("Please wait...");
                this.isLoading = true;
                try {
                    // // Constructing data object to be sent to API
                    let formData = new FormData();

                    this.assignIds.forEach(id => {
                        formData.append('ids[]', id);
                    });
                    formData.append("priority_job[job_cost]", this.job.price);
                    formData.append("priority_job[type]", this.job.type);
                    formData.append("priority_job[ping]", this.job.ping);
                    formData.append("priority_job[payment_type]", this.job.paymentType);
                    formData.append("priority_job[vehicle_type]", this.job.vehicleType);

                    httpPost("/api/v1/travel_agents/jobs/priorities/transfer", formData, id)
                        .then(async (response) => {
                            const uuid = response.data.data.uuid;
                            toast.update(id, {
                                render: response.data.message,
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                            });
                            this.isLoading = false;
                            this.$router.push({ name: "JobDetail", params: { id: uuid } });
                            this.init();
                            this.togglePriorityJobDialog();
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log(error);
                        });

                } catch (error) {
                    console.error(error);
                    toast.remove(id);
                }
            }
        },
        async updatePaxDetails() {
            const isValid = await this.$refs.guestDetailsForm.validate();

            if (isValid.valid) {
                // Constructing data object to be sent to API
                let formData = new FormData();

                formData.append("id", this.id);
                formData.append("no_of_adults", this.editedPax.no_of_adults);
                formData.append("no_of_childs", this.editedPax.no_of_childs);
                formData.append("no_of_infants", this.editedPax.no_of_infants);

                let id = toast.loading("Please wait...");
                this.isLoading = true;
                httpPost("/api/v1/travel_agents/jobs/updates/pax", formData, id)
                    .then(async (response) => {
                        await this.init();
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        });
                        this.closePaxEditDialog();
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
            }
        },
        filterDTCData() {
            this.dtcId = null;
            if (this.currentRole) {
                this.dtcData = this.dtcData.filter(a => a.user.role.role_slug === this.currentRole);
            } else {
                this.dtcData = [...this.dtcData];
            }
            if (this.currentRole === 'driver') {
                this.dtcData.unshift({
                    full_name: 'Assign to Priority Jobs',
                    uuid: 'priority_jobs',
                    user: {
                        role: {
                            role_name: 'Priority Jobs'
                        }
                    }
                });
                this.dtcId = '';
            }
        },
        assignReturnTrip() {
            if (this.returnTrip) {
                this.assignIds.push(this.returnTrip);
                this.returnTrip = false;
            }
        },
        openAssignDetailDialog() {
            this.isAssignDetailDialogOpen = true;
        },
        closeAssignDetailDialog() {
            this.isAssignDetailDialogOpen = false;
        },
        handleKeyDown(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = true;
            }
        },
        handleKeyUp(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = false;
                const unassignedReturnTrips = this.returnTripQueue.filter(trip => !this.assignIds.includes(trip.job_transfer_uuid));
                if (unassignedReturnTrips.length > 0) {
                    this.returnTrip = true; // Show dialog
                } else {
                    this.returnTripQueue = [];
                }
            }
        },
        processReturnTrips() {
            if (this.returnTripQueue.length > 0) {
                this.returnTripQueue.forEach(trip => {
                    this.assignIds.push(trip.job_transfer_uuid);
                });
                this.returnTripQueue = [];
            }
            this.returnTrip = false;
        },
        resetAssignIds() {
            this.assignIds = [];
            this.currentRole = null;
            this.filterDTCData();
        },

        handleCheckboxClick(jobTransferId, role) {
            const index = this.assignIds.indexOf(jobTransferId);
            if (index === -1) {
                this.assignIds.push(jobTransferId);
                this.currentRole = role;
                this.filterDTCData();

                const selectedTransfer = this.data.data.transfers.find((a) => a.job_transfer_uuid === jobTransferId);
                if (selectedTransfer) {
                    const returnTrip = this.data.data.transfers.find((a) =>
                        a.to.uuid === selectedTransfer.from.uuid &&
                        a.from.uuid === selectedTransfer.to.uuid &&
                        this.isSameDate(a.pick_up_time, selectedTransfer.pick_up_time)
                    );

                    if (returnTrip && !this.assignIds.includes(returnTrip.job_transfer_uuid)) {
                        if (this.isShiftKeyPressed) {
                            this.returnTripQueue.push(returnTrip);
                        } else {
                            this.returnTrip = returnTrip.job_transfer_uuid;
                        }
                    }
                }
            } else {
                this.assignIds.splice(index, 1);
                if (this.assignIds.length === 0) {
                    this.currentRole = null;
                    this.filterDTCData();
                }
            }
        },
        async submitRequest() {
            if (this.dtcId === 'priority_jobs') {
                this.closeAssignDetailDialog();
                this.togglePriorityJobDialog();
                return;
            }

            const data = {
                id: this.dtcId,
                job_transfer_ids: this.assignIds,
                job_ids: [this.id]
            };

            const id = toast.loading('Please wait...');
            this.isLoading = true;

            try {
                await httpPost('/api/v1/travel_agents/jobs/assigns/add', data, id);
                await this.init();
                this.dtcId = null;
                this.currentRole = null;
                this.assignIds = [];
                toast.update(id, {
                    render: "Request submitted successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 5000
                });
                this.isLoading = false;
                this.closeAssignDetailDialog();
                this.fetchJobDetails();
            } catch (error) {
                console.error(error);
                toast.remove(id);
                this.isLoading = false;
            }
        },
        // Stay Detail Add Functions
        openStayAddDialog() {
            this.isStayAddDialogOpen = true;
        },
        closeStayAddDialog() {
            this.isStayAddDialogOpen = false;
            this.newStay = {
                startDate: "",
                endDate: "",
                location: "",
                locationSuggestions: [],
            };
        },
        async addStayDetail() {
            const isValid = await this.$refs.addStayForm.validate();

            if (isValid.valid) {
                // Constructing data object to be sent to API
                let formData = new FormData();

                formData.append("id", this.id);
                formData.append("[stays][0]start_date", this.newStay.startDate);
                formData.append("[stays][0]end_date", this.newStay.endDate);
                formData.append("[stays][0]google_place_suggestion_id", this.newStay.location);

                let id = toast.loading("Please wait...");
                this.isLoading = true;
                httpPost("/api/v1/travel_agents/jobs/stays/create", formData, id)
                    .then(async (response) => {
                        await this.init();
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        });
                        this.newStay = {
                            startDate: "",
                            endDate: "",
                            location: "",
                            locationSuggestions: [],
                        };
                        this.$refs.addStayForm.reset();
                        this.$refs.addStayForm.resetValidation();
                        this.closeStayAddDialog();
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
            }
        },
        async deleteStayDetail() {
            try {
                let formData = new FormData();

                let stay = this.data.data.stays[this.editStayDetailModal.index];
                this.isLoading = true;

                formData.append("id", this.id);
                formData.append("stay_id", stay.uuid);

                let id = toast.loading("Please wait...");
                httpPost("/api/v1/travel_agents/jobs/stays/delete", formData, id)
                    .then(async (response) => {
                        await this.init();
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        });
                        this.closeStayEditDialog();
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
            } catch (error) {
                this.isLoading = false;
                console.log(error);
            }
        },
        async editStayDetail() {
            const isValid = await this.$refs.editStayForm.validate();

            if (isValid.valid) {
                let stay = this.data.data.stays[this.editStayDetailModal.index];
                let formData = new FormData();

                formData.append("id", this.id);
                formData.append("[stays][0]id", stay.uuid);
                formData.append("[stays][0]start_date", this.newStay.startDate);
                formData.append("[stays][0]end_date", this.newStay.endDate);
                formData.append("[stays][0]google_place_suggestion_id", this.newStay.location);

                let id = toast.loading("Please wait...");
                this.isLoading = true;

                httpPost("/api/v1/travel_agents/jobs/stays/update", formData, id)
                    .then(async (response) => {
                        await this.init();
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        });
                        this.newStay = {
                            startDate: "",
                            endDate: "",
                            location: "",
                            locationSuggestions: [],
                        };
                        this.closeStayEditDialog();
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        console.log(error);
                    });
            }
        },
        // Stay Detail Edit Functionss
        openStayEditDialog(index) {
            this.editStayDetailModal.show = true;
            this.editStayDetailModal.index = index;
            let stay = this.data.data.stays[index];
            if (stay) {
                this.newStay = {
                    startDate: stay.start_date,
                    endDate: stay.end_date,
                    location: stay.stay_uuid,
                    locationSuggestions: [
                        stay.stay
                    ],
                };
                console.log(stay)
                console.log(this.newStay)
            }
        },
        closeStayEditDialog() {
            this.newStay = {
                startDate: "",
                endDate: "",
                location: "",
                locationSuggestions: [],
            };
            this.$refs.editStayForm.reset();
            this.$refs.editStayForm.resetValidation();
            this.editStayDetailModal.show = false;
        },
        // Guest Detail Edit Functions
        openPaxEditDialog() {
            this.editedPax = {
                no_of_adults: this.data.data.no_of_adults,
                no_of_childs: this.data.data.no_of_childs,
                no_of_infants: this.data.data.no_of_infants
            };
            this.isPaxEditDialogOpen = true;
        },

        closePaxEditDialog() {
            this.isPaxEditDialogOpen = false;
        },
        fetchLocationSuggestions(value, field, editItinerary) {
            if (!value) {
                this.locationSuggestions = [];
                return;
            }

            httpGet(`/api/v1/users/locations/search?value=${encodeURIComponent(value)} `)
                .then((result) => {
                    console.log("Is Editing Itinerary ", editItinerary);
                    console.log("Current Searching for ", value);
                    if (result.status && result.data) {
                        if (field === "stay") {
                            this.newStay.locationSuggestions = result.data.data;
                        } else if (field === "pick_up") {
                            if (!editItinerary) {
                                this.newItinerary.pickUpLocationSuggestions = result.data.data;
                                if (this.newItinerary.category === "ARR") {
                                    let location = this.categories.find(
                                        (a) => a.slug === this.newItinerary.category
                                    ).location;
                                    this.newItinerary.pickUpLocationSuggestions.unshift(location);
                                }
                            } else {
                                this.editItinerary.pickUpLocationSuggestions = result.data.data;
                                if (this.editItinerary.category === "ARR") {
                                    let location = this.categories.find(
                                        (a) => a.slug === this.editItinerary.category
                                    ).location;
                                    this.editItinerary.pickUpLocationSuggestions.unshift(location);
                                }
                            }
                            console.log(this.newItinerary.pickUpLocation);
                        } else if (field === "destination") {
                            console.log(result.data.data)
                            console.log('Current edit mode: ' + editItinerary)
                            if (!editItinerary) {
                                this.newItinerary.destinationLocationSuggestions = result.data.data;
                                let location = this.categories.find(
                                    (a) => a.slug === this.newItinerary.category
                                ).location;
                                if (this.newItinerary.category !== "ARR" && location) {
                                    this.newItinerary.destinationLocationSuggestions.unshift(location);
                                }
                            } else {
                                this.editItinerary.destinationLocationSuggestions = result.data.data;
                                let location = this.categories.find(
                                    (a) => a.slug === this.editItinerary.category
                                ).location;
                                if (this.editItinerary.category !== "ARR" && location) {
                                    this.editItinerary.destinationLocationSuggestions.unshift(location);
                                }
                            }
                        }
                        this.locationLoading[field] = false;
                    } else {
                        throw new Error("Failed to fetch location suggestions");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    this.locationSuggestions = [];
                    this.locationLoading[field] = false;
                });
        },
        debouncedFetchLocationSuggestions: debounce(function (
            value,
            field,
            editItinerary = false
        ) {
            this.locationLoading[field] = true;
            this.fetchLocationSuggestions(value, field, editItinerary);
        },
            500),
        // Itinerary Add Functions
        fetchTransferTypesData() {
            const observableData =
                liveQuery(async () => {
                    // Fetch transfer types with their related locations and location details
                    const transfers = await db.transfer_types.orderBy('transfer_type_special_name').toArray();
                    const locations = await db.locations.toArray();
                    const locationDetails = await db.location_details.toArray();

                    // Create a map for locations and location details for easy lookup
                    const locationMap = new Map(locations.map(location => [location.uuid, location]));
                    const locationDetailMap = new Map(locationDetails.map(detail => [detail.uuid, detail]));

                    // Attach related location and location details to each transfer
                    const transfersWithDetails = transfers.map(transfer => {
                        const location = locationMap.get(transfer.location_uuid) || null;
                        if (location) {
                            location.detail = locationDetailMap.get(location.detail_uuid) || null;
                        }
                        return {
                            type: transfer.transfer_type_special_name,
                            slug: transfer.transfer_type_special_slug,
                            location: location ? location : null,
                            start_time: transfer.start_time,
                            return_time: transfer.return_time,
                            sic_start_time: transfer.sic_start_time,
                            sic_return_time: transfer.sic_return_time,
                            is_private: transfer.is_private,
                            is_sic_per_head: transfer.is_sic_per_head,
                            is_sic_per_hotel: transfer.is_sic_per_hotel,
                            is_private_tour_guide: transfer.is_private_tour_guide,
                            is_sic_tour_guide: transfer.is_sic_tour_guide,
                        };
                    });

                    return transfersWithDetails;
                });

            this.subscription = observableData.subscribe((result) => {
                this.orgCategories = result
                this.filterTransferTypes()
            });
        },
        fetchPriorityData() {
            // Fetch job priority type
            const observableJobPriorityTypes = liveQuery(async () => {
                return await db.job_priority_types
                    .orderBy('type_name')
                    .filter(type => !type.deleted_at)
                    .toArray();
            });

            this.jobPriorityTypesSubscription = observableJobPriorityTypes.subscribe((result) => {
                this.jobTypes = result.map(type => ({
                    type_name: type.type_name,
                    type_slug: type.type_slug
                }));
            });

            // Fetch job pings
            const observableJobPings = liveQuery(async () => {
                return await db.job_pings
                    .orderBy('ping_name')
                    .filter(ping => !ping.deleted_at)
                    .toArray();
            });

            this.jobPingsSubscription = observableJobPings.subscribe((result) => {
                this.pings = result.map(ping => ({
                    ping_name: ping.ping_name,
                    ping_slug: ping.ping_slug
                }));
            });

            // Fetch payment types
            const observablePaymentTypes = liveQuery(async () => {
                return await db.job_payment_types
                    .orderBy('type_name')
                    .filter(type => !type.deleted_at)
                    .toArray();
            });

            this.jobPriorityPaymentTypesSubscription = observablePaymentTypes.subscribe((result) => {
                this.paymentTypes = result.map(type => ({
                    type_name: type.type_name,
                    type_slug: type.type_slug
                }));
            });

            // Fetch vehicle types
            const observableVehicleTypes = liveQuery(async () => {
                return await db.vehicle_types
                    .orderBy('type_name')
                    .filter(type => !type.deleted_at)
                    .toArray();
            });

            this.vehicleTypesSubscription = observableVehicleTypes.subscribe((result) => {
                this.vehicleTypes = result.map(type => ({
                    type_name: type.type_name,
                    type_slug: type.type_slug
                }));
            });
        },
        filterTransferTypes() {
            if (!(this.editTransferModal.show === true)) {
                if (this.newItinerary.type === 'pte') {
                    this.categories = this.orgCategories.filter((a) => a.is_private === 1)
                } else {
                    this.categories = this.orgCategories.filter((a) => a.is_sic_per_head === 1)
                }
            } else {
                if (this.editItinerary.type === 'pte') {
                    this.categories = this.orgCategories.filter((a) => a.is_private === 1)
                } else {
                    this.categories = this.orgCategories.filter((a) => a.is_sic_per_head === 1)
                }
            }
        },
        addItinerary(date) {
            this.addItineraryShow = !this.addItineraryShow;
            const defaultTime = "09:00";
            const returnTime = "21:00";

            this.newItinerary = {
                type: "pte",
                category: "ARR",
                date: this.$moment(date).format('YYYY-MM-DD'),
                pickUpTime: defaultTime,
                pickUpLocation: "",
                pickUpLocationSuggestions: [],
                destinationLocation: "",
                destinationLocationSuggestions: [],
                tickets: [],
                remarks: "",
                returnTime: returnTime,
            };

            const matchingStay = this.data.data.stays.find((stay) => {
                const stayStart = new Date(stay.start_date).getTime();
                const stayEnd = new Date(stay.end_date).getTime();
                const itineraryDateTime = new Date(date).getTime();
                return itineraryDateTime >= stayStart && itineraryDateTime <= stayEnd;
            });

            if (this.getItinerariesForDate(date).length == 0) {
                if (matchingStay) {
                    this.newItinerary.pickUpLocation = matchingStay.stay_uuid;
                    this.newItinerary.pickUpLocationSuggestions = [matchingStay.stay];
                }
            } else {
                const lastItinerary = this.getItinerariesForDate(date)[
                    this.getItinerariesForDate(date).length - 1
                ];

                this.newItinerary.type = lastItinerary.transfer_type_slug;
                this.newItinerary.category = lastItinerary.transfer_type.transfer_type_special_slug;

                this.newItinerary.pickUpLocation = lastItinerary.to.uuid;
                this.newItinerary.pickUpLocationSuggestions = [lastItinerary.to];

                const lastPickupTime = this.$moment(lastItinerary.pick_up_time);
                const newPickupTime = lastPickupTime.add(1, 'hour');

                this.newItinerary.pickUpTime = newPickupTime.format('HH:mm');
            }
        },
        getItinerariesForDate(date) {
            if (!this.data?.data?.transfers) {
                return [];
            }

            const formattedDate = this.$moment(date).format('YYYY-MM-DD');

            return this.data.data.transfers.filter((transfer) => {
                const transferDate = this.$moment(transfer.pick_up_time).format('YYYY-MM-DD');
                return transferDate === formattedDate;
            });
        },
        addTickets(event, isEdit = false) {
            const files = event.target.files; // Get selected files from input event
            const itinerary = isEdit ? this.editItinerary : this.newItinerary;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // Check if file is not already selected
                if (!itinerary.tickets.find((selectedFile) => selectedFile.name === file.name)) {
                    itinerary.tickets.push(file); // Add file to the appropriate tickets array
                }
            }
        },
        onCategoryChange(newValue, isEdit = false) {
            console.log("Category changed to:", newValue);

            if (!newValue) {
                // If newValue is null or undefined, reset the relevant fields
                if (isEdit) {
                    this.editItinerary.pickUpLocation = null;
                    this.editItinerary.pickUpLocationSuggestions = [];
                    this.editItinerary.destinationLocation = null;
                    this.editItinerary.destinationLocationSuggestions = [];
                } else {
                    this.newItinerary.pickUpLocation = null;
                    this.newItinerary.pickUpLocationSuggestions = [];
                    this.newItinerary.destinationLocation = null;
                    this.newItinerary.destinationLocationSuggestions = [];
                }
                return;
            }

            let category = this.categories.find((a) => a.slug === newValue);
            let location = category.location;
            if (location) {
                if (newValue === "ARR") {
                    if (isEdit) {
                        this.editItinerary.pickUpLocation = location.uuid;
                        this.editItinerary.pickUpLocationSuggestions = [location];
                    } else {
                        this.newItinerary.pickUpLocation = location.uuid;
                        this.newItinerary.pickUpLocationSuggestions = [location];
                    }
                } else {
                    if (isEdit) {
                        this.editItinerary.destinationLocation = location.uuid;
                        this.editItinerary.destinationLocationSuggestions = [location];
                    } else {
                        this.newItinerary.destinationLocation = location.uuid;
                        this.newItinerary.destinationLocationSuggestions = [location];
                    }
                }
            }

            if (category.start_time !== null || category.return_time !== null || category.sic_start_time !== null || category.sic_return_time !== null) {
                const getFormattedTime = (date, timeString) => {
                    if (!timeString) {
                        return null;
                    }
                    const [hours, minutes] = timeString.split(":").map(Number);
                    const currentDate = new Date(date);
                    currentDate.setHours(hours, minutes, 0, 0); // Ensure seconds and milliseconds are set to 0
                    return `${`0${currentDate.getHours()}`.slice(-2)}:${`0${currentDate.getMinutes()}`.slice(-2)}`;
                };

                const date = isEdit ? this.editItinerary.date : this.newItinerary.date;

                if (isEdit) {
                    if (this.editItinerary.type === "pte") {
                        if (category && category.start_time) {
                            this.editItinerary.pickUpTime = getFormattedTime(date, category.start_time);
                        }
                    } else {
                        if (category && category.sic_start_time) {
                            this.editItinerary.pickUpTime = getFormattedTime(date, category.sic_start_time);
                        }
                    }
                } else {
                    if (this.newItinerary.type === "pte") {
                        if (category && category.start_time) {
                            this.newItinerary.pickUpTime = getFormattedTime(date, category.start_time);
                        }
                        if (category && category.return_time) {
                            this.newItinerary.returnTime = getFormattedTime(date, category.return_time);
                        }
                    } else {
                        if (category && category.sic_start_time) {
                            this.newItinerary.pickUpTime = getFormattedTime(date, category.sic_start_time);
                        }
                        if (category && category.sic_return_time) {
                            this.newItinerary.returnTime = getFormattedTime(date, category.sic_return_time);
                        }
                    }
                }
            }
        },
        // Edit Itinerary
        openEditItineraryDialog(item) {
            const index = this.data.data.transfers.findIndex(transfer => transfer.uuid === item.uuid);
            if (index !== -1) {
                this.editTransferModal.index = index;
                this.editTransferModal.show = true;
                this.editItinerary = {
                    type: item.transfer_type_slug,
                    category: item.transfer_type.transfer_type_special_slug,
                    date: this.$moment(item.pick_up_time).format('YYYY-MM-DD'),
                    pickUpTime: this.$moment(item.pick_up_time).format('HH:mm'),
                    pickUpLocation: item.from.uuid,
                    pickUpLocationSuggestions: [item.from],
                    destinationLocation: item.to.uuid,
                    destinationLocationSuggestions: [item.to],
                    tickets: [],
                    uploadedTickets: item.tickets.filter((a) => a.deleted_at === null) || [],
                    remarks: item.remarks,
                    returnTime: item.return_time ? this.$moment(item.return_time).format('HH:mm') : null,
                };
                this.filterTransferTypes();
            } else {
                console.error('Transfer not found in data.data.transfers');
            }
        },
        closeEditItineraryDialog() {
            this.editTransferModal.show = false;
            this.editTransferModal.index = null;
            this.editItinerary = {
                type: "",
                category: "",
                date: "",
                pickUpTime: "",
                pickUpLocation: null,
                pickUpLocationSuggestions: [],
                destinationLocation: null,
                destinationLocationSuggestions: [],
                tickets: [],
                remarks: null,
            };
        },
        async deleteItinerary() {
            const isValid = await this.$refs.editItineraryForm.validate();

            if (isValid.valid) {
                // Create FormData for file upload
                let formData = new FormData();
                formData.append("id", this.id);
                formData.append("transfer_id", this.data.data.transfers[this.editTransferModal.index].uuid);


                try {
                    let id = toast.loading("Please wait...");
                    this.isLoading = true;
                    httpPost("/api/v1/travel_agents/jobs/transfers/delete", formData, id)
                        .then(async (response) => {
                            await this.init();
                            toast.update(id, {
                                render: response.data.message,
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                            });
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log(error);
                        });

                    this.closeEditItineraryDialog();
                } catch (error) {
                    console.error("Error deleting transfer:", error);
                    toast.error("Failed to delete transfer");
                    this.isLoading = false;
                }
            }
        },
        async deleteTicket(uuid) {
            const isValid = await this.$refs.editItineraryForm.validate();

            if (isValid.valid) {
                // Create FormData for file upload
                let formData = new FormData();
                formData.append("id", this.id);
                formData.append("transfer_id", this.data.data.transfers[this.editTransferModal.index].uuid);
                formData.append("ticket_id", uuid);


                try {
                    let id = toast.loading("Please wait...");
                    this.isLoading = true;
                    httpPost("/api/v1/travel_agents/jobs/transfers/tickets/delete", formData, id)
                        .then(async (response) => {
                            await this.init();
                            toast.update(id, {
                                render: response.data.message,
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                            });
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log(error);
                        });

                    this.closeEditItineraryDialog();
                } catch (error) {
                    console.error("Error deleting ticket:", error);
                    toast.error("Failed to delete ticket");
                    this.isLoading = false;
                }
            }
        },
        async updateItinerary() {
            const isValid = await this.$refs.editItineraryForm.validate();

            if (isValid.valid) {
                // Create FormData for file upload
                let formData = new FormData();
                formData.append(`id`, this.id);
                formData.append(`job[transfers][0][id]`, this.data.data.transfers[this.editTransferModal.index].uuid);
                formData.append(`job[transfers][0][transfer_type_slug]`, this.editItinerary.type);
                formData.append(
                    `job[transfers][0][transfer_type_special_slug]`,
                    this.editItinerary.category
                );
                formData.append(
                    `job[transfers][0][pick_up_time]`,
                    this.formatDateTime(this.editItinerary.date, this.editItinerary.pickUpTime)
                );
                formData.append(`job[transfers][0][pick_up]`, this.editItinerary.pickUpLocation);
                formData.append(
                    `job[transfers][0][destination]`,
                    this.editItinerary.destinationLocation
                );
                formData.append(`job[transfers][0][remarks]`, this.editItinerary.remarks);
                // Handling tickets as files
                this.editItinerary.tickets.forEach((file, fileIndex) => {
                    formData.append(`job[transfers][0][tickets][${fileIndex}]`, file);
                });

                try {
                    const config = {
                        method: "post",
                        headers: { "Content-Type": "multipart/form-data" },
                    };

                    let id = toast.loading("Please wait...");
                    this.isLoading = true;
                    httpPost("/api/v1/travel_agents/jobs/transfers/update", formData, id, config)
                        .then(async (response) => {
                            await this.init();
                            toast.update(id, {
                                render: response.data.message,
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                            });
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.log(error);
                        });

                    this.closeEditItineraryDialog();
                } catch (error) {
                    console.error("Error updating itinerary:", error);
                    toast.error("Failed to update itinerary");
                    this.isLoading = false;
                }
            }
        },
        async addTransferDetail() {
            const isValid = await this.$refs.addItineraryForm.validate();

            if (isValid.valid) {
                // Create FormData for file upload
                let formData = new FormData();
                formData.append(`id`, this.id);

                // Add outbound transfer
                formData.append(
                    `job[transfers][0][transfer_type_slug]`,
                    this.newItinerary.type
                );
                formData.append(
                    `job[transfers][0][transfer_type_special_slug]`,
                    this.newItinerary.category
                );
                formData.append(
                    `job[transfers][0][pick_up_time]`,
                    this.formatDateTime(this.newItinerary.date, this.newItinerary.pickUpTime)
                );
                formData.append(
                    `job[transfers][0][pick_up]`,
                    this.newItinerary.pickUpLocation
                );
                formData.append(
                    `job[transfers][0][destination]`,
                    this.newItinerary.destinationLocation
                );
                formData.append(`job[transfers][0][remarks]`, this.newItinerary.remarks || '');

                // Handle tickets for outbound transfer
                this.newItinerary.tickets.forEach((file, fileIndex) => {
                    formData.append(`job[transfers][0][tickets][${fileIndex}]`, file);
                });

                // If return time is specified, add return transfer
                if (this.newItinerary.returnTime) {
                    formData.append(
                        `job[transfers][1][transfer_type_slug]`,
                        this.newItinerary.type
                    );
                    formData.append(
                        `job[transfers][1][transfer_type_special_slug]`,
                        this.newItinerary.category
                    );
                    formData.append(
                        `job[transfers][1][pick_up_time]`,
                        this.formatDateTime(this.newItinerary.date, this.newItinerary.returnTime)
                    );
                    // Swap pickup and destination for return
                    formData.append(
                        `job[transfers][1][pick_up]`,
                        this.newItinerary.destinationLocation
                    );
                    formData.append(
                        `job[transfers][1][destination]`,
                        this.newItinerary.pickUpLocation
                    );
                    formData.append(`job[transfers][1][remarks]`, this.newItinerary.remarks || '');
                }

                try {
                    const config = {
                        method: "post",
                        headers: { "Content-Type": "multipart/form-data" },
                    };

                    let id = toast.loading("Please wait...");
                    this.isLoading = true;

                    await httpPost(
                        "/api/v1/travel_agents/jobs/transfers/create",
                        formData,
                        id,
                        config
                    )
                        .then(async (response) => {
                            await this.init();
                            toast.update(id, {
                                render: response.data.message,
                                type: "success",
                                isLoading: false,
                                autoClose: 5000,
                            });
                            this.addItineraryShow = false;
                            // Reset form after successful submission
                            this.newItinerary = {
                                type: "pte",
                                category: "ARR",
                                date: "",
                                pickUpTime: "09:00",
                                pickUpLocation: null,
                                pickUpLocationSuggestions: [],
                                destinationLocation: null,
                                destinationLocationSuggestions: [],
                                tickets: [],
                                remarks: "",
                                returnTime: "21:00",
                            };
                            this.isLoading = false;
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            console.error(error);
                            toast.remove(id);
                        });
                } catch (error) {
                    console.error("Error adding transfer:", error);
                    toast.error("Failed to add transfer");
                    this.isLoading = false;
                }
            }
        },
        formatPrice() {
            if (!isNaN(this.job.price) && this.job.price !== '') {
                this.job.price = parseFloat(this.job.price).toFixed(2);
            }
        },
        sendWhatsapp(number) {
            const formattedNumber = number.toString().replace(/[\s-()+]/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${formattedNumber}`, '_blank').focus();
        },
        isSameDate(date1, date2) {
            const d1 = new Date(date1);
            const d2 = new Date(date2);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        },
        formatDateFull(date) {
            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();
            return `${day} ${month} ${year}`;
        },
        formatDateTime(date, time) {
            const dateObj = new Date(`${date} ${time}`);
            const year = dateObj.getFullYear();
            const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
            const day = ("0" + dateObj.getDate()).slice(-2);
            const hours = ("0" + dateObj.getHours()).slice(-2);
            const minutes = ("0" + dateObj.getMinutes()).slice(-2);
            const seconds = ("0" + dateObj.getSeconds()).slice(-2);

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
    },
    computed: {
        groupedTransfers() {
            if (this.data?.data?.transfers) {
                const transfers = this.data.data.transfers;
                const groups = {};

                transfers.forEach(transfer => {
                    const date = this.$moment(transfer.pick_up_time).format('DD MMM yyyy');
                    if (date !== "Invalid Date") {
                        if (!groups[date]) {
                            groups[date] = [];
                        }

                        groups[date].push({
                            ...transfer,
                            transfer_type_name: transfer.transfer_type_name,
                            transfer_type_special_slug: transfer.transfer_type?.transfer_type_special_slug,
                        });
                    } else {
                        console.warn(`Invalid date for transfer:`, transfer);
                    }
                });

                Object.keys(groups).forEach(date => {
                    groups[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time));
                });

                const sortedGroups = Object.keys(groups)
                    .sort((a, b) => new Date(a) - new Date(b));

                return sortedGroups.map(date => ({
                    date,
                    transfers: groups[date]
                }));
            }
            return [];
        },
        availableDates() {
            const dates = new Set();

            // Add dates from stays
            if (this.data?.data?.stays) {
                this.data.data.stays.forEach(stay => {
                    let currentDate = new Date(stay.start_date);
                    const endDate = new Date(stay.end_date);

                    while (currentDate <= endDate) {
                        dates.add(this.$moment(currentDate).format('YYYY-MM-DD'));
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                });
            }

            // Add dates from existing transfers
            if (this.data?.data?.transfers) {
                this.data.data.transfers.forEach(transfer => {
                    const transferDate = this.$moment(transfer.pick_up_time).format('YYYY-MM-DD');
                    dates.add(transferDate);
                });
            }

            // Convert to array and sort
            return Array.from(dates).sort();
        }
    }
}
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
<style scoped>
.alert-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: var(--secondary-dark-grey);
    padding: 10px;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.job-infomation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.job-infomation .button-1 {
    margin-top: unset !important;
}

.user .image-container {
    margin-right: 20px;
}

.priority-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.timer {
    margin-top: unset !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.timer h3 {
    font-size: 2.5em;
}

.list {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 0;
    flex: 1;
}

.list-item {
    cursor: pointer;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.list-item:hover {
    background-color: var(--secondary-dark-grey);
}

.list-item-subtitle {
    font-size: 0.9em;
    color: #666;
}

@media screen and (max-width: 850px) {
    .list {
        text-align: center;
    }

    .priority-details {
        grid-template-columns: repeat(1, 1fr);
    }

    .list-item {
        text-align: center;
    }
}

.pulse-animation {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}
</style>
