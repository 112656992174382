<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Request Detail</h1>
                <div>
                    <p>Dashboard > Requests > Detail</p>
                    <div class="job-infomation">
                        <div href="" class="button-1" v-if="data && data.deleted_at === null">{{
                            data.status ?? 'Checking...' }}</div>
                        <div href="" class="button-1" v-else>Deleted</div>
                        <v-btn href="" color="error" prepend-icon="mdi-delete" :loading="isLoading"
                            @click="deleteRequest" v-if="data && data.deleted_at === null">Delete
                            Request</v-btn>
                        <v-chip color="error" v-else>
                            Request Deleted
                        </v-chip>
                    </div>
                </div>
            </div>

            <div class="container">
                <div v-if="requestUser" class="user-details">
                    <div class="user">
                        <div class="image-container">
                            <Image :imagePath="requestUser.image_path"></Image>
                        </div>
                        <div class="details">
                            <h1 class="name">
                                {{ formatUserName(requestUser.first_name, requestUser.last_name) }}
                                <v-chip class="ml-2" color="primary" size="small">
                                    {{ requestUser.role.role_name }}
                                </v-chip>
                            </h1>
                            <p>+{{ getPrimaryContactNumber(requestUser) }}</p>
                        </div>
                    </div>
                    <div class="contacts">
                        <a href="" @click.prevent="$router.push('/chats/' + requestUser.uuid)" class="chat">
                            <img src="@/assets/icons/chat.svg" alt="chat.svg">
                        </a>
                        <a href="" @click.prevent="sendWhatsapp(requestUser.contact_numbers.find((a) => a.is_primary ===
                            1).country_code.country_code +
                            data.data.tourist.contact_numbers.find((a) => a.is_primary === 1).contact_number)"
                            class="whatsapp">
                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp.svg">
                        </a>
                    </div>
                </div>
                <div v-else>
                    <p>Loading...</p>
                </div>
            </div>

            <div class="tabs">
                <div class="horizontal-nav">
                    <ul class="nav-list">
                        <p class="mr-3">Filter By: </p>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('date')"
                                :class="{ 'button-1': currentFilter === 'date' }">Date</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('guest')"
                                :class="{ 'button-1': currentFilter === 'guest' }">Guest</a>
                        </li>
                    </ul>
                </div>

                <!-- Guest View -->
                <div class="filter-by-user" v-if="currentFilter === 'guest'">
                    <div class="each-user" v-for="(group, index) in groupedData" :key="index">
                        <div class="container">
                            <div v-if="group.tourist" class="user-details">
                                <div class="user">
                                    <div class="image-container">
                                        <Image :imagePath="group.tourist.image_path"></Image>
                                    </div>
                                    <div class="details">
                                        <h1 class="name">
                                            {{ group.tourist.first_name + ' ' + group.tourist.last_name }}
                                            <v-chip class="ml-2" color="primary" size="small">
                                                {{ group.tourist.role.role_name }}
                                            </v-chip>
                                        </h1>
                                        <p>+{{ getPrimaryContactNumber(group.tourist) }}</p>
                                    </div>
                                </div>
                                <div class="contacts">
                                    <a href="" @click.prevent="$router.push('/chats/' + group.tourist.uuid)"
                                        class="chat">
                                        <img src="@/assets/icons/chat.svg" alt="chat.svg">
                                    </a>
                                    <a href="" @click.prevent="sendWhatsapp(getPrimaryContactNumber(group.tourist))"
                                        class="whatsapp">
                                        <img src="@/assets/icons/whatsapp.svg" alt="whatsapp.svg">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="transfer-container" v-for="(date, dateIndex) in group.dates" :key="dateIndex">
                            <div>
                                <div class="transfer-header">
                                    <h3>{{ formatDate(date.date) }}</h3>
                                </div>

                                <v-data-table :headers="guestHeaders" :items="date.transfers" :items-per-page="-1"
                                    :sort-by="[{ key: 'pick_up_time', order: 'asc' }]" disable-pagination
                                    hide-default-footer item-key="uuid">
                                    <template v-slot:item="{ item }">
                                        <tr>
                                            <td>{{ formatTime(item.pick_up_time) }}</td>
                                            <td>{{ item.transfer_type_name }}</td>
                                            <td>{{ item.transfer_type.transfer_type_special_slug }}</td>
                                            <td>{{ item.from.main_text }}</td>
                                            <td>{{ item.to.main_text }}</td>
                                            <td>{{ item.remarks || '-' }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Date View -->
                <div class="transfer-container" v-if="currentFilter === 'date'" v-for="(group, index) in groupedByDate"
                    :key="index">
                    <div>
                        <div class="transfer-header">
                            <h3>{{ formatDate(group.date) }}</h3>
                        </div>

                        <v-data-table :headers="headers" :items="group.transfers" :items-per-page="-1"
                            :sort-by="[{ key: 'pick_up_time', order: 'asc' }]" disable-pagination hide-default-footer
                            item-key="uuid">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ formatTime(item.pick_up_time) }}</td>
                                    <td>{{ item.transfer_type_name }}</td>
                                    <td>{{ item.transfer_type.transfer_type_special_slug }}</td>
                                    <td>{{ item.from.main_text }}</td>
                                    <td>{{ item.to.main_text }}</td>
                                    <td>{{ item.remarks || '-' }}</td>
                                    <td v-if="item.tourist">
                                        <div class="user">
                                            <div class="details">
                                                <p>{{ item.tourist.first_name + ' ' + item.tourist.last_name }}</p>
                                                <p>+{{ getPrimaryContactNumber(item.tourist) }}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-else>-</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';
import { toast } from 'vue3-toastify';
import { httpPost } from '@/services/http';

export default {
    name: 'RequestsView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            id: this.$route.params.id,
            data: null,
            requestUser: null,
            groupedData: [],
            groupedByDate: [],
            currentFilter: 'date',
            isLoading: false,
            subscription: null,
            headers: [
                { title: 'Time', key: 'pick_up_time', sortable: true },
                { title: 'Transfer Type', key: 'transfer_type_name' },
                { title: 'Slug', key: 'transfer_type.transfer_type_special_slug' },
                { title: 'From', key: 'from.main_text' },
                { title: 'To', key: 'to.main_text' },
                { title: 'Remarks', key: 'remarks' },
                { title: 'Tourist', key: 'tourist' },
            ],
            guestHeaders: [
                { title: 'Time', key: 'pick_up_time', sortable: true },
                { title: 'Transfer Type', key: 'transfer_type_name' },
                { title: 'Slug', key: 'transfer_type.transfer_type_special_slug' },
                { title: 'From', key: 'from.main_text' },
                { title: 'To', key: 'to.main_text' },
                { title: 'Remarks', key: 'remarks' },
            ],
        };
    },
    created() {
        this.init()
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        async init() {
            await indexedDBService.getAssignedJobRequestsData();
            this.fetchData();
        },
        async fetchData() {
            try {
                // Create a liveQuery that observes the main request and all related data
                const observableData = liveQuery(async () => {
                    // Fetch the main request
                    const request = await db.assigned_job_requests
                        .where('uuid')
                        .equals(this.id)
                        .first();

                    if (!request) {
                        throw new Error('Request not found');
                    }

                    // Fetch requester information
                    const requester = await db.users
                        .where('uuid')
                        .equals(request.requester_uuid)
                        .first();

                    // Fetch all request details
                    const details = await db.assigned_job_request_details
                        .where('assigned_job_request_uuid')
                        .equals(request.uuid)
                        .toArray();

                    // Fetch all related data for each detail
                    const detailsWithData = await Promise.all(
                        details.map(async (detail) => {
                            try {
                                // Get job transfer
                                const jobTransfer = await db.job_transfers
                                    .where('uuid')
                                    .equals(detail.job_transfer_uuid)
                                    .first();

                                if (!jobTransfer) return null;

                                // Get transfer
                                const transfer = await db.transfers
                                    .where('uuid')
                                    .equals(jobTransfer.transfer_uuid)
                                    .first();

                                if (!transfer) return null;

                                const transferType = await db.transfer_types.get(transfer.transfer_type_special_uuid);

                                // Get locations
                                const [fromLocation, toLocation] = await Promise.all([
                                    db.locations.get(transfer.from_uuid),
                                    db.locations.get(transfer.to_uuid)
                                ]);

                                // Get job and tourist info
                                const job = await db.jobs
                                    .where('uuid')
                                    .equals(jobTransfer.job_uuid)
                                    .first();

                                if (!job) return null;

                                const tourist = await db.users
                                    .where('uuid')
                                    .equals(job.tourist_uuid)
                                    .first();

                                return {
                                    ...detail,
                                    transfer: {
                                        ...transfer,
                                        from: fromLocation,
                                        to: toLocation,
                                        transfer_type: transferType,
                                    },
                                    job: {
                                        ...job,
                                        tourist
                                    }
                                };
                            } catch (error) {
                                console.error('Error processing detail:', error);
                                return null;
                            }
                        })
                    );

                    return {
                        request,
                        requester,
                        details: detailsWithData.filter(detail => detail !== null)
                    };
                });

                // Subscribe to the observable and update the component data
                this.subscription = observableData.subscribe({
                    next: (result) => {
                        if (result) {
                            this.data = result.request;
                            this.requestUser = result.requester;
                            this.processData({
                                ...result.request,
                                details: result.details
                            });
                            this.processDataByDate({
                                ...result.request,
                                details: result.details
                            });
                        }
                        console.log(result)
                    },
                    error: (error) => {
                        console.error('LiveQuery error:', error);
                        toast.error('Error loading request details');
                    }
                });

            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Error loading request details');
            }
        },

        async deleteRequest() {
            let id = toast.loading("Please wait...");
            this.isLoading = true;

            try {
                httpPost('/api/v1/travel_agents/jobs/assigns/delete', { id: this.id }, id)
                    .then(async (response) => {
                        this.init()
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                        });
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.isLoading = false;
                    });

            } catch (error) {
                console.error(error);
            }
        },
        changeFilter(input) {
            this.currentFilter = input;
        },

        processData(data) {
            const grouped = {};

            data.details.filter(a => !data.deleted_at ? !a.deleted_at : true).forEach(detail => {
                const transfer = detail.transfer;
                const job = detail.job;
                const tourist = job?.tourist;

                if (tourist && transfer) {
                    const touristUUID = tourist.uuid;
                    const transferDate = new Date(transfer.pick_up_time).toDateString();

                    if (!grouped[touristUUID]) {
                        grouped[touristUUID] = {
                            tourist: tourist,
                            dates: {}
                        };
                    }

                    if (!grouped[touristUUID].dates[transferDate]) {
                        grouped[touristUUID].dates[transferDate] = [];
                    }

                    grouped[touristUUID].dates[transferDate].push({
                        ...transfer,
                        job: job,
                        createdAt: detail.created_at
                    });
                }
            });

            this.groupedData = Object.values(grouped).map(touristGroup => ({
                tourist: touristGroup.tourist,
                dates: Object.keys(touristGroup.dates).sort().map(date => ({
                    date,
                    transfers: touristGroup.dates[date].sort((a, b) =>
                        new Date(a.pick_up_time) - new Date(b.pick_up_time)
                    )
                }))
            }));
        },
        formatUserName(firstName, lastName) {
            const cleanFirstName = firstName === 'null' || firstName === null ? '' : firstName;
            const cleanLastName = lastName === 'null' || lastName === null ? '' : lastName;
            return `${cleanFirstName} ${cleanLastName}`.trim();
        },
        processDataByDate(data) {
            const grouped = {};

            data.details.filter(a => !data.deleted_at ? !a.deleted_at : true).forEach(detail => {
                const transfer = detail.transfer;
                const job = detail.job;

                if (transfer) {
                    const date = new Date(transfer.pick_up_time).toDateString();

                    if (!grouped[date]) {
                        grouped[date] = [];
                    }

                    grouped[date].push({
                        ...transfer,
                        tourist: job?.tourist,
                        createdAt: detail.created_at
                    });
                }
            });

            this.groupedByDate = Object.keys(grouped)
                .sort((a, b) => new Date(a) - new Date(b))
                .map(date => ({
                    date,
                    transfers: grouped[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time))
                }));
        },

        formatDate(date) {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();
            return `${day} ${month} ${year}`;
        },

        formatTime(dateTimeString) {
            const date = new Date(dateTimeString);
            const options = { hour: 'numeric', minute: 'numeric', hour12: false };
            return date.toLocaleTimeString('en-US', options);
        },

        getPrimaryContactNumber(user) {
            if (!user || !user.contact_numbers) return '-';
            const primaryContact = user.contact_numbers.find(c => c.is_primary === 1);
            if (!primaryContact) return '-';
            return primaryContact.country_code.country_code + primaryContact.contact_number;
        },

        sendWhatsapp(number) {
            window.open(`https://wa.me/${number}`, '_blank');
        },
    }
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style scoped>
.job-infomation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.job-infomation .button-1 {
    margin-top: unset !important;
}

section#content .title div.button-1 {
    margin-top: 20px;
}

section#content .container {
    padding: 20px;
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius);
}

section#content .container h3 {
    margin-bottom: 10px;
}

section#content .container:not(:first-child) {
    margin-top: 20px;
}

.each-user:not(:last-child) {
    margin-bottom: 50px;
}

.user .image-container {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}

.user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user .name {
    font-size: 17px;
}

.user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.location-details .user {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-bottom: 20px;
}

.tabs .horizontal-nav {
    margin-bottom: 10px;
}

.tabs .horizontal-nav .nav-list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.tabs .horizontal-nav .nav-list li {
    margin-right: 10px;
}

.transfer-container {
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.transfer-header {
    padding: 20px;
    background: var(--primary-blue);
    color: #ffffff;
}

.transfer-header h3 {
    margin: 0;
}

.v-data-table {
    background: transparent;
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
    .transfer-container {
        background: var(--dark-background);
    }

    .user-details .contacts a {
        background-color: var(--dark-surface);
    }

    .v-data-table {
        color: var(--dark-text);
    }
}
</style>