import $ from 'jquery';

const toggleNav = () => {
    let isNavCollapsed = $('nav').hasClass('collapse-nav')
    if (!isNavCollapsed) {
        $('nav').addClass('collapse-nav')
        localStorage.navCollapse = true
    } else {
        $('nav').removeClass('collapse-nav')
        localStorage.navCollapse = false
    }
}

document.addEventListener("DOMContentLoaded", function () {
    if (localStorage.navCollapse === 'true') {
        $("nav").addClass("collapse-nav")
    } else {
        if (!localStorage.navCollapse === undefined) {
            $("nav").removeClass("collapse-nav")
        } else {
            $("nav").addClass("collapse-nav")
        }
    }

    const nav = document.querySelector('nav');

    function checkOverflow() {
        // Ensure the 'nav' element exists
        if (!nav) {
            return;  // Exit the function if 'nav' does not exist
        }

        // Proceed with the original logic
        if (nav.scrollHeight > nav.clientHeight) {
            nav.classList.add('has-overflow');
        } else {
            nav.classList.remove('has-overflow');
        }
    }

    // Run check on initial load
    checkOverflow();

    // Optional: Run check on window resize if your layout is responsive
    window.addEventListener('resize', checkOverflow);


    setTimeout(() => {
        $('nav').css('transition', '1s all');
    }, 500)
});

export default {
    toggleNav,
}