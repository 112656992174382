<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>DTC Detail</h1>
                <p>Dashboard > DTC > Detail</p>
            </div>

            <div class="user-details container">
                <div v-if="data && data.user">
                    <div class="user">
                        <div class="image-container">
                            <Image :imagePath="data.user.image_path">
                            </Image>
                        </div>
                        <div class="details">
                            <h1 class="name">{{ data.user.first_name + ' ' + data.user.last_name }}</h1>
                            <p>+{{ data.user.contact_numbers.find((a) => a.is_primary ===
                                1).country_code.country_code }}{{
                                    data.user.contact_numbers.find((a) => a.is_primary === 1).contact_number }}</p>
                        </div>
                    </div>
                    <div class="contacts">
                        <a href="" @click.prevent="$router.push('/chats/' + data.user.uuid)" class="chat">
                            <img src="@/assets/icons/chat.svg" alt="chat.svg">
                        </a>
                        <a href="" @click.prevent="sendWhatsapp(data.user.contact_numbers.find((a) => a.is_primary ===
                            1).country_code.country_code +
                            data.user.contact_numbers.find((a) => a.is_primary === 1).contact_number)" class="whatsapp">
                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp.svg">
                        </a>
                    </div>
                </div>
                <div v-else>
                    <p>Loading...</p>
                </div>
            </div>


            <div class="horizontal-nav status">
                <div class="button-1">Request Status: <strong v-if="data">{{ data.deleted_at !== null ? 'Deleted' :
                    data.status }}</strong>
                </div>
                <div class="buttons d-flex align-center" v-if="data">
                    <v-chip class="mr-5"
                        v-if="data.user && data.user.user && (data.user.user.uuid === this.profile.id)">
                        Driver - Under Your Fleet
                    </v-chip>
                    <v-btn href="" color="primary" @click.prevent="this.$router.push(`/dtc/${data.uuid}/edit`)"
                        v-if="(data.deleted_at === null) && data.user && data.user.user && (data.user.user.uuid === this.profile.id)">Edit
                        Request</v-btn>
                    <v-btn href="" color="error" @click.prevent="deleteRequest" v-else-if="data.deleted_at === null"
                        prepend-icon="mdi-delete" :loading="isLoading">Delete Request</v-btn>
                </div>
            </div>


            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th class="td-start">No.</th>
                            <th>Slug</th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Requested On</th>
                        </tr>
                    </thead>
                    <tbody v-if="data && data.details">
                        <tr v-for="(request, index) in data.details">
                            <td class="td-start">{{ index + 1 }}.</td>
                            <td>{{ request.transfer_type_special.transfer_type_special_slug }} {{ request.sic === 1 ?
                                '(SIC)' : '' }}</td>
                            <td>{{ request.transfer_type_special.transfer_type_special_name }}
                                {{ request.sic === 1 ? '(SIC)' : '' }}</td>
                            <td>${{ request.price }}</td>
                            <td>{{ this.$moment(request.created_at).format('DD MMM yyyy') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import Image from '@/components/Image.vue';
import { httpGet } from '@/services/http';
import { toast } from 'vue3-toastify';
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'DTCAddDetailView',
    components: {
        TopNav,
        Image
    },
    data() {
        return {
            id: this.$route.params.id,
            data: '',
            isLoading: false,
        }
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
    },
    created() {
        indexedDBService.getDtcsData();
        this.fetchData();
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        fetchData() {
            const observableData = liveQuery(async () => {
                try {
                    // Fetch the single DTC request record
                    const dtcRequest = await db.dtc_requests.get({ uuid: this.id });

                    if (dtcRequest) {
                        // Fetch requester and user associated with the request
                        const requester = await db.users.get({ uuid: dtcRequest.requester_uuid });
                        const user = await db.users.get({ uuid: dtcRequest.user_uuid });

                        // Fetch details for the request and enhance with transfer type special data
                        const requestDetails = await db.dtc_request_details.where({ dtc_request_uuid: dtcRequest.uuid }).toArray();
                        for (let detail of requestDetails) {
                            const transferTypeSpecial = await db.transfer_types.get({ uuid: detail.transfer_type_special_uuid });
                            detail.transfer_type_special = transferTypeSpecial || null;
                        }

                        // Return the enhanced data
                        return {
                            ...dtcRequest,
                            requester,
                            user,
                            details: requestDetails,
                        };
                    }

                    // Return null or an empty object if the request does not exist
                    return null;
                } catch (error) {
                    console.error('Failed to fetch data:', error);
                    return null;
                }
            });

            this.subscription = observableData.subscribe((result) => {
                if (result) {
                    this.data = result;

                    // Sort details first by sic and then by transfer_type_special_slug
                    this.data.details.sort((a, b) => {
                        // Compare by sic first
                        const sicComparison = a.sic - b.sic;
                        if (sicComparison === 0) {
                            // If sic is the same, compare by transfer_type_special_slug
                            if (a.transfer_type_special && b.transfer_type_special) {
                                return a.transfer_type_special.transfer_type_special_slug.localeCompare(b.transfer_type_special.transfer_type_special_slug);
                            } else {
                                return 0;
                            }
                        }
                        return sicComparison;
                    });

                    console.log(result);
                } else {
                    this.data = null;
                    console.log("No data found");
                }
            });
        },
        deleteRequest() {
            let id = toast.loading('Please wait...');
            this.isLoading = true;
            httpGet('/api/v1/travel_agents/dtcs/delete/' + this.id)
                .then(response => {
                    this.data = ''
                    this.data = response.data.data;
                    toast.update(id, {
                        render: response.data.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    });
                    this.isLoading = false;
                    indexedDBService.getDtcsData();
                })
                .catch(error => {
                    console.error('Failed to load data:', error);
                });
        },
        sendWhatsapp(number) {
            // Remove all spaces, dashes, round brackets, and plus signs
            const formattedNumber = number.toString().replace(/[\s-()+]/g, '');
            console.log(formattedNumber);
            window.open(`https://api.whatsapp.com/send?phone=${formattedNumber}`, '_blank').focus();
        }
    },
}
</script>

<style src="@/assets/css/index.css" scoped></style>
<style scoped>
section#content .container {
    padding: 20px;
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius);
}

.user-details>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-details .contacts {
    display: flex;
    gap: 20px;
}

.user-details .contacts a {
    display: grid;
    place-items: center;
}

.user-details .contacts a.whatsapp img {
    height: 30px;
    width: 30px;
}

.user-details .contacts a.chat img {
    height: 25px;
    width: 25px;
}

.user .image-container {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}

.user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user .name {
    font-size: 17px;
}

.user {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.buttons .button-3 {
    margin-left: 0px;
}

.status {
    margin-top: 20px;
    display: inline-block;
}
</style>