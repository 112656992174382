<template>
    <section id="content" class="home">
        <div class="main-content">

            <a href="" class="logo">
                <img src="@/assets/logo/Main_1.png" alt="logo" class="full-logo">
            </a>

            <div class="title pa-1">
                <h1>Forget Password</h1>
                <p>Enter your email address to reset your password</p>
            </div>

            <div class="login-form">
                <v-form ref="form" id="login-form" @submit.prevent="resetPassword()">

                    <div class="email-address">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Email Address" v-model="emailAddress" :rules="emailValidation"
                                placeholder="alexander_tan@gmail.com"></v-text-field>
                        </v-col>
                    </div>

                    <v-col cols="12" class="pa-1 pb-3">
                        <v-btn block class="button-1" type="submit" form="login-form" :loading="isLoading">Reset
                            Password</v-btn>
                    </v-col>

                    <v-col cols="12" class="text-center">
                        <v-btn variant="text" @click.prevent="this.$router.push('/login')">Already have an account?
                            Login Now</v-btn>
                    </v-col>
                </v-form>
            </div>


        </div>
    </section>
</template>

<script>
import { mapActions } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'

// Toast Notification
// In any Vue component, import the toast function
import { toast } from 'vue3-toastify';
import { httpPost } from '@/services/http';

export default {
    name: 'ForgetPasswordView',
    data() {
        return {
            emailAddress: '',
            emailValidation: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            isLoading: false,
        }
    },
    methods: {
        async resetPassword() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid) {
                let id = toast.loading("Please wait...");
                this.isLoading = true;
                const formData = new FormData();
                formData.append('email', this.emailAddress);
                httpPost('/api/v1/travel_agents/reset-password', formData)
                    .then(response => {
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                        });
                        this.isLoading = false
                    })
                    .catch(error => {
                        this.isLoading = false
                        console.log(error)
                    })
            }
        }
    }
}
</script>

<style scoped>
section#content .logo {
    width: 40%;
    margin: 0px auto;
}

section#content .logo img {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

section#content .main-content {
    max-width: 60%;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 950px) {
    section#content .main-content {
        max-width: unset;
    }
}
</style>