<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Add Drivers, Tour Guides & Cons</h1>
                <p>Dashboard > DTC > Add</p>
            </div>

            <div class="request-details">
                <div class="title">
                    <h3>Request Details</h3>
                    <p>Select a driver, tour guide or contractor</p>
                </div>
                <v-form ref="form">
                    <div class="dtc">
                        <v-col cols="12">
                            <v-autocomplete :items="dtc" item-title="full_name" item-value="uuid"
                                label="Select Driver/Tour Guide/Contractor" v-model="id" @keyup="debouncedGetDTC(
                                    $event.target.value,
                                )" :loading=dtcLoading></v-autocomplete>
                        </v-col>
                    </div>
                    <v-col cols="12">
                        <v-card>
                            <v-tabs v-model="tab"
                                style="background-color: var(--primary-blue); color: var(--secondary-white);">
                                <v-tab value="private">Private</v-tab>
                                <v-tab value="sic">SIC</v-tab>
                            </v-tabs>

                            <v-card-text>
                                <v-tabs-window v-model="tab">
                                    <v-tabs-window-item value="private" v-if="tab == 'private'">
                                        <v-col cols="6" sm="6" v-for="(category, index) in categories" :key="index"
                                            style="display: inline-block;">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPrices(category.price) : formatPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                    </v-tabs-window-item>

                                    <v-tabs-window-item value="sic" v-if="tab == 'sic'">
                                        <v-col cols="12">
                                            <v-radio-group v-model="sicType" @change="updateSICCategories">
                                                <v-radio label="Per Head" value="perHead"></v-radio>
                                                <v-radio label="Per Hotel" value="perHotel"
                                                    v-if="currentRole !== 'contractor' && currentRole !== 'tour-guide'"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="6" sm="6" v-for="(category, index) in perHeadCategory" :key="index"
                                            style="display: inline-block;" v-if="sicType == 'perHead'">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPerHeadPrices(category.price) : formatPerHeadPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="6" v-for="(category, index) in perHotelCategory"
                                            :key="index" style="display: inline-block;" v-if="sicType == 'perHotel'">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPerHotelPrices(category.price) : formatPerHotelPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                    </v-tabs-window-item>
                                </v-tabs-window>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <div class=" buttons">
                        <v-btn class="button-1" @click.prevent="submitRequest" :loading="isLoading">Request</v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Loading from '@/components/Loading.vue'
import { httpGet, httpPost } from '@/services/http';
import { toast } from 'vue3-toastify';
import { debounce } from "vue-debounce";
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'DTCAddView',
    components: {
        TopNav,
        Loading,
    },
    data() {
        return {
            id: '',
            currentRole: '',
            tab: 'private',
            dtc: [],
            sicType: 'perHead', // New data property for SIC type
            categories: [],
            perHotelCategory: [],
            perHeadCategory: [],
            priceValidation: [
                v => !!v || 'Price is required',
                v => !isNaN(parseFloat(v)) && v >= 0 || 'Invalid price', // Ensure value is a non-negative number
                v => /^(\d+(\.\d{0,2})?)$/.test(v) || 'Price must be a valid number with up to 2 decimal places', // Optional: Adjust regex as needed for your price format
            ],
            isLoading: false,
            dtcLoading: false,
            subscription: null,
        };
    },
    computed: {
        sicCategories() {
            return this.sicType === 'perHead' ? this.perHeadCategory : this.perHotelCategory;
        }
    },
    watch: {
        id() {
            let user = this.dtc.find((a) => a.uuid === this.id)

            if (user) {
                this.currentRole = user.role.role_slug
                if (this.currentRole === 'contractor') {
                    this.sicType = 'perHead'
                }

                if (this.currentRole === 'tour-guide') {
                    this.sicType = 'perHead'
                }
            }

        }
    },
    created() {
        indexedDBService.getTransferTypesData();
        this.fetchData();
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        fetchData() {
            const observableData =
                liveQuery(async () => {
                    // Fetch transfer types with their related locations and location details
                    const transfers = await db.transfer_types.orderBy('transfer_type_special_name').toArray();

                    const transfersWithDetails = transfers.map(transfer => {
                        return {
                            type: transfer.transfer_type_special_name,
                            slug: transfer.transfer_type_special_slug,
                            start_time: transfer.start_time,
                            return_time: transfer.return_time,
                            sic_start_time: transfer.sic_start_time,
                            sic_return_time: transfer.sic_return_time,
                            is_private: transfer.is_private,
                            is_sic_per_head: transfer.is_sic_per_head,
                            is_sic_per_hotel: transfer.is_sic_per_hotel,
                            is_private_tour_guide: transfer.is_private_tour_guide,
                            is_sic_tour_guide: transfer.is_sic_tour_guide,
                        };
                    });

                    return transfersWithDetails;
                });

            this.subscription = observableData.subscribe((result) => {
                this.allTypes = result;

                console.log(result)

                let privateTypes = this.allTypes.filter((a) => a.is_private === 1)
                this.categories = [];
                privateTypes.forEach((type) => {
                    this.categories.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })

                let perHeadSicTypes = this.allTypes.filter((a) => a.is_sic_per_head === 1)
                this.perHeadCategory = [];
                perHeadSicTypes.forEach((type) => {
                    this.perHeadCategory.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })

                let perHotelCategoryTypes = this.allTypes.filter((a) => a.is_sic_per_hotel === 1)
                this.perHotelCategory = [];
                perHotelCategoryTypes.forEach((type) => {
                    this.perHotelCategory.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })
            });
        },
        getDTC(value) {
            if (!value) {
                this.dtc = [];
                return;
            }

            httpGet(`/api/v1/travel_agents/users/search?search=${value}&types[0]=driver&types[1]=tour-guide&types[2]=contractor&limit=non-fleet`)
                .then(response => {
                    if (response.data.data) {
                        this.dtc = response.data.data.filter((a) => a.role.role_slug === "driver" || a.role.role_slug === "tour-guide" || a.role.role_slug === 'contractor');
                        this.dtc.forEach(a => {
                            a.full_name = a.first_name + ' ' + a.last_name + ' (' + a.role.role_name + ')';
                        });
                    } else {
                        throw new Error('Failed to fetch user');
                    }
                    this.dtcLoading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.dtcLoading = false;
                    this.dtc = [];
                });
        },
        debouncedGetDTC: debounce(function (value) {
            this.dtcLoading = true;
            this.getDTC(value);
        }, 500),
        formatPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.categories.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.categories = updatedCategories;
        },
        // Example of replacing the entire array to ensure reactivity
        autoFillPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.categories.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.categories = updatedCategories;
            }

            this.formatPrices()
        },
        // Per Head Prices
        formatPerHeadPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.perHeadCategory.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.perHeadCategory = updatedCategories;
        },
        autoFillPerHeadPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.perHeadCategory.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.perHeadCategory = updatedCategories;
            }

            this.formatPerHeadPrices()
        },
        // Per Hotel Prices
        formatPerHotelPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.perHotelCategory.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.perHotelCategory = updatedCategories;
        },
        autoFillPerHotelPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.perHotelCategory.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.perHotelCategory = updatedCategories;
            }

            this.formatPerHotelPrices()
        },
        submitRequest() {
            // Check if all inputs are valid
            if (this.id === '') {
                toast.error('Please select a user');
                return;
            }

            const privateFilled = this.categories.every(category => !isNaN(category.price) && category.price !== null);

            if (!privateFilled) {
                toast.error('Please ensure all private prices are filled and valid.');
                return;
            }

            const selectedSICCategories = this.sicType === 'perHead' ? this.perHeadCategory : this.perHotelCategory;

            const sicFilled = selectedSICCategories.every(category => !isNaN(category.price) && category.price !== null);

            if (!sicFilled) {
                toast.error('Please ensure all SIC prices are filled and valid.');
                return;
            }

            // Select the appropriate SIC categories based on sicType

            // Combine categories and selected SIC categories
            const allCategories = [
                ...this.categories.map(category => ({
                    slug: category.slug,
                    price: parseFloat(category.price), // Ensure price is a number
                    sic: false
                })),
                ...selectedSICCategories.map(category => ({
                    slug: category.slug,
                    price: parseFloat(category.price), // Ensure price is a number
                    sic: true
                }))
            ];

            // Construct the request payload
            let dataToSend = {
                user_id: this.id, // Assuming id is the user_id selected from the autocomplete
                request: allCategories
            };

            let id = toast.loading('Please wait...');
            this.isLoading = true;
            httpPost('/api/v1/travel_agents/dtcs/add', dataToSend, id)
                .then((response) => {
                    toast.update(id, {
                        render: response.data.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    });
                    this.isLoading = false;
                    indexedDBService.getDtcsData();
                    this.$router.push('/dtc/' + response.data.data.uuid);
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        }
    },
};
</script>

<style src="@/assets/css/job.css" scoped></style>
