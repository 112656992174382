<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Locations</h1>
                <p>Dashboard > Maps > Locations</p>
            </div>

            <div class="split-layout">
                <div class="map-container">
                    <v-card class="map-wrapper">
                        <GMapMap :center="center" :zoom="currentZoom" map-type-id="terrain" :options="mapOptions"
                            ref="mapRef" @click="onMapClick">
                            <GMapMarker v-for="(driver, index) in drivers" :key="'driver-' + index"
                                :icon="{ url: driver.icon }" :position="driver.position"
                                @click="toggleDriverInfo(driver, index)"
                                :class="{ 'selected-marker': selectedDriver === driver }">
                                <GMapInfoWindow :opened="driver.infoWindowOpen" :options="infoWindowOptions">
                                    <!-- Info Window Content -->
                                    <div class="info-window-container">
                                        <div class="driver-header">
                                            <div class="driver-avatar">
                                                <v-avatar size="60">
                                                    <v-img :src="driver.imagePath" :alt="driver.name" />
                                                </v-avatar>
                                            </div>
                                            <div class="driver-info">
                                                <h3 class="driver-name">
                                                    <span class="mr-3">
                                                        {{ driver.name }}
                                                    </span>
                                                    <Pulse :color="getDriverStatusColor(driver.status)">
                                                    </Pulse>
                                                </h3>
                                                <div class="vehicle-info">
                                                    <v-icon size="18">mdi-car</v-icon>
                                                    <span>{{ driver.vehicle }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <v-divider class="my-3"></v-divider>

                                        <div class="current-trip">
                                            <h4 class="section-title">
                                                <v-icon color="primary">mdi-map-marker-path</v-icon>
                                                Current Trip Details
                                            </h4>
                                            <div class="tourists-list">
                                                <div v-for="(tourist, index) in driver.tourists" :key="index"
                                                    class="tourist-card">
                                                    <div class="tourist-header">
                                                        <div class="tourist-name">
                                                            <v-icon size="20" color="primary">mdi-account</v-icon>
                                                            <span>{{ tourist.name }}</span>
                                                        </div>
                                                        <v-chip size="small" :color="getStatusColor(tourist.status)">
                                                            {{ tourist.status }}
                                                        </v-chip>
                                                    </div>

                                                    <div class="tourist-details">
                                                        <div class="detail-item">
                                                            <v-icon size="18">mdi-map-marker</v-icon>
                                                            <span>{{ tourist.destination }}</span>
                                                        </div>
                                                        <div class="detail-item">
                                                            <v-icon size="18">mdi-clock-outline</v-icon>
                                                            <span>{{ tourist.pickupTime }}</span>
                                                        </div>
                                                        <div class="detail-item">
                                                            <v-icon size="18">mdi-account-group</v-icon>
                                                            <span>{{ tourist.adults }} Adults, {{ tourist.children }}
                                                                Children</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </GMapInfoWindow>
                            </GMapMarker>
                        </GMapMap>
                    </v-card>
                </div>

                <div class="info-container">
                    <v-card elevation="0">
                        <v-card-title class="d-flex justify-space-between align-center py-4 px-6">
                            <span class="text-h6">Your Drivers</span>
                            <v-chip color="primary" size="small">
                                {{ activeDrivers }} Active
                            </v-chip>
                        </v-card-title>

                        <v-card-text class="pa-0">
                            <v-list>
                                <v-list-item v-for="(driver, index) in drivers" :key="'driver-list-' + index"
                                    :class="{ 'selected-driver': selectedDriver === driver }"
                                    @click="toggleDriverInfo(driver, index)">
                                    <template v-slot:prepend>
                                        <v-avatar size="40">
                                            <v-img :src="driver.imagePath" :alt="driver.name"></v-img>
                                        </v-avatar>
                                    </template>

                                    <v-list-item-title class="font-weight-medium">
                                        <span class="mr-3">
                                            {{ driver.name }}
                                        </span>
                                        <Pulse :color="getDriverStatusColor(driver.status)">
                                        </Pulse>
                                    </v-list-item-title>

                                    <v-list-item-subtitle class="d-flex align-center mt-1">
                                        <v-icon size="16" class="mr-1">mdi-car</v-icon>
                                        {{ driver.vehicle }}
                                    </v-list-item-subtitle>

                                    <template v-slot:append>
                                        <div class="driver-stats">
                                            <v-chip size="small" v-if="driver.yourDriver" color="primary" class="ml-2">
                                                Your Driver
                                            </v-chip>
                                            <div class="tourist-count mt-2">
                                                <v-icon size="16">mdi-account-group</v-icon>
                                                <span>{{ driver.tourists.length }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import map from '@/assets/styles/map'
import TopNav from '@/components/TopNav.vue'
import Pulse from '@/components/Pulse.vue'

export default {
    name: 'MapView',
    components: {
        TopNav,
        Pulse
    },

    data() {
        return {
            imageCache: new Map(), // Memory cache for images
            mapStyles: JSON.parse(map.customMapStyles),
            center: { lat: 1.290270, lng: 103.851959 },
            currentZoom: 10,
            selectedDriver: null,
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                styles: JSON.parse(map.customMapStyles)
            },
            infoWindowOptions: {
                pixelOffset: { width: 0, height: -35 },
                hideCloseButton: true,
            },
            drivers: [
                {
                    name: 'John Doe',
                    position: { lat: 1.290270, lng: 103.851959 },
                    vehicle: 'Toyota Prius',
                    status: 'Inactive',
                    yourDriver: true,
                    infoWindowOpen: false,
                    imagePath: 'https://picsum.photos/200',
                    lastUpdated: '2023-09-09 14:30',
                    tourists: [
                        {
                            name: 'Alice Smith',
                            destination: 'Marina Bay Sands',
                            status: 'Picked Up',
                            adults: 2,
                            children: 1,
                            pickupTime: '15:00'
                        },
                        {
                            name: 'Bob Johnson',
                            destination: 'Sentosa Island',
                            status: 'Waiting',
                            adults: 1,
                            children: 0,
                            pickupTime: '16:30'
                        }
                    ]
                },
                {
                    name: 'Jane Smith',
                    position: { lat: 1.299270, lng: 103.856959 },
                    vehicle: 'Honda Civic',
                    status: 'Active',
                    yourDriver: false,
                    infoWindowOpen: false,
                    imagePath: 'https://picsum.photos/200',
                    lastUpdated: '2023-09-09 14:35',
                    tourists: [
                        {
                            name: 'Charlie Brown',
                            destination: 'Gardens by the Bay',
                            status: 'Waiting',
                            adults: 3,
                            children: 2,
                            pickupTime: '16:00'
                        }
                    ]
                }
            ],
            // Base64 encoded placeholder avatar for fallback
            placeholderAvatar: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI2NjYyIgZD0iTTEyLDRBNCw0IDAgMCwxIDE2LDhBNCw0IDAgMCwxIDEyLDEyQTQsNCAwIDAsMSA4LDhBNCw0IDAgMCwxIDEyLDRNMTIsMTRDMTYuNDIsMTQgMjAsMTUuNzkgMjAsMThWMjBINFYxOEM0LDE1Ljc5IDcuNTgsMTQgMTIsMTRaIiAvPjwvc3ZnPg==`
        }
    },

    computed: {
        activeDrivers() {
            return this.drivers.filter(driver => driver.status === 'Active').length
        }
    },

    async mounted() {
        await this.loadAllMarkers();
    },

    beforeUnmount() {
        // Clear memory cache when component is destroyed
        this.imageCache.clear();
    },

    methods: {
        async loadAllMarkers() {
            await Promise.all(this.drivers.map(async driver => {
                driver.icon = await this.generateMarkerIcon(driver);
            }));
        },

        async loadImageAsBase64(url) {
            // Check memory cache first
            if (this.imageCache.has(url)) {
                return this.imageCache.get(url);
            }

            // Check browser cache using Cache API
            try {
                const cache = await caches.open('driver-images');
                let response = await cache.match(url);

                if (!response) {
                    // If not in cache, fetch and store
                    response = await fetch(url);
                    await cache.put(url, response.clone());
                }

                const blob = await response.blob();
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        // Store in memory cache
                        this.imageCache.set(url, reader.result);
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                });
            } catch (error) {
                console.error('Error loading image:', error);
                return this.placeholderAvatar;
            }
        },

        async generateMarkerIcon(driver) {
            const selected = this.selectedDriver === driver;
            const isActive = driver.status.toLowerCase() === 'active';
            const statusColor = isActive ? '#4CAF50' : '#FFA726';

            // Get image from cache or load it
            let base64Image;
            try {
                base64Image = await this.loadImageAsBase64(driver.imagePath);
            } catch (error) {
                console.error('Error generating marker:', error);
                base64Image = this.placeholderAvatar;
            }

            // Create a unique ID for each driver's pattern
            const patternId = `profileImage-${driver.name.replace(/\s+/g, '')}-${Date.now()}`;

            const svg = `
                <svg xmlns="http://www.w3.org/2000/svg" 
                     width="${selected ? '69' : '49'}" 
                     height="${selected ? '89' : '69'}"
                     viewBox="0 0 69 89">
                    <defs>
                        <pattern id="${patternId}" 
                            patternUnits="userSpaceOnUse" 
                            width="38" 
                            height="38"
                            patternTransform="translate(5.5, 5.5)">
                            <image href="${base64Image}" 
                                   width="38" 
                                   height="38"
                                   preserveAspectRatio="xMidYMid slice">
                            </image>
                        </pattern>
                    </defs>
                    <g transform="translate(10, 10)">
                        ${selected ? `
                        <path
                            d="M0,24.151A24.311,24.311,0,0,1,24.5,0,24.309,24.309,0,0,1,49,24.151C49,42.264,24.5,69,24.5,69S0,42.264,0,24.151Z"
                            fill="rgba(0,0,0,0.2)"
                            transform="translate(2, 2)"
                        />` : ''}
                        <path
                            d="M0,24.151A24.311,24.311,0,0,1,24.5,0,24.309,24.309,0,0,1,49,24.151C49,42.264,24.5,69,24.5,69S0,42.264,0,24.151Z"
                            fill="#03256c"
                        />
                        <circle cx="24.5" cy="24.5" r="20.5" fill="#fff" />
                        
                        <!-- Profile image using pattern -->
                        <circle 
                            cx="24.5" 
                            cy="24.5" 
                            r="19" 
                            fill="url(#${patternId})"
                        />
                        
                        <!-- Status indicator -->
                        <circle 
                            cx="38" 
                            cy="38" 
                            r="6" 
                            fill="${statusColor}"
                            stroke="#fff"
                            stroke-width="2"
                        />
                    </g>
                </svg>`;

            return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
        },
        async toggleDriverInfo(driver, index) {
            // Store previous driver for animation
            const previousDriver = this.selectedDriver;

            // If clicking the same driver that's already selected
            if (previousDriver === driver) {
                this.closeDriverInfo(driver);
                return;
            }

            // Close previous driver's info window
            if (previousDriver) {
                previousDriver.infoWindowOpen = false;
            }

            this.selectedDriver = driver;
            await this.loadAllMarkers();

            // Smooth map transition
            if (this.$refs.mapRef) {
                const map = this.$refs.mapRef.$mapObject;

                // Calculate the midpoint if there's a previous position
                if (previousDriver) {
                    await this.smoothTransition(
                        map,
                        previousDriver.position,
                        driver.position,
                        previousDriver.infoWindowOpen
                    );
                } else {
                    // If no previous driver, animate to new position with zoom
                    await this.smoothTransition(
                        map,
                        map.getCenter(), // Use current map center as starting point
                        driver.position,
                        false
                    );
                }

                driver.infoWindowOpen = true;
            }
        },

        async smoothTransition(map, startPos, endPos, wasInfoWindowOpen) {
            const startZoom = map.getZoom();
            const TRANSITION_ZOOM = 13;
            const FINAL_ZOOM = 15;

            // If info window was open, smoothly close it first
            if (wasInfoWindowOpen) {
                await new Promise(resolve => setTimeout(resolve, 150));
            }

            // Step 1: Slightly zoom out if we're very zoomed in
            if (startZoom > TRANSITION_ZOOM) {
                await this.smoothZoom(map, TRANSITION_ZOOM, true);
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            // Step 2: Smooth pan to new location
            await this.animateToPosition(map, endPos);

            // Step 3: Zoom in to final zoom level
            await this.smoothZoom(map, FINAL_ZOOM, false);
        },

        async animateToPosition(map, endPos) {
            const startPos = map.getCenter();
            const frames = 60;
            const duration = 1000; // 1 second animation

            // Calculate the direct path between points
            const deltaLat = endPos.lat - startPos.lat();
            const deltaLng = endPos.lng - startPos.lng();

            // Create a slight curve by adding a midpoint
            const midPoint = {
                lat: startPos.lat() + deltaLat * 0.5,
                lng: startPos.lng() + deltaLng * 0.5
            };

            // Add a slight offset to the midpoint for a subtle curve
            const perpAngle = Math.atan2(deltaLat, deltaLng) + Math.PI / 2;
            const curveOffset = 0.0001; // Adjust this value to control curve intensity
            midPoint.lat += Math.sin(perpAngle) * curveOffset;
            midPoint.lng += Math.cos(perpAngle) * curveOffset;

            return new Promise(resolve => {
                let frame = 0;

                const animate = () => {
                    frame++;
                    const progress = frame / frames;

                    // Smooth easing function
                    const easing = progress < 0.5
                        ? 4 * progress * progress * progress
                        : 1 - Math.pow(-2 * progress + 2, 3) / 2;

                    // Quadratic Bezier curve calculation
                    const oneMinusT = 1 - easing;
                    const lat = Math.pow(oneMinusT, 2) * startPos.lat() +
                        2 * oneMinusT * easing * midPoint.lat +
                        Math.pow(easing, 2) * endPos.lat;

                    const lng = Math.pow(oneMinusT, 2) * startPos.lng() +
                        2 * oneMinusT * easing * midPoint.lng +
                        Math.pow(easing, 2) * endPos.lng;

                    map.panTo({ lat, lng });

                    if (frame < frames) {
                        requestAnimationFrame(animate);
                    } else {
                        resolve();
                    }
                };

                animate();
            });
        },

        async smoothZoom(map, targetZoom, out) {
            const startZoom = map.getZoom();
            const frames = 30;

            return new Promise(resolve => {
                let frame = 0;

                const animate = () => {
                    frame++;
                    const progress = frame / frames;

                    // Use easeInOutQuad for smooth zoom
                    const easing = progress < 0.5
                        ? 2 * progress * progress
                        : 1 - Math.pow(-2 * progress + 2, 2) / 2;

                    const currentZoom = startZoom + (targetZoom - startZoom) * easing;
                    map.setZoom(currentZoom);

                    if (frame < frames) {
                        requestAnimationFrame(animate);
                    } else {
                        resolve();
                    }
                };

                animate();
            });
        },

        async closeDriverInfo(driver) {
            this.selectedDriver = null;
            await this.loadAllMarkers();

            // Smoothly close info window first
            driver.infoWindowOpen = false;
            await new Promise(resolve => setTimeout(resolve, 150));

            // Smooth zoom out
            if (this.$refs.mapRef) {
                const map = this.$refs.mapRef.$mapObject;
                await this.smoothZoom(map, this.currentZoom, true);

                // Smooth pan to default center
                await this.animateToPosition(map, {
                    lat: 1.290270,
                    lng: 103.851959
                });
            }
        },

        centerMapOn(position) {
            if (this.$refs.mapRef && this.$refs.mapRef.$mapObject) {
                this.animateToPosition(this.$refs.mapRef.$mapObject, position);
            } else {
                this.center = position;
            }
        },

        onMapClick(event) {
            if (this.selectedDriver) {
                this.closeDriverInfo(this.selectedDriver)
            }
        },

        getStatusColor(status) {
            switch (status.toLowerCase()) {
                case 'picked up':
                    return 'success'
                case 'waiting':
                    return 'warning'
                case 'completed':
                    return 'info'
                default:
                    return 'grey'
            }
        },

        getDriverStatusColor(status) {
            return status.toLowerCase() === 'active' ? 'success' : 'warning'
        }
    }
}
</script>

<style scoped>
/* Add these to your <style> section */
.v-card {
    border-radius: var(--border-radius);
    transition: all 0.3s ease-in-out;
    background-color: var(--secondary-light-grey);
}

.selected-driver {
    background-color: var(--secondary-dark-grey) !important;
    transition: background-color 0.3s ease-in-out;
}

/* Add animation for marker selection */
.selected-marker {
    animation: bounce 0.5s ease-in-out;
}

.gm-style-iw button[title="Close"] {
    display: none !important;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.split-layout {
    display: flex;
    gap: 24px;
    height: 500px;
}

.map-container,
.info-container {
    flex: 1;
    border: 1px solid var(--secondary-dark-grey);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.map-container .v-card {
    margin: auto;
}

.map-wrapper {
    aspect-ratio: 1/1;
    border-radius: var(--border-radius);
    overflow: hidden;
}

.driver-stats {
    text-align: right;
}

.tourist-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    color: var(--v-text-secondary);
}

.info-window-container {
    padding: 16px;
    min-width: 300px;
    max-width: 400px;
    background: white;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out;
}

.driver-header {
    display: flex;
    align-items: center;
    gap: 16px;
}

.driver-info {
    flex: 1;
}

.driver-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 4px 0;
    color: rgb(var(--v-theme-primary));
}

.driver-name span {
    vertical-align: middle;
}

.vehicle-info {
    display: flex;
    align-items: center;
    gap: 6px;
    color: var(--v-text-secondary);
    font-size: 0.9rem;
}

.status-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 6px;
}

.section-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    color: rgb(var(--v-theme-primary));
    margin-bottom: 16px;
}

.tourists-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tourist-card {
    background-color: var(--secondary-light-grey);
    border-radius: 8px;
    padding: 12px;
}

.tourist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.tourist-name {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 500;
}

.tourist-details {
    display: flex;
    flex-direction: column;
    gap: 6px
}

.tourist-details .detail-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    color: var(--v-text-secondary);
}

.driver-stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 8px;
}

.stat-item {
    text-align: center;
    padding: 12px;
    background-color: var(--secondary-light-grey);
    border-radius: 8px;
}

.stat-label {
    font-size: 0.875rem;
    color: var(--v-text-secondary);
    margin-bottom: 4px;
}

.stat-value {
    font-size: 1.125rem;
    font-weight: 600;
    color: rgb(var(--v-theme-primary));
}

.timeline-content {
    padding: 8px;
    background-color: var(--secondary-light-grey);
    border-radius: 6px;
    margin-left: 8px;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .split-layout {
        height: unset;
        flex-direction: column;
    }

    .map-wrapper {
        height: unset;
        max-height: 500px;
    }
}

@media (max-width: 768px) {
    .driver-stats-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .info-window-container {
        min-width: 250px;
    }
}

@media (max-width: 480px) {
    .driver-stats-grid {
        grid-template-columns: 1fr;
    }

    .split-layout {
        padding: 12px;
    }
}
</style>