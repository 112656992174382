import db from '@/databases/indexedDB';
import { httpGet } from '@/services/http'; // Adjust the import based on your project structure

async function getTransferTypesData() {
    try {
        // Retrieve the most recent update timestamp from the 'requests' store
        const mostRecentUpdate = await db.transfer_types.orderBy('updated_at').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updated_at : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/transfers/types${queryParams}`);

        console.log('Max. requests: ' + response.data.data.length)

        if (response && response.data && response.data.data) {
            const transfers = response.data.data.map(item => {
                const { location, ...transfer } = item;
                return { ...transfer, location_uuid: location ? location.uuid : null };
            });

            const locations = response.data.data
                .filter(item => item.location) // Only include items with non-null locations
                .map(item => {
                    const { detail, ...location } = item.location;
                    return { ...location, detail_uuid: detail ? detail.uuid : null };
                });

            const locationDetails = response.data.data
                .filter(item => item.location && item.location.detail) // Only include items with non-null location details
                .map(item => item.location.detail);

            // Use a transaction to insert or update records
            await db.transaction('rw', [db.transfer_types, db.locations, db.location_details], async () => {
                await db.transfer_types.bulkPut(transfers);
                await db.locations.bulkPut(locations);
                await db.location_details.bulkPut(locationDetails);
            });
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get requests data:', error);
    }
}

async function getDtcsData() {
    try {
        // Retrieve the most recent update timestamp from the 'dtc_requests' store
        const mostRecentUpdate = await db.dtc_requests.orderBy('updated_at').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updated_at : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/dtcs${queryParams}`);

        console.log('Max. dtc_requests: ' + response.data.data.length)

        if (response && response.data && response.data.data) {
            const dtcRequests = response.data.data.map(item => {
                const { requester, user, details, ...dtcRequest } = item;
                return { ...dtcRequest, requester_uuid: requester ? requester.uuid : null, user_uuid: user ? user.uuid : null };
            });

            const users = response.data.data
                .flatMap(item => [item.requester, item.user])
                .filter(user => user) // Only include non-null users
                .map(user => {
                    return user;
                });

            const dtcRequestDetails = response.data.data
                .flatMap(item => item.details.map(detail => {
                    const { transfer_type_special, ...rest } = detail;
                    return {
                        ...rest,
                        transfer_type_special_uuid: transfer_type_special.uuid,
                        dtc_request_uuid: item.uuid
                    };
                }));

            // Use a transaction to insert or update records
            await db.transaction('rw', [db.dtc_requests, db.users, db.dtc_request_details], async () => {
                await db.dtc_requests.bulkPut(dtcRequests);
                await db.users.bulkPut(users);
                await db.dtc_request_details.bulkPut(dtcRequestDetails);
            });
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get DTC data:', error);
    }
}

async function getJobsData() {
    try {
        // Retrieve the most recent update timestamp from the 'jobs' store
        const mostRecentUpdate = await db.jobs.orderBy('updated_at').last();

        // Prepare the query parameter
        const updatedAt = mostRecentUpdate ? mostRecentUpdate.updated_at : null;
        const queryParams = updatedAt ? `?updated_at=${updatedAt}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/jobs${queryParams}`);

        console.log('Max. jobs: ' + response.data.data.length);

        if (response && response.data && response.data.data) {
            // Map server response to local IndexedDB schema
            const jobs = response.data.data.map(item => {
                const { job_status, user, tourist, stays, job_transfers, ...job } = item;
                return {
                    uuid: item.uuid,
                    ...job,
                    job_status: job_status ? job_status.uuid : null,
                    user_uuid: user ? user.uuid : null,
                    tourist_uuid: tourist ? tourist.uuid : null
                };
            });

            const users = response.data.data
                .flatMap(item => [item.user, item.tourist])
                .filter(user => user) // Only include non-null users
                .map(user => ({
                    uuid: user.uuid,
                    ...user
                }));

            const stays = response.data.data
                .flatMap(item => item.stays.map(stay_detail => {
                    const { stay, ...rest } = stay_detail;
                    return {
                        uuid: stay_detail.uuid,
                        ...rest,
                        stay_uuid: stay.uuid,
                        job_uuid: item.uuid
                    };
                }));

            // Extract job_transfers
            const jobTransfers = response.data.data
                .flatMap(item => item.job_transfers.map(job_transfer => ({
                    uuid: job_transfer.uuid,
                    job_uuid: job_transfer.job.uuid,
                    transfer_uuid: job_transfer.transfer.uuid,
                    children: job_transfer.children,
                    created_at: job_transfer.created_at,
                    updated_at: job_transfer.updated_at,
                    deleted_at: job_transfer.deleted_at
                })));

            // Modified to extract transfers from job_transfers
            const transfers = response.data.data
                .flatMap(item => item.job_transfers.map(job_transfer => {
                    const { transfer_type, transfer_type_special, from, to, ...rest } = job_transfer.transfer;
                    return {
                        uuid: job_transfer.transfer.uuid,
                        ...rest,
                        transfer_type_name: transfer_type.type_name,
                        transfer_type_slug: transfer_type.type_slug,
                        transfer_type_special_uuid: transfer_type_special.uuid,
                        from_uuid: from.uuid,
                        to_uuid: to.uuid
                    };
                }));

            const locations = response.data.data
                .flatMap(item => [
                    // Get locations from job_transfers
                    ...item.job_transfers.flatMap(job_transfer => [
                        job_transfer.transfer.from,
                        job_transfer.transfer.to
                    ]),
                    // Get locations from stays
                    ...item.stays.map(stay_detail => stay_detail.stay)
                ])
                .map(location => {
                    const { detail, ...rest } = location;
                    return { ...rest, detail_uuid: detail ? detail.uuid : null };
                })
                .filter((location, index, self) =>
                    index === self.findIndex(l => l.uuid === location.uuid)
                );

            const locationDetails = response.data.data
                .flatMap(item => [
                    // Get locations from job_transfers
                    ...item.job_transfers.flatMap(job_transfer => [
                        job_transfer.transfer.from,
                        job_transfer.transfer.to
                    ]),
                    // Get locations from stays
                    ...item.stays.map(stay_detail => stay_detail.stay)
                ])
                .filter(location => location.detail)
                .map(location => location.detail)
                .filter((detail, index, self) =>
                    index === self.findIndex(d => d.uuid === detail.uuid)
                );

            // Use a transaction to insert or update records
            await db.transaction('rw', [
                db.jobs,
                db.users,
                db.stays,
                db.transfers,
                db.job_transfers,
                db.locations,
                db.location_details
            ], async () => {
                await db.jobs.bulkPut(jobs);
                await db.users.bulkPut(users);
                await db.stays.bulkPut(stays);
                await db.transfers.bulkPut(transfers);
                await db.job_transfers.bulkPut(jobTransfers);
                await db.locations.bulkPut(locations);
                await db.location_details.bulkPut(locationDetails);
            });

            console.log('Data successfully updated in IndexedDB');
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get and store data:', error);
    }
}

async function getLastNotificationUpdateTime() {
    try {
        // Retrieve the most recent update timestamp from the 'notifications' store
        const mostRecentUpdate = await db.notifications.orderBy('updated_at').last();

        // Return the updated_at timestamp or null if no notifications exist
        return mostRecentUpdate ? mostRecentUpdate.updated_at : null;
    } catch (error) {
        console.error('Failed to get last notification update:', error);
        return null;
    }
}

async function insertNotifications(response) {
    try {
        if (response && response.data && response.data.data) {
            // Keep all data without separating
            const notifications = response.data.data.map(item => {
                return {
                    ...item  // This keeps the complete structure including nested notification object
                };
            });

            // Use a transaction to insert records
            await db.transaction('rw', [db.notifications], async () => {
                // Insert notifications
                if (notifications.length > 0) {
                    await db.notifications.bulkPut(notifications);
                    console.log(`Inserted ${notifications.length} notifications`);
                }
            });

            console.log('Notifications data successfully stored in IndexedDB');
        } else {
            console.warn('No notifications data to insert');
        }
    } catch (error) {
        console.error('Failed to insert notifications:', error);
        throw error;
    }
}

async function getJobPriorityTypesData() {
    try {
        // Retrieve the most recent update timestamp
        const mostRecentUpdate = await db.job_priority_types.orderBy('updated_at').last();
        const queryParams = mostRecentUpdate ? `?updated_at=${mostRecentUpdate.updated_at}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/users/jobs/priorities/types${queryParams}`);

        console.log('Max. job_priority_types: ' + response.data.data.length);

        if (response?.data?.data) {
            const types = [];

            response.data.data.forEach(type => {
                types.push({
                    uuid: type.uuid,
                    type_name: type.type_name,
                    type_slug: type.type_slug,
                    type_desc: type.type_desc,
                    created_at: type.created_at,
                    updated_at: type.updated_at,
                    deleted_at: type.deleted_at
                });
            });


            // Use a transaction to insert or update all records
            await db.transaction('rw',
                [db.job_priority_types],
                async () => {
                    await db.job_priority_types.bulkPut(types);
                }
            );
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get job priority types data:', error);
    }
}

async function getJobPings() {
    try {
        // Retrieve the most recent update timestamp
        const mostRecentUpdate = await db.job_pings.orderBy('updated_at').last();
        const queryParams = mostRecentUpdate ? `?updated_at=${mostRecentUpdate.updated_at}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/users/jobs/priorities/pings/types${queryParams}`);

        console.log('Max. job_pings: ' + response.data.data.length);

        if (response?.data?.data) {
            const pings = [];

            response.data.data.forEach(ping => {
                pings.push({
                    uuid: ping.uuid,
                    ping_name: ping.ping_name,
                    ping_slug: ping.ping_slug,
                    ping_desc: ping.ping_desc,
                    created_at: ping.created_at,
                    updated_at: ping.updated_at,
                    deleted_at: ping.deleted_at
                });
            });


            // Use a transaction to insert or update all records
            await db.transaction('rw',
                [db.job_pings],
                async () => {
                    await db.job_pings.bulkPut(pings);
                }
            );
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get job pings data:', error);
    }
}

async function getJobPaymentTypes() {
    try {
        // Retrieve the most recent update timestamp
        const mostRecentUpdate = await db.job_payment_types.orderBy('updated_at').last();
        const queryParams = mostRecentUpdate ? `?updated_at=${mostRecentUpdate.updated_at}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/users/jobs/priorities/payments/types${queryParams}`);

        console.log('Max. job_payment_types: ' + response.data.data.length);

        if (response?.data?.data) {
            const types = [];

            response.data.data.forEach(type => {
                types.push({
                    uuid: type.uuid,
                    type_name: type.type_name,
                    type_slug: type.type_slug,
                    created_at: type.created_at,
                    updated_at: type.updated_at,
                    deleted_at: type.deleted_at
                });
            });


            // Use a transaction to insert or update all records
            await db.transaction('rw',
                [db.job_payment_types],
                async () => {
                    await db.job_payment_types.bulkPut(types);
                }
            );
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get job priority types data:', error);
    }
}

async function getVehicleTypes() {
    try {
        // Retrieve the most recent update timestamp
        const mostRecentUpdate = await db.vehicle_types.orderBy('updated_at').last();
        const queryParams = mostRecentUpdate ? `?updated_at=${mostRecentUpdate.updated_at}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/users/jobs/vehicles/types${queryParams}`);

        console.log('Max. vehicle_types: ' + response.data.data.length);

        if (response?.data?.data) {
            const types = [];

            response.data.data.forEach(type => {
                types.push({
                    uuid: type.uuid,
                    type_name: type.type_name,
                    type_slug: type.type_slug,
                    created_at: type.created_at,
                    updated_at: type.updated_at,
                    deleted_at: type.deleted_at
                });
            });


            // Use a transaction to insert or update all records
            await db.transaction('rw',
                [db.vehicle_types],
                async () => {
                    await db.vehicle_types.bulkPut(types);
                }
            );
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get vehicle types data:', error);
    }
}

// Modified code to handle job_transfer structure
async function getAssignedJobRequestsData() {
    try {
        // Retrieve the most recent update timestamp
        const mostRecentUpdate = await db.assigned_job_requests.orderBy('updated_at').last();
        const queryParams = mostRecentUpdate ? `?updated_at=${mostRecentUpdate.updated_at}` : '';

        // Fetch updated data from the server
        const response = await httpGet(`/api/v1/travel_agents/jobs/assigns${queryParams}`);

        console.log('Max. assigned_job_requests: ' + response.data.data.length);

        if (response?.data?.data) {
            // Process assigned job requests
            const assignedJobRequests = response.data.data.map(item => {
                const { requester, user, details, transfer_type_special_request, ...assignedJobRequest } = item;
                return {
                    ...assignedJobRequest,
                    requester_uuid: requester?.uuid,
                    user_uuid: user?.uuid
                };
            });

            // Process users
            const users = response.data.data
                .flatMap(item => [item.requester, item.user])
                .filter(Boolean)
                .map(user => user);

            // Process job transfers and transfers separately
            const jobTransfers = [];
            const transfers = [];
            const assignedJobRequestDetails = [];

            response.data.data.forEach(request => {
                request.details.forEach(detail => {
                    // Add assigned job request detail
                    assignedJobRequestDetails.push({
                        uuid: detail.uuid,
                        assigned_job_request_uuid: request.uuid,
                        job_transfer_uuid: detail.job_transfer.uuid,
                        created_at: detail.created_at,
                        updated_at: detail.updated_at,
                        deleted_at: detail.deleted_at
                    });

                    // Add job transfer
                    jobTransfers.push({
                        uuid: detail.job_transfer.uuid,
                        created_at: detail.job_transfer.created_at,
                        updated_at: detail.job_transfer.updated_at,
                        deleted_at: detail.job_transfer.deleted_at,
                        job_uuid: detail.job_transfer.job.uuid,
                        transfer_uuid: detail.job_transfer.transfer.uuid,
                        children: detail.job_transfer.children
                    });

                    // Add transfer
                    const transfer = detail.job_transfer.transfer;
                    transfers.push({
                        uuid: transfer.uuid,
                        pick_up_time: transfer.pick_up_time,
                        remarks: transfer.remarks,
                        created_at: transfer.created_at,
                        updated_at: transfer.updated_at,
                        deleted_at: transfer.deleted_at,
                        transfer_type_name: transfer.transfer_type.type_name,
                        transfer_type_slug: transfer.transfer_type.type_slug,
                        transfer_type_special_uuid: transfer.transfer_type_special.uuid,
                        from_uuid: transfer.from.uuid,
                        to_uuid: transfer.to.uuid,
                        attendances: transfer.attendances,
                        tickets: transfer.tickets
                    });
                });
            });

            // Use a transaction to insert or update all records
            await db.transaction('rw',
                [db.assigned_job_requests, db.users, db.assigned_job_request_details,
                db.job_transfers, db.transfers],
                async () => {
                    await db.assigned_job_requests.bulkPut(assignedJobRequests);
                    await db.users.bulkPut(users);
                    await db.assigned_job_request_details.bulkPut(assignedJobRequestDetails);
                    await db.job_transfers.bulkPut(jobTransfers);
                    await db.transfers.bulkPut(transfers);
                }
            );
        } else {
            console.warn('No data received from the server');
        }
    } catch (error) {
        console.error('Failed to get assigned job requests data:', error);
    }
}


const deleteDatabase = async () => {
    try {
        await new Promise((resolve, reject) => {
            const request = indexedDB.deleteDatabase('rectrix-link-travel-agent');

            request.onsuccess = () => {
                console.log('Database deleted successfully');
                resolve();
            };

            request.onerror = () => {
                console.error('Error deleting database');
                reject(new Error('Failed to delete database'));
            };

            request.onblocked = () => {
                console.warn('Delete operation blocked for database');
                reject(new Error('Database deletion blocked'));
            };
        });
    } catch (error) {
        console.error('Database deletion failed:', error);
        throw error;
    }
};


export const indexedDBService = {
    getTransferTypesData,
    getDtcsData,
    getJobsData,
    getAssignedJobRequestsData,
    // Get Priority Details
    getJobPriorityTypesData,
    getJobPings,
    getJobPaymentTypes,
    getVehicleTypes,
    getLastNotificationUpdateTime,
    insertNotifications,
    deleteDatabase
};