<template>
  <v-dialog v-model="addItineraryShow" persistent max-width="600px">
    <v-card>
      <v-card-text>
        <v-card-title class="d-flex justify-space-between align-center">
          <span class="headline">Add Itinerary</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addItineraryShow = !addItineraryShow">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-form ref="addItineraryForm">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select :items="types" item-title="type" item-value="slug" label="Type" v-model="newItinerary.type"
                  @update:modelValue="(value) => onCategoryChange(newItinerary.category, false)"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select :items="categories" item-title="type" item-value="slug" label="Category"
                  v-model="newItinerary.category"
                  @update:modelValue="(value) => onCategoryChange(value, false)"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field type="date" label="Date" v-model="newItinerary.date" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field type="time" label="Time" v-model="newItinerary.pickUpTime"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete :items="newItinerary.pickUpLocationSuggestions" item-title="main_text" item-value="uuid"
                  label="Pick Up Location" :rules="itineraryLocationValidation.pickUpLocationRules"
                  v-model="newItinerary.pickUpLocation" @keyup="
                    debouncedFetchLocationSuggestions(
                      $event.target.value,
                      'pick_up',
                      false
                    )
                    " :loading="locationLoading.pick_up ? true : false"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete :items="newItinerary.destinationLocationSuggestions" item-title="main_text"
                  item-value="uuid" label="Drop Off Location"
                  :rules="itineraryLocationValidation.destinationLocationRules"
                  v-model="newItinerary.destinationLocation" @keyup="
                    debouncedFetchLocationSuggestions(
                      $event.target.value,
                      'destination',
                      false
                    )
                    " :loading="locationLoading.destination ? true : false"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea label="Remarks" v-model="newItinerary.remarks" rows="1"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field type="time" label="Return Time (Optional)" v-model="newItinerary.returnTime"
                  clearable></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card-actions>
          <v-container align="right">
            <v-btn color="white button-1 w-100" @click="addTransferDetail">Add Transfer</v-btn>
          </v-container>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="editTransferModal.show" persistent max-width="600px">
    <v-card>
      <v-card-text>
        <v-card-title class="d-flex justify-space-between align-center">
          <span class="headline">Edit Itinerary</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="editTransferModal.show = !editTransferModal.show">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-form ref="editItineraryForm">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select :items="types" item-title="type" item-value="slug" label="Type" v-model="editItinerary.type"
                  @update:modelValue="(value) => onCategoryChange(editItinerary.category, true)"></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select :items="categories" item-title="type" item-value="slug" label="Category"
                  v-model="editItinerary.category"
                  @update:modelValue="(value) => onCategoryChange(value, true)"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field type="date" label="Date" v-model="editItinerary.date" readonly></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field type="time" label="Time" v-model="editItinerary.pickUpTime"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete :items="editItinerary.pickUpLocationSuggestions" item-title="main_text"
                  item-value="uuid" label="Pick Up Location" :rules="itineraryLocationValidation.pickUpLocationRules"
                  v-model="editItinerary.pickUpLocation" @keyup="
                    debouncedFetchLocationSuggestions(
                      $event.target.value,
                      'pick_up',
                      true
                    )
                    " :loading="locationLoading.pick_up ? true : false"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete :items="editItinerary.destinationLocationSuggestions" item-title="main_text"
                  item-value="uuid" label="Drop Off Location"
                  :rules="itineraryLocationValidation.destinationLocationRules"
                  v-model="editItinerary.destinationLocation" @keyup="
                    debouncedFetchLocationSuggestions(
                      $event.target.value,
                      'destination',
                      true
                    )
                    " :loading="locationLoading.destination ? true : false"></v-autocomplete>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12">
                <v-file-input v-model="editItinerary.tickets" label="Select ticket(s)" multiple clearable chips
                  accept=".jpg,.jpeg,.png,.pdf" @change="(event) => addTickets(event, true)"></v-file-input>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <v-textarea label="Remarks" v-model="editItinerary.remarks" rows="1"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn variant="outlined" class="w-100" color="error" @click="deleteEditedTransfer">Delete</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn class="w-100" color="primary" @click="saveEditedTransfer">Save</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>

  <section id="content" class="home">
    <!-- <Loading /> -->
    <div class="main-content">
      <TopNav />
      <div class="title">
        <h1>Add Priority Job</h1>
        <p>Dashboard > Priority Job > Job</p>
      </div>

      <div class="guest-details">
        <div class="title">
          <h3>Guest Details</h3>
          <p>
            {{
              !isEmailValid()
                ? "Check guest's account status"
                : guestExistsData
                  ? "Guest already have an account"
                  : "Guest does not exist, fill in their details to create"
            }}
          </p>
        </div>
        <v-form ref="guestDetailsForm">
          <div class="name">
            <div class="first-name">
              <v-text-field v-model="guest.firstName" name="first-name" id="first-name" class="form-control"
                label="First Name" :rules="guestValidation.firstName"
                :append-inner-icon="guestExistsData ? 'mdi-lock' : 'mdi-plus'"
                :readonly="guestExistsData ? true : false"
                :disabled="isEmailValid() && !guestEmailAddressLoading ? false : true"></v-text-field>
            </div>
            <div class="last-name">
              <v-text-field v-model="guest.lastName" name="last-name" id="last-name" class="form-control"
                label="Last Name" :rules="guestValidation.lastName"
                :append-inner-icon="guestExistsData ? 'mdi-lock' : 'mdi-plus'"
                :readonly="guestExistsData ? true : false"
                :disabled="isEmailValid() && !guestEmailAddressLoading ? false : true"></v-text-field>
            </div>
          </div>
          <div class="contact-details">
            <div class="email-address">
              <v-text-field v-model="guest.emailAddress" name="email-address" id="email-address" class="form-control"
                label="Email Address" :rules="guestValidation.emailAddress"
                :append-inner-icon="guestExistsData ? 'mdi-check' : 'mdi-plus'"
                :loading="guestEmailAddressLoading ? true : false"
                @keyup="debouncedCheckGuestExists($event.target.value)"></v-text-field>
            </div>
            <div class="contact-number">
              <div class="form-control">
                <v-phone-input :key="phoneInputKey" v-model="guest.contactNumber"
                  :defaultCountry="defaultContactNumberCountry" :rules="guestValidation.contactNumber"
                  :append-inner-icon="guestExistsData ? 'mdi-lock' : 'mdi-plus'"
                  :readonly="guestExistsData ? true : false"
                  :disabled="isEmailValid() && !guestEmailAddressLoading ? false : true" />
              </div>
            </div>
          </div>
          <div class="guest-types">
            <p>Guest Types</p>
          </div>
          <div class="job-details">
            <div class="no-of-adults">
              <v-text-field v-model="guest.noOfAdults" name="no-of-adults" id="no-of-adults" class="form-control"
                label="No. of Adults" type="number" min="0" :rules="guestValidation.numberValidation"></v-text-field>
            </div>
            <div class="no-of-childs">
              <v-text-field v-model="guest.noOfChilds" name="no-of-childs" id="no-of-childs" class="form-control"
                label="No. of Childs" type="number" min="0" :rules="guestValidation.numberValidation"></v-text-field>
            </div>
            <div class="no-of-infants">
              <v-text-field v-model="guest.noOfInfants" name="no-of-infants" id="no-of-infants" class="form-control"
                label="No. of Infants" type="number" min="0" :rules="guestValidation.numberValidation"></v-text-field>
            </div>
          </div>
          <div class="guest-types">
            <p>Job Infomation</p>
          </div>
          <div class="date-price">
            <div class="date">
              <v-text-field v-model="guest.date" name="date" type="date" id="date" class="form-control" label="Date"
                :rules="guestValidation.dateValidation" @update:modelValue="generateDate()"></v-text-field>
            </div>
            <div class="price">
              <v-text-field v-model="job.price" name="price" id="no" class="form-control"
                label="Price for all transfers" :rules="guestValidation.priceValidation" @blur="formatPrice()"
                prefix="$"></v-text-field>
            </div>
          </div>
          <div class="job-details">
            <div class="type">
              <v-select :items="jobTypes" item-title="type_name" item-value="type_slug" label="Type"
                v-model="job.type"></v-select>
            </div>
            <div class="pings">
              <v-select :items="pings" item-title="ping_name" item-value="ping_slug" label="Ping"
                v-model="job.ping"></v-select>
            </div>
            <div class="payment-types">
              <v-select :items="paymentTypes" item-title="type_name" item-value="type_slug" label="Payment Type"
                v-model="job.paymentType"></v-select>
            </div>
            <div class="vehicle-types">
              <v-select :items="vehicleTypes" item-title="type_name" item-value="type_slug" label="Vehicle Type"
                v-model="job.vehicleType"></v-select>
            </div>
          </div>
        </v-form>
      </div>


      <div class="itinerary-details">
        <div class="title">
          <h3>Transfer Details</h3>
          <p>Fill in the details about your guest</p>
        </div>

        <div class="table-container" v-if="allStayDates.length == 0">
          <p>Add job date to continue</p>
        </div>

        <div class="dates" ref="dates">
          <div class="each-date" v-for="(date, index) in allStayDates">
            <div class="transfer-container">
              <div class="transfer-header">
                <h2>Day {{ index + 1 }} - {{ formatDateFull(date) }}</h2>
              </div>
              <div class="transfer-detail" v-for="(transfer, index) in getItinerariesForDate(date)">
                <div>
                  <div class="time">{{ transfer.pickUpTime }}</div>
                  <div class="transfer-type">
                    {{ transfer.type == "pte" ? "Private" : "SIC" }}
                  </div>
                  <div class="special-slug">({{ transfer.category }})</div>
                </div>
                <div class="line-indicator"></div>
                <div class="location-details">
                  <div class="location-info">
                    <div class="location">
                      <h3>
                        {{
                          transfer.pickUpLocationSuggestions.find(
                            (a) => a.uuid === transfer.pickUpLocation
                          ).main_text
                        }}
                      </h3>
                      <!-- Assuming you have a remarks field -->
                      <div>Remarks: {{ transfer.remarks || "N/A" }}</div>
                    </div>
                    <div class="location-path">
                      <div class="line"></div>
                      <div class="path-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <title>steering_wheel_line</title>
                          <g id="steering_wheel_line" fill="none" fill-rule="evenodd">
                            <path
                              d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
                            <path fill="#09244BFF"
                              d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2ZM8.313 14.781c-1.044-.835-2.46-1.158-4.108-.972a8.01 8.01 0 0 0 6.254 6.043c-.193-2.625-1.056-4.2-2.146-5.07Zm7.374 0c-1.09.872-1.953 2.446-2.146 5.07a8.01 8.01 0 0 0 6.253-6.042c-1.647-.186-3.063.137-4.107.972ZM12 10c-.95 0-1.732.37-2.306.778l-.498.347c-.524.355-1.126.72-1.801.96a6.435 6.435 0 0 1 2.167 1.134c1.124.9 1.953 2.187 2.438 3.859.485-1.672 1.314-2.96 2.438-3.859a6.434 6.434 0 0 1 2.167-1.133c-.675-.241-1.277-.606-1.801-.961l-.498-.347C13.732 10.37 12.95 10 12 10Zm0-6a8.003 8.003 0 0 0-7.862 6.513l-.043.248 2.21-.442c.582-.116 1.135-.423 1.753-.84l.477-.332C9.332 8.581 10.513 8 12 8c1.388 0 2.509.506 3.3 1.034l.642.445c.54.365 1.032.645 1.536.788l.217.052 2.21.442A8.002 8.002 0 0 0 12 4Z" />
                          </g>
                        </svg>
                      </div>
                      <div class="line"></div>
                    </div>
                    <div class="location">
                      <h3>
                        {{
                          transfer.destinationLocationSuggestions.find(
                            (a) => a.uuid === transfer.destinationLocation
                          ).main_text
                        }}
                      </h3>
                      <!-- Assuming you have tickets information -->
                      <div>Tickets: {{ transfer.tickets.length ?? 0 }}</div>
                    </div>
                  </div>
                </div>
                <v-btn icon @click="openEditTransfer(index)">
                  <v-icon color="black">mdi-pencil</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="add-itinerary-btn" @click="addItinerary(date)">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
                  <line id="Line_36" data-name="Line 36" x2="23" transform="translate(2 13.5)" fill="none" stroke="#fff"
                    stroke-linecap="round" stroke-width="4" />
                  <line id="Line_37" data-name="Line 37" x2="23" transform="translate(13.5 2) rotate(90)" fill="none"
                    stroke="#fff" stroke-linecap="round" stroke-width="4" />
                </svg>
              </span>
              Add itinerary for {{ formatDateFull(date) }}
            </div>
          </div>
        </div>

        <div class="submit-button buttons w-100 justify-end" v-if="itineraries.length != 0">
          <v-btn class="button-1" @click="submitItinerary()" :loading="isLoading">Submit Itinerary</v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import Loading from "@/components/Loading.vue";
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { httpGet, httpPost } from "@/services/http";
import { toast } from "vue3-toastify";
import { debounce } from "vue-debounce";
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
  name: "AddJobPriorityView",
  components: {
    TopNav,
    Loading,
  },
  data() {
    return {
      guest: {
        firstName: "",
        lastName: "",
        contactNumber: "",
        emailAddress: "",
        noOfAdults: 0,
        noOfChilds: 0,
        noOfInfants: 0,
      },
      phoneInputKey: 0, // Add a key for v-phone-input to force re-render
      defaultContactNumberCountry: "SG",
      guestEmailAddressLoading: false,
      guestExistsData: null,
      addItineraryShow: false,
      locationLoading: {
        pick_up: false,
        destination: false,
      },
      jobTypes: ['Scheduled', 'Now'],  // This seems to be a frontend-only option
      pings: [],
      paymentTypes: [],
      vehicleTypes: [],
      job: {
        price: '',
        type: 'scheduled',
        ping: 'fleet',
        paymentType: 'cash',
        vehicleType: 'sedan'
      },
      itineraries: [],
      types: [
        {
          type: "Private",
          slug: "pte",
        },
        {
          type: "SIC",
          slug: "sic",
        },
      ],
      categories: [],
      orgCategories: [],
      newItinerary: {
        type: "pte",
        category: "ARR",
        date: "",
        pickUpTime: "",
        pickUpLocation: null,
        pickUpLocationSuggestions: [],
        destinationLocation: null,
        destinationLocationSuggestions: [],
        tickets: [],
        remarks: null,
      },
      editItinerary: {
        type: "",
        category: "",
        date: "",
        pickUpTime: "",
        pickUpLocation: null,
        pickUpLocationSuggestions: [],
        destinationLocation: null,
        destinationLocationSuggestions: [],
        tickets: [],
        remarks: null,
      },
      editTransferModal: {
        index: null,
        show: false,
      },
      allStayDates: [],
      guestValidation: {
        firstName: [(v) => !!v || "First name is required"],
        lastName: [(v) => !!v || "Last name is required"],
        contactNumber: [(v) => !!v || "Contact number is required"],
        emailAddress: [
          (v) => !!v || "Email address is required",
          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "Invalid email address",
        ],
        numberValidation: [
          (v) => !v || /^(0|[1-9][0-9]*)$/.test(v) || "Only numbers are allowed",
        ],
        dateValidation: [
          v => !!v || 'Date is required',
          v => /^\d{4}-\d{2}-\d{2}$/.test(v) || 'Invalid date format (YYYY-MM-DD)',
          v => {
            const today = new Date().setHours(0, 0, 0, 0);
            const inputDate = new Date(v).getTime();
            return inputDate >= today || "Date can't be in the past"; // Optional: remove this line if past dates are allowed
          }
        ],
        priceValidation: [
          v => !!v || 'Price is required',
          v => !isNaN(parseFloat(v)) && v >= 0 || 'Invalid price', // Ensure value is a non-negative number
          v => !isNaN(parseFloat(v)) && v >= 40 || 'Minimum price is $40', // Ensure value is a non-negative number
          v => /^(\d+(\.\d{0,2})?)$/.test(v) || 'Price must be a valid number with up to 2 decimal places', // Optional: Adjust regex as needed for your price format
        ],
      },
      itineraryLocationValidation: {
        pickUpLocationRules: [(v) => !!v || "Pick up location is required"],
        destinationLocationRules: [(v) => !!v || "Destination location is required"],
      },
      isLoading: false,
      subscription: null,
      jobPriorityTypesSubscription: null,
      jobPingsSubscription: null,
      jobPriorityPaymentTypesSubscription: null,
      vehicleTypesSubscription: null,
    };
  },
  watch: {
    "newItinerary.type": function (newVal, oldVal) {
      // Your logic here
      console.log('Itinerary type changed from', oldVal, 'to', newVal);
      // Add your custom logic to handle the type change
      this.filterTransferTypes()

      // Check if the selected category is in the new list
      const foundCategory = this.categories.find((a) => a.slug === this.newItinerary.category);

      if (!foundCategory) {
        // If not found, select the first element in the list
        this.newItinerary.category = this.categories.length > 0 ? this.categories[0].slug : null;
      }
    },
    "editItinerary.type": function (newVal, oldVal) {
      // Your logic here
      console.log('Itinerary type changed from', oldVal, 'to', newVal);
      // Add your custom logic to handle the type change
      this.filterTransferTypes()

      // Check if the selected category is in the new list
      const foundCategory = this.categories.find((a) => a.slug === this.editItinerary.category);

      if (!foundCategory) {
        // If not found, select the first element in the list
        this.editItinerary.category = this.categories.length > 0 ? this.categories[0].slug : null;
      }
    }
  },
  created() {
    indexedDBService.getTransferTypesData();
    this.fetchData();
    this.fetchPriorityData();
  },
  beforeUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.jobPriorityTypesSubscription) {
      this.jobPriorityTypesSubscription.unsubscribe();
    }
    if (this.jobPingsSubscription) {
      this.jobPingsSubscription.unsubscribe();
    }
    if (this.jobPriorityPaymentTypesSubscription) {
      this.jobPriorityPaymentTypesSubscription.unsubscribe();
    }
    if (this.vehicleTypesSubscription) {
      this.vehicleTypesSubscription.unsubscribe();
    }
  },
  methods: {
    filterTransferTypes() {
      if (!(this.editTransferModal.show === true)) {
        if (this.newItinerary.type === 'pte') {
          this.categories = this.orgCategories.filter((a) => a.is_private === 1)
        } else {
          this.categories = this.orgCategories.filter((a) => a.is_sic_per_head === 1)
        }
      } else {
        if (this.editItinerary.type === 'pte') {
          this.categories = this.orgCategories.filter((a) => a.is_private === 1)
        } else {
          this.categories = this.orgCategories.filter((a) => a.is_sic_per_head === 1)
        }
      }
    },
    fetchData() {
      const observableData =
        liveQuery(async () => {
          // Fetch transfer types with their related locations and location details
          const transfers = await db.transfer_types.orderBy('transfer_type_special_name').toArray();
          const locations = await db.locations.toArray();
          const locationDetails = await db.location_details.toArray();

          // Create a map for locations and location details for easy lookup
          const locationMap = new Map(locations.map(location => [location.uuid, location]));
          const locationDetailMap = new Map(locationDetails.map(detail => [detail.uuid, detail]));

          // Attach related location and location details to each transfer
          const transfersWithDetails = transfers.map(transfer => {
            const location = locationMap.get(transfer.location_uuid) || null;
            if (location) {
              location.detail = locationDetailMap.get(location.detail_uuid) || null;
            }
            return {
              type: transfer.transfer_type_special_name,
              slug: transfer.transfer_type_special_slug,
              location: location ? location : null,
              start_time: transfer.start_time,
              return_time: transfer.return_time,
              sic_start_time: transfer.sic_start_time,
              sic_return_time: transfer.sic_return_time,
              is_private: transfer.is_private,
              is_sic_per_head: transfer.is_sic_per_head,
              is_sic_per_hotel: transfer.is_sic_per_hotel,
              is_private_tour_guide: transfer.is_private_tour_guide,
              is_sic_tour_guide: transfer.is_sic_tour_guide,
            };
          });

          return transfersWithDetails;
        });

      this.subscription = observableData.subscribe((result) => {
        this.orgCategories = result
        this.filterTransferTypes()
      });
    },
    fetchPriorityData() {
      // Fetch job priority type
      const observableJobPriorityTypes = liveQuery(async () => {
        return await db.job_priority_types
          .orderBy('type_name')
          .filter(type => !type.deleted_at)
          .toArray();
      });

      this.jobPriorityTypesSubscription = observableJobPriorityTypes.subscribe((result) => {
        this.jobTypes = result.map(type => ({
          type_name: type.type_name,
          type_slug: type.type_slug
        }));
      });

      // Fetch job pings
      const observableJobPings = liveQuery(async () => {
        return await db.job_pings
          .orderBy('ping_name')
          .filter(ping => !ping.deleted_at)
          .toArray();
      });

      this.jobPingsSubscription = observableJobPings.subscribe((result) => {
        this.pings = result.map(ping => ({
          ping_name: ping.ping_name,
          ping_slug: ping.ping_slug
        }));
      });

      // Fetch payment types
      const observablePaymentTypes = liveQuery(async () => {
        return await db.job_payment_types
          .orderBy('type_name')
          .filter(type => !type.deleted_at)
          .toArray();
      });

      this.jobPriorityPaymentTypesSubscription = observablePaymentTypes.subscribe((result) => {
        this.paymentTypes = result.map(type => ({
          type_name: type.type_name,
          type_slug: type.type_slug
        }));
      });

      // Fetch vehicle types
      const observableVehicleTypes = liveQuery(async () => {
        return await db.vehicle_types
          .orderBy('type_name')
          .filter(type => !type.deleted_at)
          .toArray();
      });

      this.vehicleTypesSubscription = observableVehicleTypes.subscribe((result) => {
        this.vehicleTypes = result.map(type => ({
          type_name: type.type_name,
          type_slug: type.type_slug
        }));
      });
    },
    formatPrice() {
      if (!isNaN(this.job.price) && this.job.price !== '') {
        this.job.price = parseFloat(this.job.price).toFixed(2);
      }
    },
    generateDate() {
      let allDates = [];
      let currentDate = new Date(this.guest.date);
      const endDate = new Date(this.guest.date);

      while (currentDate <= endDate) {
        allDates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.allStayDates = allDates; // Store the calculated dates
      console.log(allDates); // Or process them as needed
      this.itineraries = []
    },
    checkGuestExists(value) {
      if (this.isEmailValid()) {
        let dataToSend = {
          email_address: value, // Assuming id is the user_id selected from the autocomplete
        };

        httpPost(`/api/v1/users/tourists/exists`, dataToSend, null)
          .then((result) => {
            this.guest.firstName = "";
            this.guest.lastName = "";
            this.guest.contactNumber = "";

            this.guestExistsData = result.data.data;

            if (this.guestExistsData) {
              this.guest.firstName = this.guestExistsData.first_name;
              this.guest.lastName = this.guestExistsData.last_name;
              let contactNumber = this.guestExistsData.contact_numbers.find(
                (a) => a.is_primary === 1
              );
              if (contactNumber) {
                this.defaultContactNumberCountry =
                  contactNumber.country_code.country.iso_3166_1;
                this.guest.contactNumber = contactNumber.contact_number;
                this.phoneInputKey += 1;
              }
            }
            this.guestEmailAddressLoading = false;
          })
          .catch((error) => {
            console.error("Error:", error);
            this.guestEmailAddressLoading = false;
          });
      }
    },
    debouncedCheckGuestExists: debounce(function (value) {
      this.guestEmailAddressLoading = true;
      this.checkGuestExists(value);
    }, 500),
    isEmailValid() {
      return this.guestValidation.emailAddress.every(
        (rule) => rule(this.guest.emailAddress) === true
      );
    },
    fetchLocationSuggestions(value, field, editItinerary) {
      if (!value) {
        this.locationSuggestions = [];
        return;
      }

      httpGet(`/api/v1/users/locations/search?value=${encodeURIComponent(value)}`)
        .then((result) => {
          console.log("Is Editing Itinerary ", editItinerary);
          console.log("Current Searching for ", value);
          if (result.status && result.data) {
            if (field === "pick_up") {
              if (!editItinerary) {
                this.newItinerary.pickUpLocationSuggestions = result.data.data;
                if (this.newItinerary.category === "ARR") {
                  let location = this.categories.find(
                    (a) => a.slug === this.newItinerary.category
                  ).location;
                  this.newItinerary.pickUpLocationSuggestions.unshift(location);
                }
              } else {
                this.editItinerary.pickUpLocationSuggestions = result.data.data;
                if (this.editItinerary.category === "ARR") {
                  let location = this.categories.find(
                    (a) => a.slug === this.editItinerary.category
                  ).location;
                  this.editItinerary.pickUpLocationSuggestions.unshift(location);
                }
              }
              console.log(this.newItinerary.pickUpLocation);
            } else if (field === "destination") {
              if (!editItinerary) {
                this.newItinerary.destinationLocationSuggestions = result.data.data;
                let location = this.categories.find(
                  (a) => a.slug === this.newItinerary.category
                ).location;
                if (this.newItinerary.category !== "ARR" && location) {
                  this.newItinerary.destinationLocationSuggestions.unshift(location);
                }
              } else {
                this.editItinerary.destinationLocationSuggestions = result.data.data;
                let location = this.categories.find(
                  (a) => a.slug === this.editItinerary.category
                ).location;
                if (this.editItinerary.category !== "ARR" && location) {
                  this.editItinerary.destinationLocationSuggestions.unshift(location);
                }
              }
            }
            this.locationLoading[field] = false;
          } else {
            throw new Error("Failed to fetch location suggestions");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.locationSuggestions = [];
          this.locationLoading[field] = false;
        });
    },
    debouncedFetchLocationSuggestions: debounce(function (
      value,
      field,
      editItinerary = false
    ) {
      this.locationLoading[field] = true;
      this.fetchLocationSuggestions(value, field, editItinerary);
    },
      500),
    onCategoryChange(newValue, isEdit = false) {
      console.log("Category changed to:", newValue);

      if (!newValue) {
        // If newValue is null or undefined, reset the relevant fields
        if (isEdit) {
          this.editItinerary.pickUpLocation = null;
          this.editItinerary.pickUpLocationSuggestions = [];
          this.editItinerary.destinationLocation = null;
          this.editItinerary.destinationLocationSuggestions = [];
        } else {
          this.newItinerary.pickUpLocation = null;
          this.newItinerary.pickUpLocationSuggestions = [];
          this.newItinerary.destinationLocation = null;
          this.newItinerary.destinationLocationSuggestions = [];
        }
        return;
      }

      let category = this.categories.find((a) => a.slug === newValue);
      let location = category.location;
      if (location) {
        if (newValue === "ARR") {
          if (isEdit) {
            this.editItinerary.pickUpLocation = location.uuid;
            this.editItinerary.pickUpLocationSuggestions = [location];
          } else {
            this.newItinerary.pickUpLocation = location.uuid;
            this.newItinerary.pickUpLocationSuggestions = [location];
          }
        } else {
          if (isEdit) {
            this.editItinerary.destinationLocation = location.uuid;
            this.editItinerary.destinationLocationSuggestions = [location];
          } else {
            this.newItinerary.destinationLocation = location.uuid;
            this.newItinerary.destinationLocationSuggestions = [location];
          }
        }
      }

      if (category.start_time !== null || category.return_time !== null || category.sic_start_time !== null || category.sic_return_time !== null) {
        const getFormattedTime = (date, timeString) => {
          if (!timeString) {
            return null;
          }
          const [hours, minutes] = timeString.split(":").map(Number);
          const currentDate = new Date(date);
          currentDate.setHours(hours, minutes, 0, 0); // Ensure seconds and milliseconds are set to 0
          return `${`0${currentDate.getHours()}`.slice(-2)}:${`0${currentDate.getMinutes()}`.slice(-2)}`;
        };

        if (isEdit) {
          if (this.editItinerary.type === "pte") {
            if (category && category.start_time) {
              this.editItinerary.pickUpTime = getFormattedTime(this.editItinerary.date, category.start_time);
            }
          } else {
            if (category && category.sic_start_time) {
              this.editItinerary.pickUpTime = getFormattedTime(this.editItinerary.date, category.sic_start_time);
            }
          }
        } else {
          if (this.newItinerary.type === "pte") {
            if (category && category.start_time) {
              console.log('Got time start')
              this.newItinerary.pickUpTime = getFormattedTime(this.newItinerary.date, category.start_time);
            }
            if (category && category.return_time) {
              console.log('Got return start')
              this.newItinerary.returnTime = getFormattedTime(this.newItinerary.date, category.return_time);
            }
          } else {
            if (category && category.sic_start_time) {
              this.newItinerary.pickUpTime = getFormattedTime(this.newItinerary.date, category.sic_start_time);
            }
            if (category && category.sic_return_time) {
              this.newItinerary.returnTime = getFormattedTime(this.newItinerary.date, category.sic_return_time);
            }
          }
        }
      }
    },
    calculateDateRanges() {
      let allDates = [];
      this.stays.forEach((stay) => {
        let currentDate = new Date(stay.startDate);
        const endDate = new Date(stay.endDate);

        while (currentDate <= endDate) {
          allDates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      });

      this.allStayDates = allDates; // Store the calculated dates
    },
    formatDate(date) {
      const d = new Date(date);
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const year = d.getFullYear();

      return `${day}/${month}/${year}`;
    },
    formatDateFull(date) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const d = new Date(date);
      const day = d.getDate();
      const month = months[d.getMonth()];
      const year = d.getFullYear();
      return `${day} ${month} ${year}`;
    },
    addTickets(event, isEdit = false) {
      const files = event.target.files; // Get selected files from input event
      const itinerary = isEdit ? this.editItinerary : this.newItinerary;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // Check if file is not already selected
        if (!itinerary.tickets.find((selectedFile) => selectedFile.name === file.name)) {
          itinerary.tickets.push(file); // Add file to the appropriate tickets array
        }
      }
    },
    // Generate Default Values
    addItinerary(date) {
      this.addItineraryShow = !this.addItineraryShow;
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2); // Months are 0-based
      const day = `0${date.getDate()}`.slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      const defaultTime = "09:00"; // Default time set to 9:00 AM
      const returnTime = "21:00"; // Return time set to 9:00 PM

      this.newItinerary = {
        type: "pte",
        category: "ARR",
        date: formattedDate, // Assuming you want to set the date here as well
        pickUpTime: defaultTime,
        pickUpLocation: "",
        pickUpLocationSuggestions: [],
        destinationLocation: "",
        destinationLocationSuggestions: [],
        tickets: [],
        remarks: "",
        returnTime: returnTime,
      };

      const lastItinerary = this.getItinerariesForDate(date)[
        this.getItinerariesForDate(date).length - 1
      ];

      if (lastItinerary) {
        // Default type & category
        this.newItinerary.type = lastItinerary.type;
        this.newItinerary.category = lastItinerary.category;

        // Default Pick Up Location
        this.newItinerary.pickUpLocation = lastItinerary.destinationLocation;
        this.newItinerary.pickUpLocationSuggestions =
          lastItinerary.destinationLocationSuggestions;

        // Add an hour to the pick-up time of the last itinerary
        const lastPickupTime = new Date(date); // Using current date for reference
        const [hours, minutes] = lastItinerary.pickUpTime.split(":").map(Number);
        lastPickupTime.setHours(hours + 1, minutes);
        const formattedPickupTime = `${`0${lastPickupTime.getHours()}`.slice(
          -2
        )}:${`0${lastPickupTime.getMinutes()}`.slice(-2)}`;
        this.newItinerary.pickUpTime = formattedPickupTime;
      }

    },
    async addTransferDetail() {
      const isValid = await this.$refs.addItineraryForm.validate();

      if (isValid.valid) {
        this.itineraries.push({ ...this.newItinerary });
        if (this.newItinerary.returnTime != null) {
          const firstWay = { ...this.newItinerary };
          console.log("Before assignment:", firstWay);
          this.newItinerary.pickUpTime = firstWay.returnTime;
          this.newItinerary.pickUpLocation = firstWay.destinationLocation;
          this.newItinerary.pickUpLocationSuggestions =
            firstWay.destinationLocationSuggestions;
          this.newItinerary.destinationLocation = firstWay.pickUpLocation;
          this.newItinerary.destinationLocationSuggestions =
            firstWay.pickUpLocationSuggestions;
          this.newItinerary.remarks = "";
          this.newItinerary.tickets = [];
          console.log("After assignment:", this.newItinerary);
          this.itineraries.push({ ...this.newItinerary });

          // Sort itineraries by pickup time
          this.itineraries.sort((a, b) => {
            return new Date(a.pickUpTime) - new Date(b.pickUpTime);
          });
        }
        this.addItineraryShow = !this.addItineraryShow;
      }
    },
    openEditTransfer(index) {
      console.log("Current Index: " + index);
      this.editTransferModal.index = index;
      this.editItinerary = { ...this.itineraries[index] };
      this.editTransferModal.show = true;
    },
    deleteEditedTransfer() {
      this.itineraries.splice(this.editTransferModal.index, 1);
      this.editTransferModal.show = false;
      this.$refs.editItineraryForm.reset();
    },
    async saveEditedTransfer() {
      const isValid = await this.$refs.editItineraryForm.validate();
      if (isValid) {
        console.log(this.editItinerary);
        console.log(this.itineraries);
        this.itineraries[this.editTransferModal.index] = { ...this.editItinerary };
        this.editTransferModal.show = false;
        this.$refs.editItineraryForm.reset();
      }
    },
    getItinerariesForDate(date) {
      return this.itineraries.filter((itinerary) => {
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2); // Months are 0-based
        const day = `0${date.getDate()}`.slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        // Assuming itinerary.date and date are in the same format
        return itinerary.date === formattedDate;
      });
    },
    async submitItinerary() {
      const isValid = await this.$refs.guestDetailsForm.validate();

      if (isValid.valid && isValidPhoneNumber(this.guest.contactNumber)) {
        const parsedNumber = parsePhoneNumberFromString(this.guest.contactNumber);

        let countryCode = parsedNumber.countryCallingCode; // Country code
        let contactNumber = parsedNumber.nationalNumber; // National number

        // Constructing data object to be sent to API
        let formData = new FormData();

        // Adding tourist information
        formData.append("tourist[first_name]", this.guest.firstName);
        formData.append("tourist[last_name]", this.guest.lastName);
        formData.append("tourist[email_address]", this.guest.emailAddress);
        formData.append("tourist[country_code]", countryCode);
        formData.append("tourist[contact_number]", contactNumber);
        formData.append("priority_job[job_cost]", this.job.price);
        formData.append("priority_job[type]", this.job.type);
        formData.append("priority_job[ping]", this.job.ping);
        formData.append("priority_job[payment_type]", this.job.paymentType);
        formData.append("priority_job[vehicle_type]", this.job.vehicleType);
        formData.append("job[no_of_adults]", this.guest.noOfAdults);
        formData.append("job[no_of_childs]", this.guest.noOfChilds);
        formData.append("job[no_of_infants]", this.guest.noOfInfants);

        // Adding transfer details including tickets
        this.itineraries.forEach((itinerary, index) => {
          formData.append(`job[transfers][${index}][transfer_type_slug]`, itinerary.type);
          formData.append(
            `job[transfers][${index}][transfer_type_special_slug]`,
            itinerary.category
          );
          formData.append(
            `job[transfers][${index}][pick_up_time]`,
            this.formatDateTime(itinerary.date, itinerary.pickUpTime)
          );
          formData.append(`job[transfers][${index}][pick_up]`, itinerary.pickUpLocation);
          formData.append(
            `job[transfers][${index}][destination]`,
            itinerary.destinationLocation
          );
          formData.append(`job[transfers][${index}][remarks]`, itinerary.remarks);
          // Handling tickets as files
          itinerary.tickets.forEach((file, fileIndex) => {
            formData.append(`job[transfers][${index}][tickets][${fileIndex}]`, file);
          });
        });

        const config = {
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
        };

        let id = toast.loading("Please wait...");
        this.isLoading = true;
        httpPost("/api/v1/users/jobs/priorities/create", formData, id, config)
          .then((response) => {
            const uuid = response.data.data.uuid;
            toast.update(id, {
              render: response.data.message,
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            this.isLoading = false;
            this.$router.push({ name: "JobDetail", params: { id: uuid } });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } else {
        this.$refs.guestDetailsForm.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    formatDateTime(date, time) {
      const dateObj = new Date(`${date} ${time}`);
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
      const day = ("0" + dateObj.getDate()).slice(-2);
      const hours = ("0" + dateObj.getHours()).slice(-2);
      const minutes = ("0" + dateObj.getMinutes()).slice(-2);
      const seconds = ("0" + dateObj.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style src="@/assets/css/job.css" scoped></style>