<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Add Fleet Drivers</h1>
                <p>Dashboard > DTC > Add Fleet Driver</p>
            </div>


            <div class="request-details">
                <div class="upgrade"
                    v-if="this.profile && (this.profile.current_fleet_drivers >= this.profile.allowed_fleet_drivers)">
                    <v-icon color="warning" size="36" class="upgrade-icon">mdi-alert-circle</v-icon>
                    <h2 class="upgrade-title">Fleet Driver Limit Reached</h2>
                    <div class="upgrade-content">
                        <p>You've reached your maximum number of fleet drivers. Upgrade your plan to get more!
                        </p>
                        <p class="mt-2">Need More Drivers?</p>
                        <v-btn @click="this.$router.push('/profile')" color="primary" prepend-icon="mdi-cart"
                            class="mt-3">
                            Purchase More
                        </v-btn>
                    </div>
                </div>

                <v-form ref="form" id="add-form" v-else>

                    <div class="display-image">
                        <v-col cols="12" class="pa-1 pb-3 text-center">
                            <v-avatar size="100" @click="triggerFileInput" class="avatar-container">
                                <template v-if="displayImage">
                                    <img :src="displayImage" alt="Display Image" class="avatar-image" />
                                    <p class="edit-text">Edit</p>
                                </template>
                                <template v-else>
                                    <v-icon size="48">mdi-camera</v-icon>
                                </template>
                            </v-avatar>
                            <v-file-input id="fileUpload" v-model="displayImageFile" accept=".jpg,.jpeg,.png"
                                label="Upload Display Image" @change="onImageChange" prepend-icon="mdi-camera"
                                style="display: none;"></v-file-input>
                        </v-col>
                    </div>

                    <div class="company-name d-flex w-100">
                        <v-col cols="6" class="pa-1 pb-3">
                            <v-text-field label="First Name" v-model="firstName" :rules="firstNameValidation"
                                placeholder="John"></v-text-field>
                        </v-col>

                        <v-col cols="6" class="pa-1 pb-3">
                            <v-text-field label="Last Name" v-model="lastName" :rules="lastNameValidation"
                                placeholder="Doe"></v-text-field>
                        </v-col>
                    </div>

                    <div class="contact-number">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-phone-input v-model="contactNumber" :rules="contactNumberValidation" />
                        </v-col>
                    </div>

                    <div class="email-address">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Email Address" v-model="emailAddress" :rules="emailValidation"
                                placeholder="john.doe@example.com"></v-text-field>
                        </v-col>
                    </div>

                    <!-- <div class="car-plate">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Car Plate" v-model="carPlate" :rules="carPlateValidation"
                                placeholder="SMW6231L"></v-text-field>
                        </v-col>
                    </div>

                    <div class="max-no-of-pax">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Max No. of Pax" v-model="pax" :rules="paxValidation"
                                placeholder="5"></v-text-field>
                        </v-col>
                    </div>

                    <div class="vehicle-type">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-select label="Vehicle Type" v-model="vehicleType"
                                :items="['Sedan', 'Sedan Luxury', 'MPV', 'MPV Luxury', 'Bus', 'Mini Bus']"></v-select>
                        </v-col>
                    </div> -->

                    <div class="password">
                        <v-col cols="12" class="pa-1 pb-3">
                            <p>Note: Password will be automatically emailed to driver</p>
                        </v-col>
                    </div>

                    <v-col cols="12">
                        <v-card>
                            <v-tabs v-model="tab"
                                style="background-color: var(--primary-blue); color: var(--secondary-white);">
                                <v-tab value="private">Private</v-tab>
                                <v-tab value="sic">SIC</v-tab>
                            </v-tabs>

                            <v-card-text>
                                <v-tabs-window v-model="tab">
                                    <v-tabs-window-item value="private" v-if="tab == 'private'">
                                        <v-col cols="6" sm="6" v-for="(category, index) in categories" :key="index"
                                            style="display: inline-block;">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPrices(category.price) : formatPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                    </v-tabs-window-item>

                                    <v-tabs-window-item value="sic" v-if="tab == 'sic'">
                                        <v-col cols="12">
                                            <v-radio-group v-model="sicType" @change="updateSICCategories">
                                                <!-- <v-radio label="Per Head" value="perHead"></v-radio> -->
                                                <v-radio label="Per Hotel" value="perHotel"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="6" sm="6" v-for="(category, index) in perHeadCategory" :key="index"
                                            style="display: inline-block;" v-if="sicType == 'perHead'">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPerHeadPrices(category.price) : formatPerHeadPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="6" v-for="(category, index) in perHotelCategory"
                                            :key="index" style="display: inline-block;" v-if="sicType == 'perHotel'">
                                            <v-text-field :label="category.type + ' Price'" v-model="category.price"
                                                @blur="index === 0 ? autoFillPerHotelPrices(category.price) : formatPerHotelPrices()"
                                                :rules="priceValidation" placeholder="Enter price"
                                                prefix="$"></v-text-field>
                                        </v-col>
                                    </v-tabs-window-item>
                                </v-tabs-window>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <div class=" buttons">
                        <v-btn class="button-1" @click.prevent="submitRequest" :loading="isLoading">Request</v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { useAuthStore } from '@/stores/AuthStore'
import { mapState, mapActions } from 'pinia';
import Loading from '@/components/Loading.vue'
import { isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

// Toast Notification
import { toast } from 'vue3-toastify';
import { httpPost } from '@/services/http';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'DTCFleetAddView',
    components: {
        TopNav,
        Loading,
    },
    data() {
        return {
            displayImage: '',
            displayImageFile: null,
            firstName: '',
            lastName: '',
            contactNumber: '',
            emailAddress: '',
            carPlate: '',
            pax: '',
            // vehicleType: 'Sedan',
            firstNameValidation: [
                v => !!v || 'First Name is required',
            ],
            lastNameValidation: [
                v => !!v || 'Last Name is required',
            ],
            contactNumberValidation: [
                v => !!v || 'Contact number is required',
            ],
            emailValidation: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            // carPlateValidation: [
            //     v => !!v || 'Car Plate is required',
            //     v => /^[A-Z]{1,3}\d{1,4}[A-Z]$/.test(v) || 'Car Plate must be a valid Singapore car plate number',
            // ],
            // paxValidation: [
            //     v => !!v || 'Pax is required',
            //     v => !isNaN(v) || 'Pax must be a number',
            // ],
            tab: 'private',
            sicType: 'perHotel', // New data property for SIC type
            categories: [],
            perHotelCategory: [],
            perHeadCategory: [],
            priceValidation: [
                v => !!v || 'Price is required',
                v => !isNaN(parseFloat(v)) && v >= 0 || 'Invalid price', // Ensure value is a non-negative number
                v => /^(\d+(\.\d{0,2})?)$/.test(v) || 'Price must be a valid number with up to 2 decimal places', // Optional: Adjust regex as needed for your price format
            ],
            isLoading: false
        };
    },
    computed: {
        sicCategories() {
            return this.sicType === 'perHead' ? this.perHeadCategory : this.perHotelCategory;
        },
        ...mapState(useAuthStore, ['profile']),
    },
    created() {
        indexedDBService.getTransferTypesData();
        this.fetchData();
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        fetchData() {
            const observableData =
                liveQuery(async () => {
                    // Fetch transfer types with their related locations and location details
                    const transfers = await db.transfer_types.orderBy('transfer_type_special_name').toArray();

                    const transfersWithDetails = transfers.map(transfer => {
                        return {
                            type: transfer.transfer_type_special_name,
                            slug: transfer.transfer_type_special_slug,
                            start_time: transfer.start_time,
                            return_time: transfer.return_time,
                            sic_start_time: transfer.sic_start_time,
                            sic_return_time: transfer.sic_return_time,
                            is_private: transfer.is_private,
                            is_sic_per_head: transfer.is_sic_per_head,
                            is_sic_per_hotel: transfer.is_sic_per_hotel,
                            is_private_tour_guide: transfer.is_private_tour_guide,
                            is_sic_tour_guide: transfer.is_sic_tour_guide,
                        };
                    });

                    return transfersWithDetails;
                });

            observableData.subscribe((result) => {
                this.allTypes = result;

                console.log(result)

                let privateTypes = this.allTypes.filter((a) => a.is_private === 1)
                this.categories = [];
                privateTypes.forEach((type) => {
                    this.categories.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })

                let perHeadSicTypes = this.allTypes.filter((a) => a.is_sic_per_head === 1)
                this.perHeadCategory = [];
                perHeadSicTypes.forEach((type) => {
                    this.perHeadCategory.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })

                let perHotelCategoryTypes = this.allTypes.filter((a) => a.is_sic_per_hotel === 1)
                this.perHotelCategory = [];
                perHotelCategoryTypes.forEach((type) => {
                    this.perHotelCategory.push({
                        type: type.type,
                        slug: type.slug,
                    })
                })
            });
        },
        formatPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.categories.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.categories = updatedCategories;
        },
        // Example of replacing the entire array to ensure reactivity
        autoFillPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.categories.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.categories = updatedCategories;
            }

            this.formatPrices()
        },
        // Per Head Prices
        formatPerHeadPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.perHeadCategory.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.perHeadCategory = updatedCategories;
        },
        autoFillPerHeadPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.perHeadCategory.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.perHeadCategory = updatedCategories;
            }

            this.formatPerHeadPrices()
        },
        // Per Hotel Prices
        formatPerHotelPrices() {
            // Format all existing prices in the categories to two decimal places
            const updatedCategories = this.perHotelCategory.map(category => {
                let price = category.price ? parseFloat(category.price).toFixed(2) : null;
                return { ...category, price: price };
            });
            this.perHotelCategory = updatedCategories;
        },
        autoFillPerHotelPrices(firstPrice) {
            if (firstPrice && !isNaN(firstPrice)) {
                const updatedCategories = this.perHotelCategory.map((category, index) => {
                    if (index === 0 || (!category.price || category.price.trim() === '')) {
                        return { ...category, price: firstPrice };
                    }
                    return category;
                });
                this.perHotelCategory = updatedCategories;
            }

            this.formatPerHotelPrices()
        },
        onImageChange(e) {
            const file = e.target.files[0];
            if (file) {
                this.displayImage = URL.createObjectURL(file);
            }
        },
        triggerFileInput() {
            let fileUpload = document.getElementById('fileUpload')
            if (fileUpload != null) {
                fileUpload.click()
            }
        },
        async submitRequest() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid && isValidPhoneNumber(this.contactNumber)) {
                const parsedNumber = parsePhoneNumberFromString(this.contactNumber);

                let countryCode = parsedNumber.countryCallingCode; // Country code
                let contactNumber = parsedNumber.nationalNumber; // National number

                let data = new FormData();
                data.append('first_name', this.firstName);
                data.append('last_name', this.lastName);
                data.append('email_address', this.emailAddress);
                if (this.displayImageFile) {
                    data.append('image_path', this.displayImageFile);
                }

                data.append('contact_number', contactNumber);
                data.append('country_code', countryCode);

                const config = {
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data' }
                };

                // Check if response is successful & driver is created

                const privateFilled = this.categories.every(category => !isNaN(category.price) && category.price !== null);

                if (!privateFilled) {
                    toast.error('Please ensure all private prices are filled and valid.');
                    return;
                }

                const selectedSICCategories = this.sicType === 'perHead' ? this.perHeadCategory : this.perHotelCategory;

                const sicFilled = selectedSICCategories.every(category => !isNaN(category.price) && category.price !== null);

                if (!sicFilled) {
                    toast.error('Please ensure all SIC prices are filled and valid.');
                    return;
                }

                // Select the appropriate SIC categories based on sicType

                // Combine categories and selected SIC categories
                const allCategories = [
                    ...this.categories.map(category => ({
                        slug: category.slug,
                        price: parseFloat(category.price), // Ensure price is a number
                        is_sic: false
                    })),
                    ...selectedSICCategories.map(category => ({
                        slug: category.slug,
                        price: parseFloat(category.price), // Ensure price is a number
                        is_sic: true
                    }))
                ];

                // Construct the request payload
                data.append('request', JSON.stringify(allCategories));

                let id = toast.loading('Please wait...');
                this.isLoading = true
                await httpPost('/api/v1/travel_agents/dtcs/fleets/add', data, id, config)
                    .then(async response => {
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        });
                        this.isLoading = false;
                        indexedDBService.getDtcsData();
                        await this.getProfile();
                        this.$router.push('/dtc/' + response.data.data.uuid);
                    })
                    .catch(error => {
                        this.isLoading = false
                        console.error(error);
                    });
            }
        },
        ...mapActions(useAuthStore, ['getProfile'])
    },
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style src="@/assets/css/register-form.css" scoped></style>
<style scoped>
section#content .main-content {
    max-width: unset;
}

.edit-form {
    flex: 1;
}

#add-form {
    margin: auto;
    max-width: 70%;
}

section#content form>* {
    gap: unset;
}

.upgrade {
    padding: 24px;
    text-align: center;
    margin-top: 24px;
}

.upgrade-icon {
    margin-bottom: 16px;
}

.upgrade-title {
    font-size: 22px;
    font-weight: bold;
    color: #2c3e50;
    margin-bottom: 8px;
}

.upgrade-content {
    color: #5c6b7a;
    font-size: 14px;
    line-height: 1.5;
}

.upgrade-btn {
    margin-top: 16px !important;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0.5px;
    padding: 0 24px !important;
}

@media screen and (max-width: 600px) {
    .upgrade {
        padding: 20px;
    }

    .upgrade-title {
        font-size: 20px;
    }

    .upgrade-content {
        font-size: 14px;
    }

    .upgrade-btn {
        width: 100%;
    }
}

@media screen and (max-width: 950px) {
    #add-form {
        max-width: unset;
    }
}
</style>
