<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Profile</h1>
                <p>Dashboard > Profile</p>
            </div>

            <div class="user-details container">
                <div>
                    <div class="user">
                        <div class="image-container">
                            <Image :imagePath="this.profile.image_path"></Image>
                        </div>
                        <div class="details">
                            <h1 class="name">{{ this.profile.last_name }}</h1>
                            <p v-if="this.profile.role && this.profile.role.role_name">{{ this.profile.role.role_name }}
                            </p>
                        </div>
                    </div>
                    <div class="acc-status">
                        <div class="button-1" v-if="this.profile.status && this.profile.status.status_name">Status: {{
                            this.profile.status.status_name }}</div>
                    </div>
                </div>
            </div>

            <div class="header-wrapper d-flex justify-content-between align-item-center">
                <h3><v-icon class="mr-3">{{ 'mdi-account' }}</v-icon>General</h3>

                <a class="button-2" @click.prevent="this.$router.push('/profile/general/edit')">Edit</a>
            </div>

            <div class="user-general-details container">
                <div class="w-100 d-flex justify-content-between">
                    <p>Company Name</p>
                    <p>{{ this.profile.last_name }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Email Address</p>
                    <p>{{ this.profile.email_address }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Main Contact Number</p>
                    <p v-if="this.profile.contact_numbers"> +{{
                        this.profile.contact_numbers.find((a) => a.is_primary == true).country_code.country_code +
                        this.profile.contact_numbers.find((a) => a.is_primary == true).contact_number }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Google Login</p>
                    <p>{{ this.profile.google_login ? 'Yes' : 'No' }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Facebook Login</p>
                    <p>{{ this.profile.facebook_login ? 'Yes' : 'No' }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Clear Cache</p>
                    <v-btn variant="tonal" @click="clearCache" :loading="isLoading">
                        Reset
                    </v-btn>
                </div>
            </div>

            <div class="header-wrapper d-flex justify-content-between align-item-center">
                <h3><v-icon class="mr-3">{{ 'mdi-card-account-details-outline' }}</v-icon>Travel Agent</h3>

                <!-- <a class="button-2" @click.prevent="this.$router.push('/profile/travel-agent/edit')">Edit</a> -->
            </div>

            <div class="user-travel-agent-details container">
                <div class="w-100 d-flex justify-content-between">
                    <p>UEN</p>
                    <p v-if="this.profile.travel_agent">{{ this.profile.travel_agent.uen }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Licence Number</p>
                    <p v-if="this.profile.travel_agent">{{ this.profile.travel_agent.licence_number }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Itinerary Template</p>
                    <div class="content-wrapper">
                        <p v-if="this.profile.travel_agent && this.profile.travel_agent.itinerary">{{
                            this.profile.travel_agent.itinerary.template_name }}</p>
                        <a class="button-2"
                            @click.prevent="this.$router.push('/profile/travel-agent/itinerary-template')">Change
                            Template</a>
                    </div>
                </div>
            </div>

            <div class="header-wrapper d-flex justify-content-between align-item-center">
                <h3><v-icon class="mr-3">{{ 'mdi-credit-card-outline' }}</v-icon>Your Current Subscription</h3>
                <a class="button-2" @click.prevent="$router.push('/subscriptions')">View History</a>
            </div>

            <div v-if="currentSubscription" class="user-travel-agent-details container">
                <div class="important-notes">
                    <ul>
                        <h3>Important Notes</h3>
                        <li>The billing table for the active subscription shown in the history page will apply to the
                            next billing
                            cycle.</li>
                        <li>If you are within your trial period, you will only be charged when the trial ends.</li>
                        <li>Newly added add-ons will be charged in the next billing cycle.</li>
                        <li>To remove or reduce add-ons, please contact us at support@rectrixlink.com.</li>
                    </ul>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Subscription Status</p>
                    <p>{{ currentSubscription.status }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Auto-Billing On</p>
                    <p>{{ currentSubscription.auto_billing_on }}</p>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Allowed Fleet Drivers (Add-ons)</p>
                    <div class="content-wrapper">
                        <p>{{ currentSubscription.allowed_fleet_drivers }} Driver(s)</p>
                        <v-btn variant="tonal" @click="openDialog('driver')" :loading="isLoadingDriver"
                            :disabled="isLoadingDriver || isLoadingLogin">
                            Buy More
                        </v-btn>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <p>Concurrent Login (Add-ons)</p>
                    <div class="content-wrapper">
                        <p>{{ currentSubscription.concurrent_login }} Login(s)</p>
                        <v-btn variant="tonal" @click="openDialog('login')" :loading="isLoadingLogin"
                            :disabled="isLoadingDriver || isLoadingLogin">
                            Buy More
                        </v-btn>
                    </div>
                </div>
            </div>
            <div v-else class="user-travel-agent-details container">
                <div class="w-100 d-flex justify-content-between">
                    <p>Please wait...</p>
                </div>
            </div>
        </div>

        <!-- Vuetify Dialog -->
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-text>
                    <v-card-title class="d-flex justify-space-between align-center">
                        <span class="headline">Confirm Purchase</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                            <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-form ref="form">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <p>{{ dialogMessage }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn class="button-2 w-100" @click="closeDialog"
                                        :disabled="isLoadingDriver || isLoadingLogin" color="error">
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn class="button-1 w-100" @click="confirmPurchase"
                                        :loading="isLoadingDriver || isLoadingLogin" color="primary">
                                        Confirm
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import Image from '@/components/Image.vue';
import { mapState, mapActions } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'
import { httpGet, httpPost } from '@/services/http';
import { indexedDBService } from "@/services/indexedDBService";
import global from '@/global';

export default {
    name: 'ProfileView',
    components: {
        TopNav,
        Image,
    },
    data() {
        return {
            currentSubscription: null,
            isLoading: false,
            isLoadingDriver: false,
            isLoadingLogin: false,
            dialog: false,
            dialogType: null,
            dialogMessage: '',
        }
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
    },
    methods: {
        async clearCache() {
            try {
                this.isLoading = true;

                // Delete database
                await indexedDBService.deleteDatabase();

                // Refetch master data
                await Promise.all([
                    await global.masterFetch()
                ]);

            } catch (error) {
                console.error('Error resetting database:', error);
                // You might want to show an error toast or handle the error
            } finally {
                this.isLoading = false;
            }
        },
        async fetchCurrentSubscription() {
            try {
                const response = await httpGet('/api/v1/travel_agents/subscriptions/current');
                this.currentSubscription = response.data.data;
            } catch (error) {
                console.error('Failed to fetch current subscription:', error);
            }
        },
        openDialog(type) {
            this.dialogType = type;
            this.dialogMessage = type === 'driver'
                ? 'Are you sure you want to add another driver? This will be charged in the next billing cycle.'
                : 'Are you sure you want to add another concurrent login? This will be charged in the next billing cycle.';
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
            this.dialogType = null;
        },
        async confirmPurchase() {
            this.dialog = false;
            if (this.dialogType === 'driver') {
                this.isLoadingDriver = true;
                try {
                    await this.addDriver();
                } finally {
                    this.isLoadingDriver = false;
                }
            } else if (this.dialogType === 'login') {
                this.isLoadingLogin = true;
                try {
                    await this.addConcurrentLogin();
                } finally {
                    this.isLoadingLogin = false;
                }
            }
            this.dialogType = null;
        },
        async addDriver() {
            try {
                await httpPost('/api/v1/travel_agents/subscriptions/add-driver');
                await this.fetchCurrentSubscription();
                await this.getProfile();
            } catch (error) {
                console.error('Failed to add driver:', error);
            }
        },
        async addConcurrentLogin() {
            try {
                await httpPost('/api/v1/travel_agents/subscriptions/add-concurrent-login');
                await this.fetchCurrentSubscription();
                await this.getProfile();
            } catch (error) {
                console.error('Failed to add concurrent login:', error);
            }
        },
        ...mapActions(useAuthStore, ['getProfile'])
    },
    created() {
        this.fetchCurrentSubscription();
    },
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style scoped>
.important-notes ul {
    list-style-type: none;
}

.important-notes ul li::before {
    content: ">";
    color: var(--primary-blue);
    font-weight: bold;
    margin-right: 0.5rem;
}

.justify-content-between {
    gap: 20px;
    justify-content: space-between;
    align-items: center
}

.user-general-details>*,
.user-travel-agent-details>* {
    padding: 10px 0px;
    border-bottom: 1px solid var(--secondary-dark-grey);
}

.user-general-details>*:first-child,
.user-travel-agent-details>*:first-child {
    padding-top: unset;
}

.user-general-details>*:last-child,
.user-travel-agent-details>*:last-child {
    border-bottom: none;
    padding-bottom: unset;
}

.content-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
}

.user .details {
    padding-left: 20px;
}
</style>