<template>
    <v-dialog v-model="isPriorityJobDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Add Priority Job</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="togglePriorityJobDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form ref="priorityJobForm">
                    <v-container>
                        <div class="guest-types">
                            <p>Fill in the infomation to push the job to priority</p>
                        </div>
                        <div class="date-price">
                            <div class="price">
                                <v-text-field v-model="job.price" name="price" id="no" class="form-control"
                                    label="Price for all transfers" :rules="guestValidation.priceValidation"
                                    @blur="formatPrice()" prefix="$"></v-text-field>
                            </div>
                        </div>
                        <div class="job-details">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div class="type">
                                        <v-select :items="jobTypes" item-title="type_name" item-value="type_slug"
                                            label="Type" v-model="job.type"></v-select>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="pings">
                                        <v-select :items="pings" item-title="ping_name" item-value="ping_slug"
                                            label="Ping" v-model="job.ping"></v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <div class="payment-types">
                                        <v-select :items="paymentTypes" item-title="type_name" item-value="type_slug"
                                            label="Payment Type" v-model="job.paymentType"></v-select>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <div class="vehicle-types">
                                        <v-select :items="vehicleTypes" item-title="type_name" item-value="type_slug"
                                            label="Vehicle Type" v-model="job.vehicleType"></v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col cols="6">
                                    <v-btn color="error" class="w-100" @click="togglePriorityJobDialog">
                                        Cancel
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn color="primary" class="w-100" @click="sendToPriorityJob"
                                        :loading="isLoading">
                                        Submit
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Assign Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>Available {{ this.currentRole === 'tour-guide' ? 'tour guide' : this.currentRole }}:
                                    {{ dtcData.length }}</p>
                                <p>Selected Transfers: {{ assignIds.length }}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete :items="dtcData" item-title="full_name" item-value="uuid"
                                    label="Select Driver/Contractor/Tour Guide" v-model="dtcId">
                                    <!-- Custom display for selected item -->
                                    <template v-slot:selection="{ item }">
                                        <v-chip class="mr-2"
                                            :color="item.raw.user?.role?.role_name === 'Priority Jobs' ? 'black' : 'primary'">
                                            {{ item.raw.user?.role?.role_name ?? 'N/A' }}
                                        </v-chip>
                                        {{ item.raw.full_name }}
                                    </template>

                                    <!-- Custom display for items in the dropdown -->
                                    <template v-slot:item="{ props, item }">
                                        <v-list-item v-bind="props">
                                            <template v-slot:prepend>
                                                <v-chip class="mr-2"
                                                    :color="item.raw.user?.role?.role_name === 'Priority Jobs' ? 'black' : 'primary'">
                                                    {{ item.raw.user?.role?.role_name ?? 'N/A' }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:title>
                                                {{ item.raw.full_name }}
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="button-1 w-100" :disabled="dtcId === '' || assignIds.length == 0"
                                    @click="submitRequest" :loading="isLoading">Request</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="returnTrip" max-width="400" persistent>
        <v-container class="pa-5 bg-white rounded">
            <div class="d-flex align-start mb-4">
                <v-icon size="18" class="mr-3 mt-1">mdi-map-marker</v-icon>
                <div>
                    <h6 class="text-h6 font-weight-medium mb-1">Return Pick Up?</h6>
                    <div>
                        {{ returnTripQueue.length > 0 ?
                            returnTripQueue.length + ' return pick-ups detected. Do you want to select?' :
                            'Return pick-up detected. Do you want to select?'
                        }}
                    </div>
                </div>
            </div>

            <div class="ml-5 mt-4">
                <v-row no-gutters>
                    <v-col cols="6" class="pr-2">
                        <v-btn @click="this.returnTrip = false" color="error" block>
                            No
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pl-2">
                        <v-btn @click="returnTripQueue.length > 0 ? processReturnTrips() : assignReturnTrip()"
                            color="primary" block>
                            Yes
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </v-dialog>

    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <div class="information">
                    <h1>Calendar</h1>
                    <p>Dashboard > Calendar</p>
                </div>
                <v-btn variant="text" @click="toggleTicketsTable()"
                    :prepend-icon="showTicketsTable ? 'mdi-eye-off' : 'mdi-eye'">
                    {{ showTicketsTable ? 'Hide' : 'Show' }} Tickets Table
                </v-btn>
                <div class="date-control">
                    <v-btn density="compact" icon="mdi-arrow-left" @click="changeDate(-1)"></v-btn>
                    <VueDatePicker v-model="selectedDate" format="dd MMM yyyy" :enableTimePicker="false"
                        :style="{ width: 'fit-content' }" :clearable="false" @update:modelValue="selectDate" />
                    <v-btn density="compact" icon="mdi-arrow-right" @click="changeDate(1)"></v-btn>
                </div>
            </div>

            <div class="table-container" v-if="showTicketsTable">
                <table v-if="Object.keys(ticketsBreakdown).length > 0">
                    <thead>
                        <tr>
                            <th class="td-start">No.</th>
                            <th>Guest Name</th>
                            <th>Slug</th>
                            <th>Type</th>
                            <th>Adults</th>
                            <th>Children</th>
                            <th>Infants</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template :key="guestName" v-for="(guest, guestName, index) in ticketsBreakdown">
                            <tr>
                                <td class="td-start" :rowspan="Object.keys(guest).length + 1">{{ index + 1 }}.</td>
                                <td :rowspan="Object.keys(guest).length + 1">{{ guestName }}</td>
                            </tr>
                            <tr v-for="(info, slug) in guest" :key="slug">
                                <td>{{ slug }}</td>
                                <td>{{ info.name }}</td>
                                <td>{{ info.adults }}</td>
                                <td>{{ info.children }}</td>
                                <td>{{ info.infants }}</td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="4">Total</td>
                            <td>{{ Object.values(ticketsBreakdown).reduce((total, guest) => total +
                                Object.values(guest).reduce((guestTotal,
                                    info) => guestTotal + info.adults, 0), 0) }}</td>
                            <td>{{ Object.values(ticketsBreakdown).reduce((total, guest) => total +
                                Object.values(guest).reduce((guestTotal,
                                    info) => guestTotal + info.children, 0), 0) }}</td>
                            <td>{{ Object.values(ticketsBreakdown).reduce((total, guest) => total +
                                Object.values(guest).reduce((guestTotal,
                                    info) => guestTotal + info.infants, 0), 0) }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="no-data" v-else>
                    <thead>
                        <tr>
                            <td class="td-start">There are currently no tickets breakdown for this day</td>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="transfer-container" v-else>
                <div class="transfer-header d-flex align-center">
                    <v-icon class="mr-3">mdi-calendar</v-icon>
                    <h2>{{ $moment(selectedDate).format('DD MMM yyyy') }}</h2>
                </div>
                <v-data-table v-if="transferData.length > 0" :headers="headers" :items="transferData"
                    :items-per-page="-1" :sort-by="[{ key: 'pick_up_time', order: 'asc' }]" disable-pagination
                    hide-default-footer item-key="uuid">
                    <template v-slot:item="{ item, index }">
                        <tr :key="item.uuid" @click="$router.push('/jobs/' + item.id)" class="location-details">
                            <td>{{ index + 1 }}.</td>
                            <td>
                                {{ $moment(item.pick_up_time).format('HH:mm') }}
                            </td>
                            <td>{{ `${item.tourist.first_name ?? ''} ${item.tourist.last_name}` }}</td>
                            <td>{{ item.transfer_type }}</td>
                            <td>{{ item.transfer_type_special?.transfer_type_special_slug }}</td>
                            <td>{{ item.from?.main_text }}</td>
                            <td>{{ item.to?.main_text }}</td>
                            <td>{{ item.remarks ?? '-' }}</td>
                            <td @click.stop>
                                <AssignmentCard :is-priority-job="item.priority !== null" role="driver"
                                    :is-selected="assignIds.includes(item.job_transfer_uuid)"
                                    :current-role="currentRole"
                                    :assigned-job-request-details="item.assigned_job_request_details"
                                    :attendances="item.attendances" :reAssigned="item.children"
                                    @assign="handleCheckboxClick(item.job_transfer_uuid, $event)" />
                            </td>
                            <td @click.stop>
                                <AssignmentCard :is-priority-job="item.priority !== null" role="contractor"
                                    :is-selected="assignIds.includes(item.job_transfer_uuid)"
                                    :current-role="currentRole"
                                    :assigned-job-request-details="item.assigned_job_request_details"
                                    :attendances="item.attendances" :reAssigned="item.children"
                                    @assign="handleCheckboxClick(item.job_transfer_uuid, $event)" />
                            </td>
                            <td @click.stop>
                                <AssignmentCard :is-priority-job="item.priority !== null" role="tour-guide"
                                    :is-selected="assignIds.includes(item.job_transfer_uuid)"
                                    :current-role="currentRole"
                                    :assigned-job-request-details="item.assigned_job_request_details"
                                    :attendances="item.attendances" :reAssigned="item.children"
                                    @assign="handleCheckboxClick(item.job_transfer_uuid, $event)" />
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <div v-else class="transfer-detail">
                    <p>There are currently no jobs on this day</p>
                </div>
            </div>

            <div v-if="assignIds.length > 0" class="transfer-assign-shortcut d-flex align-center">
                <v-btn prepend-icon="mdi-close" color="error" @click="resetAssignIds()" class="mr-3">
                    Unselect
                </v-btn>
                <v-btn prepend-icon="mdi-check-circle-outline" class="button-1" @click="openDialog">
                    Assign Transfer
                </v-btn>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import AssignmentCard from '@/components/AssignmentCard.vue';
import { httpPost } from '@/services/http';
import { toast } from 'vue3-toastify';
import db from '@/databases/indexedDB';
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default {
    name: 'CalendarView',
    components: {
        TopNav,
        AssignmentCard
    },
    data() {
        return {
            headers: [
                { title: 'No', key: 'index', sortable: false },
                { title: 'Time', key: 'pick_up_time', sortable: false },
                { title: 'Guest Name', key: 'tourist.first_name', sortable: false },
                { title: 'Transfer Type', key: 'transfer_type', sortable: false },
                { title: 'Slug', key: 'transfer_type_special.transfer_type_special_slug', sortable: false },
                { title: 'From', key: 'from.main_text', sortable: false },
                { title: 'To', key: 'to.main_text', sortable: false },
                { title: 'Remarks', key: 'remarks', sortable: false },
                { title: 'Driver', key: 'driver', sortable: false },
                { title: 'Contractor', key: 'contractor', sortable: false },
                { title: 'Tour Guide', key: 'tour_guide', sortable: false },
            ],
            currentRole: null,
            assignIds: [],
            dtcId: '',
            orgDTCData: [],
            dtcData: [],
            orgTransferData: [],
            transferData: [],
            selectedDate: new Date(),
            isShiftKeyPressed: false,
            returnTripQueue: [],
            returnTrip: false,
            isDialogOpen: false,
            showTicketsTable: false,
            isLoading: false,
            subscription: null,
            // Prioirty Job Variables
            isPriorityJobDialogOpen: false,
            jobTypes: [],
            pings: [],
            paymentTypes: [],
            vehicleTypes: [],
            types: [],
            job: {
                price: '',
                type: 'scheduled',
                ping: 'fleet',
                paymentType: 'cash',
                vehicleType: 'sedan'
            },
            guestValidation: {
                priceValidation: [
                    v => !!v || 'Price is required',
                    v => !isNaN(parseFloat(v)) && v >= 0 || 'Invalid price', // Ensure value is a non-negative number
                    v => !isNaN(parseFloat(v)) && v >= 40 || 'Minimum price is $40', // Ensure value is a non-negative number
                    v => /^(\d+(\.\d{0,2})?)$/.test(v) || 'Price must be a valid number with up to 2 decimal places', // Optional: Adjust regex as needed for your price format
                ],
            },
            jobPriorityTypesSubscription: null,
            jobPingsSubscription: null,
            jobPriorityPaymentTypesSubscription: null,
            vehicleTypesSubscription: null,
        };
    },
    created() {
        this.init();
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.jobPriorityTypesSubscription) {
            this.jobPriorityTypesSubscription.unsubscribe();
        }
        if (this.jobPingsSubscription) {
            this.jobPingsSubscription.unsubscribe();
        }
        if (this.jobPriorityPaymentTypesSubscription) {
            this.jobPriorityPaymentTypesSubscription.unsubscribe();
        }
        if (this.vehicleTypesSubscription) {
            this.vehicleTypesSubscription.unsubscribe();
        }
    },
    methods: {
        async init() {
            await Promise.all([
                indexedDBService.getDtcsData(),
                indexedDBService.getJobsData(),
                indexedDBService.getAssignedJobRequestsData()
            ]);

            this.fetchData();
            this.fetchDtcData();
            this.fetchPriorityData();
        },
        async fetchData() {
            // 1) Get *all* job_transfers (including children).
            const allJobTransfers = await db.job_transfers
                .filter(jt => jt.deleted_at === null)
                .toArray();

            // 2) Build a Set of all child UUIDs by parsing each job_transfer’s `children`.
            //    Any transfer listed in `children` belongs to a parent job_transfer.
            const childTransfersSet = new Set();
            for (const jt of allJobTransfers) {
                if (jt.children) {
                    try {
                        // Children might be JSON or already an array, depending on how it’s stored.
                        const childrenArr = typeof jt.children === 'string'
                            ? JSON.parse(jt.children)
                            : jt.children;

                        // Make sure we have an array:
                        if (Array.isArray(childrenArr)) {
                            for (const child of childrenArr) {
                                // child.uuid should match job_transfer.uuid of the child
                                childTransfersSet.add(child.uuid);
                            }
                        }
                    } catch (error) {
                        console.error('Error parsing children for job_transfer:', jt.uuid, error);
                    }
                }
            }

            // 3) Now get all transfers from db.transfers, but skip them if their 
            //    associated job_transfer is in `childTransfersSet`.
            const observableData = liveQuery(async () => {
                const transfers = await db.transfers
                    .filter(transfer => transfer.deleted_at === null)
                    .toArray();

                // Process each transfer with its relationships
                const transfersWithDetails = await Promise.all(transfers.map(async transfer => {
                    // Get locations and transfer type
                    const [from, to, transferType] = await Promise.all([
                        db.locations.get(transfer.from_uuid),
                        db.locations.get(transfer.to_uuid),
                        db.transfer_types.get(transfer.transfer_type_special_uuid)
                    ]);

                    // Get job transfer and job details
                    var jobTransfer = await db.job_transfers
                        .where('transfer_uuid')
                        .equals(transfer.uuid)
                        .filter(jt => jt.deleted_at === null && jt.children.length !== 0)
                        .first();

                    if (!jobTransfer) {
                        jobTransfer = await db.job_transfers
                            .where('transfer_uuid')
                            .equals(transfer.uuid)
                            .filter(jt => jt.deleted_at === null)
                            .first();
                    }

                    if (!jobTransfer) return null;
                    // If this jobTransfer is known to be a child, skip it
                    if (childTransfersSet.has(jobTransfer.uuid)) {
                        return null;
                    }

                    const job = await db.jobs.get(jobTransfer.job_uuid);
                    if (!job) return null;

                    // Get tourist info
                    const tourist = await db.users.get(job.tourist_uuid);



                    // Get assigned job request details using job_transfer_uuid
                    const assignedJobRequestDetails = await db.assigned_job_request_details
                        .where('job_transfer_uuid')
                        .equals(jobTransfer.uuid)
                        .toArray();

                    const assignedJobRequests = await Promise.all(
                        assignedJobRequestDetails.map(async detail => {
                            const request = await db.assigned_job_requests.get(detail.assigned_job_request_uuid);
                            const user = request ? await db.users.get(request.user_uuid) : null;
                            return {
                                ...detail,
                                assigned_job_request: {
                                    ...request,
                                    user
                                }
                            };
                        })
                    );

                    // Get children transfers (reassigned transfers)
                    let children = [];
                    if (jobTransfer.children) {
                        try {
                            // Parse children if it's a string, otherwise use as is
                            children = typeof jobTransfer.children === 'string'
                                ? JSON.parse(jobTransfer.children)
                                : jobTransfer.children;

                            // Ensure children is an array and filter out deleted items
                            children = Array.isArray(children)
                                ? children.filter(child => !child.deleted_at)
                                : [];

                        } catch (error) {
                            console.error('Error parsing children:', error);
                            children = [];
                        }
                    }

                    // Parse attendances safely
                    let parsedAttendances = [];
                    if (transfer.attendances) {
                        try {
                            parsedAttendances = typeof transfer.attendances === 'string'
                                ? JSON.parse(transfer.attendances)
                                : transfer.attendances;

                            // Ensure it's an array
                            if (!Array.isArray(parsedAttendances)) {
                                parsedAttendances = [];
                            }
                        } catch (error) {
                            console.error('Error parsing attendances:', error);
                            parsedAttendances = [];
                        }
                    }

                    return {
                        ...transfer,
                        id: jobTransfer.job_uuid,
                        job_uuid: jobTransfer.job_uuid,
                        job_transfer_uuid: jobTransfer.uuid,
                        tourist: tourist || {},
                        no_of_adults: job.no_of_adults || 0,
                        no_of_childs: job.no_of_childs || 0,
                        no_of_infants: job.no_of_infants || 0,
                        priority: job.priority,
                        transfer_type: transfer.transfer_type_name,
                        transfer_type_special: transferType,
                        from,
                        to,
                        assigned_job_request_details: assignedJobRequests,
                        attendances: parsedAttendances,
                        children
                    };
                }));

                return transfersWithDetails.filter(Boolean);
            });

            this.subscription = observableData.subscribe((result) => {
                this.orgTransferData = result;
                console.log('The DATA', this.orgTransferData)
                this.filterBySelectedDate();
            });
        },
        async computeTicketsBreakdown() {
            const ticketsBreakdown = {};

            for (const transfer of this.transferData) {
                // Skip if tourist data is incomplete
                if (!transfer.tourist?.first_name) continue;

                const guestName = `${transfer.tourist.first_name} ${transfer.tourist.last_name}`.trim();
                const slug = transfer.transfer_type_special?.transfer_type_special_slug;
                const name = transfer.transfer_type_special?.transfer_type_special_name;

                // Skip if transfer type data is incomplete
                if (!slug || !name) continue;

                // Initialize guest entry if it doesn't exist
                if (!ticketsBreakdown[guestName]) {
                    ticketsBreakdown[guestName] = {};
                }

                // Initialize or update slug entry
                if (!ticketsBreakdown[guestName][slug]) {
                    ticketsBreakdown[guestName][slug] = {
                        name,
                        adults: transfer.no_of_adults || 0,
                        children: transfer.no_of_childs || 0,
                        infants: transfer.no_of_infants || 0
                    };
                } else {
                    // Update with maximum values
                    ticketsBreakdown[guestName][slug].adults = Math.max(
                        ticketsBreakdown[guestName][slug].adults,
                        transfer.no_of_adults || 0
                    );
                    ticketsBreakdown[guestName][slug].children = Math.max(
                        ticketsBreakdown[guestName][slug].children,
                        transfer.no_of_childs || 0
                    );
                    ticketsBreakdown[guestName][slug].infants = Math.max(
                        ticketsBreakdown[guestName][slug].infants,
                        transfer.no_of_infants || 0
                    );
                }
            }

            this.ticketsBreakdown = ticketsBreakdown;
        },
        fetchDtcData() {
            const observableData = liveQuery(async () => {
                const dtcRequests = await db.dtc_requests.orderBy('created_at').reverse().toArray();
                const users = await db.users.toArray();
                const dtcRequestDetails = await db.dtc_request_details.toArray();

                const userMap = new Map(users.map(user => [user.uuid, user]));

                return dtcRequests.map(request => {
                    const requester = userMap.get(request.requester_uuid) || null;
                    const user = userMap.get(request.user_uuid) || null;
                    const details = dtcRequestDetails.filter(detail => detail.dtc_request_uuid === request.uuid);

                    return {
                        ...request,
                        requester,
                        user,
                        details,
                    };
                });
            });

            this.subscription = observableData.subscribe(result => {
                this.orgDTCData = result
                    .filter(a => a.status === 'Accepted' && !a.deleted_at)
                    .map(a => ({
                        ...a,
                        full_name: `${a.user.first_name} ${a.user.last_name}`
                    }));
                this.filterDTCData();
            });
        },
        fetchPriorityData() {
            // Fetch job priority type
            const observableJobPriorityTypes = liveQuery(async () => {
                return await db.job_priority_types
                    .orderBy('type_name')
                    .filter(type => !type.deleted_at)
                    .toArray();
            });

            this.jobPriorityTypesSubscription = observableJobPriorityTypes.subscribe((result) => {
                this.jobTypes = result.map(type => ({
                    type_name: type.type_name,
                    type_slug: type.type_slug
                }));
            });

            // Fetch job pings
            const observableJobPings = liveQuery(async () => {
                return await db.job_pings
                    .orderBy('ping_name')
                    .filter(ping => !ping.deleted_at)
                    .toArray();
            });

            this.jobPingsSubscription = observableJobPings.subscribe((result) => {
                this.pings = result.map(ping => ({
                    ping_name: ping.ping_name,
                    ping_slug: ping.ping_slug
                }));
            });

            // Fetch payment types
            const observablePaymentTypes = liveQuery(async () => {
                return await db.job_payment_types
                    .orderBy('type_name')
                    .filter(type => !type.deleted_at)
                    .toArray();
            });

            this.jobPriorityPaymentTypesSubscription = observablePaymentTypes.subscribe((result) => {
                this.paymentTypes = result.map(type => ({
                    type_name: type.type_name,
                    type_slug: type.type_slug
                }));
            });

            // Fetch vehicle types
            const observableVehicleTypes = liveQuery(async () => {
                return await db.vehicle_types
                    .orderBy('type_name')
                    .filter(type => !type.deleted_at)
                    .toArray();
            });

            this.vehicleTypesSubscription = observableVehicleTypes.subscribe((result) => {
                this.vehicleTypes = result.map(type => ({
                    type_name: type.type_name,
                    type_slug: type.type_slug
                }));
            });
        },
        filterDTCData() {
            this.dtcId = null;
            if (this.currentRole) {
                this.dtcData = this.orgDTCData.filter(a => a.user.role.role_slug === this.currentRole);
            } else {
                this.dtcData = [...this.orgDTCData];
            }
            if (this.currentRole === 'driver') {
                this.dtcData.unshift({
                    full_name: 'Assign to Priority Jobs',
                    uuid: 'priority_jobs',
                    user: {
                        role: {
                            role_name: 'Priority Jobs'
                        }
                    }
                });
                this.dtcId = '';
            }
        },
        submitRequest() {
            if (this.dtcId === 'priority_jobs') {
                this.closeDialog();
                this.togglePriorityJobDialog();
                return;
            }

            const selectedTransfers = this.transferData.filter(transfer =>
                this.assignIds.includes(transfer.job_transfer_uuid)
            );

            const data = {
                id: this.dtcId,
                job_transfer_ids: this.assignIds,
                job_ids: [...new Set(selectedTransfers.map(transfer => transfer.id))]
            };

            const id = toast.loading('Please wait...');
            this.isLoading = true;

            httpPost('/api/v1/travel_agents/jobs/assigns/add', data, id)
                .then(() => {
                    this.dtcId = null;
                    this.currentRole = null;
                    this.assignIds = [];
                    toast.update(id, {
                        render: "Request submitted successfully",
                        type: "success",
                        isLoading: false,
                        autoClose: 5000
                    });
                    this.isLoading = false;
                    this.closeDialog();
                    this.init();
                })
                .catch(() => {
                    this.isLoading = false;
                    toast.remove(id);
                });
        },
        selectDate(date) {
            this.selectedDate = new Date(date);
            this.filterBySelectedDate();
        },
        changeDate(step) {
            const newDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + step));
            this.selectedDate = newDate;
            this.selectDate(newDate);
        },
        async filterBySelectedDate() {
            const selectedDateString = new Date(this.selectedDate).toDateString();

            const filteredTransfers = this.orgTransferData
                .filter(transfer => {
                    if (!transfer.pick_up_time) return false;
                    const pickUpDate = new Date(transfer.pick_up_time).toDateString();
                    return pickUpDate === selectedDateString;
                })
                .sort((a, b) => {
                    const timeA = new Date(a.pick_up_time);
                    const timeB = new Date(b.pick_up_time);
                    return timeA - timeB;
                });

            this.transferData = filteredTransfers;
            await this.computeTicketsBreakdown();
        },
        handleCheckboxClick(jobTransferId, role) {
            const index = this.assignIds.indexOf(jobTransferId);
            if (index === -1) {
                this.assignIds.push(jobTransferId);
                this.currentRole = role;
                this.filterDTCData();

                let selectedTransfer = this.transferData.find((a) => a.job_transfer_uuid === jobTransferId);
                let returnTrip = this.transferData.find((a) =>
                    a.to.uuid === selectedTransfer.from.uuid &&
                    a.from.uuid === selectedTransfer.to.uuid &&
                    a.tourist.uuid === selectedTransfer.tourist.uuid &&
                    this.isSameDate(a.pick_up_time, selectedTransfer.pick_up_time)
                );

                if (returnTrip && !this.assignIds.includes(returnTrip.job_transfer_uuid)) {
                    if (this.isShiftKeyPressed) {
                        this.returnTripQueue.push(returnTrip);
                    } else {
                        this.returnTrip = returnTrip.job_transfer_uuid;
                    }
                }
            } else {
                this.assignIds.splice(index, 1);
                if (this.assignIds.length === 0) {
                    this.currentRole = null;
                    this.filterDTCData();
                }
            }
        },
        togglePriorityJobDialog() {
            this.isPriorityJobDialogOpen = !this.isPriorityJobDialogOpen;
        },
        async sendToPriorityJob() {
            const isValid = await this.$refs.priorityJobForm.validate();

            if (isValid.valid) {
                let id = toast.loading("Please wait...");
                this.isLoading = true;

                try {
                    // // Constructing data object to be sent to API
                    let formData = new FormData();

                    this.assignIds.forEach(id => {
                        formData.append('ids[]', id);
                    });
                    formData.append("priority_job[job_cost]", this.job.price);
                    formData.append("priority_job[type]", this.job.type);
                    formData.append("priority_job[ping]", this.job.ping);
                    formData.append("priority_job[payment_type]", this.job.paymentType);
                    formData.append("priority_job[vehicle_type]", this.job.vehicleType);

                    const response = await httpPost("/api/v1/travel_agents/jobs/priorities/transfer", formData, id);
                    const uuid = response.data.data.uuid;

                    toast.update(id, {
                        render: response.data.message,
                        type: "success",
                        isLoading: false,
                        autoClose: 5000,
                    });

                    this.isLoading = false;
                    this.$router.push({ name: "JobDetail", params: { id: uuid } });
                    this.togglePriorityJobDialog();
                } catch (error) {
                    console.error(error);
                    this.isLoading = false;
                    toast.remove(id);
                }
            }
        },
        formatPrice() {
            if (!isNaN(this.job.price) && this.job.price !== '') {
                this.job.price = parseFloat(this.job.price).toFixed(2);
            }
        },
        handleKeyDown(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = true;
            }
        },

        handleKeyUp(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = false;
                const unassignedReturnTrips = this.returnTripQueue.filter(trip => !this.assignIds.includes(trip.job_transfer_uuid));
                if (unassignedReturnTrips.length > 0) {
                    this.returnTrip = true; // Show dialog
                } else {
                    this.returnTripQueue = [];
                }
            }
        },
        processReturnTrips() {
            if (this.returnTripQueue.length > 0) {
                this.returnTripQueue.forEach(trip => {
                    this.assignIds.push(trip.job_transfer_uuid);
                });
                this.returnTripQueue = [];
            }
            this.returnTrip = false;
        },
        resetAssignIds() {
            this.assignIds = [];
            this.currentRole = null;
            this.filterDTCData();
        },

        isSameDate(date1, date2) {
            const d1 = new Date(date1);
            const d2 = new Date(date2);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        },
        assignReturnTrip() {
            if (this.returnTrip) {
                this.assignIds.push(this.returnTrip);
                this.returnTrip = false;
            }
        },
        openDialog() {
            this.isDialogOpen = true;
        },
        closeDialog() {
            this.isDialogOpen = false;
        },
        toggleTicketsTable() {
            this.showTicketsTable = !this.showTicketsTable;
        }
    }
};
</script>

<style scoped>
.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-control {
    display: flex;
    align-items: center;
    gap: 20px;
}

.date-control .v-btn {
    color: var(--primary-blue);
}

.transfer-container {
    margin-top: 20px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.transfer-header,
.transfer-info {
    padding: 20px;
    border-bottom: 1px solid #eee;
}

.transfer-header {
    background: var(--primary-blue);
    color: #ffffff;
    border-color: var(--primary-blue);
}

.transfer-header h2 {
    margin: 0;
}

.transfer-detail {
    display: flex;
    padding: 20px;
}

.transfer-detail>div {
    margin-right: 20px;
}

.transfer-detail .time,
.transfer-detail .transfer-type,
.transfer-detail .special-slug {
    margin-bottom: 10px;
}

.line-indicator {
    width: 4px;
    background-color: var(--primary-blue);
    border-radius: 2px;
}

.location-details {
    flex-grow: 1;
    cursor: pointer;
}

.line-indicator.grey {
    background-color: var(--secondary-dark-grey);
}

.transfer-detail:not(:first-child) {
    border-top: 2px solid var(--secondary-dark-grey);
}


.location-details .user .image-container {
    height: 35px;
    width: 35px;
    min-height: 35px;
    min-width: 35px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 20px;
}


.location-details .user .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.location-details .user {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--secondary-light-grey);
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-bottom: 20px;
}

.location-info {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    gap: 20px;
}

.location-details .location {
    text-align: center;
    width: 140px;
    max-width: 200px;
}

.location-path {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.line {
    flex-grow: 1;
    height: 2px;
    background-image: linear-gradient(to right, #000 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 2px;
    background-repeat: repeat-x;
}

/** Transfers **/
.transfer-summary {
    width: 80px;
}

.transfer-assign-shortcut {
    position: sticky;
    inset: 20px 20px;
    display: flex;
    justify-content: end;
}

.transfer-assign-shortcut .v-btn {
    background-color: var(--secondary-white);
    color: var(--secondary-black);
}
</style>

<style>
/** Checkbox assign **/
.v-checkbox .v-selection-control {
    justify-content: center;
}
</style>
