<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Submit Documents</h1>
                <p>Fill in and upload the necessary documents</p>
            </div>

            <div class="info-container container">
                <h3><v-icon class="mr-3">{{ 'mdi-file-document-multiple' }}</v-icon> Submit Document</h3>
                <p>Please submit the essential documents to use our services.</p>
                <p>After we have checked your documents, we will inform you via the email address you have provided.</p>
                <v-form ref="form" id="submit-document-form" @submit.prevent="submitForm">

                    <div class="title">
                        <p>Company Information</p>
                    </div>

                    <div class="company-registered-number">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Business' UEN" v-model="uen" :rules="uenValidation"
                                placeholder="123456789U"></v-text-field>
                        </v-col>
                    </div>

                    <div class="acra">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-file-input v-model="acraDocument" accept=".jpg,.jpeg,.png,.pdf" label="ACRA Document"
                                :rules="acraValidation" @change="onImageChange('acraDocument', $event)"
                                prepend-icon="mdi-file-document"></v-file-input>
                        </v-col>
                    </div>

                    <div class="licence-number">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Travel Agent Licence Number" v-model="licenceNumber"
                                :rules="licenceNumberValidation" placeholder="TA12345"></v-text-field>
                        </v-col>
                    </div>

                    <div class="licence-document">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-file-input v-model="licenceDocument" accept=".jpg,.jpeg,.png,.pdf"
                                label="Travel Agent Licence Document" :rules="licenceDocumentValidation"
                                @change="onImageChange('licenceDocument', $event)"
                                prepend-icon="mdi-file-document"></v-file-input>
                        </v-col>
                    </div>

                    <div class="title">
                        <p>Owner Information</p>
                    </div>

                    <div class="owner-full-name">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Owner Full Name" v-model="ownerFullName" :rules="fullNameValidation"
                                placeholder="Freddy Gleichner"></v-text-field>
                        </v-col>
                    </div>

                    <div class="owner-nric">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Owner Last 4 Alphanumeric of NRIC" v-model="ownerNric"
                                :rules="nricValidation" placeholder="123D"></v-text-field>
                        </v-col>
                    </div>

                    <div class="owner-nric-warning">
                        <v-col cols="12" class="pa-1 pb-3">
                            <p>Important: Please do not send your NRIC, instead use driving licence or other forms of
                                verification that can help us identify you.</p>
                            <strong>
                                If you send in your NRIC, we will reject your request.
                            </strong>
                        </v-col>
                    </div>

                    <div class="owner-prove">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-file-input v-model="proveDocument" accept=".jpg,.jpeg,.png,.pdf"
                                label="Owner Prove Document" :rules="proveDocumentValidation"
                                @change="onImageChange('proveDocument', $event)"
                                prepend-icon="mdi-file-document"></v-file-input>
                        </v-col>
                    </div>

                    <v-col cols="12" class="pa-1 pb-3">
                        <v-btn block class="button-1" type="submit" form="submit-document-form"
                            :loading="isLoading">Submit
                            Document</v-btn>
                    </v-col>
                </v-form>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import { mapState, mapActions } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';

// Toast Notification
import { toast } from 'vue3-toastify';
import { httpPost } from '@/services/http';

export default {
    name: 'SubmitDocumentView',
    components: {
        TopNav,
    },
    data() {
        return {
            uen: '',
            licenceNumber: '',
            ownerFullName: '',
            ownerNric: '',
            acraDocument: null,
            licenceDocument: null,
            proveDocument: null,
            isLoading: false,
            uenValidation: [
                v => !!v || 'UEN is required',
                v => (v && (v.length === 9 || v.length === 10)) || 'UEN must be 9 or 10 characters long',
                v => (v && v.length === 9 && /^[0-9]{8}[A-Z]$/.test(v)) ||
                    (v && v.length === 10 && (
                        /^[0-9]{4}[0-9]{5}[A-Z]$/.test(v) ||  // Format for local companies
                        /^[T|S][0-9]{2}[A-Z0-9]{2}[0-9]{4}[A-Z]$/.test(v)  // Format for other entities
                    )) || 'UEN format is invalid'
            ],
            licenceNumberValidation: [
                v => !!v || 'Licence Number is required',
                v => /^TA\d{5}$/.test(v) || 'Licence Number must be in the format TA12345',
            ],
            fullNameValidation: [
                v => !!v || 'Full Name is required',
            ],
            nricValidation: [
                v => !!v || 'NRIC is required',
                v => /^[0-9]{3}[A-Z]{1}$/.test(v) || 'NRIC must be in the format 123D',
            ],
            acraValidation: [
                v => !!v || 'ACRA Document is required',
            ],
            licenceDocumentValidation: [
                v => !!v || 'Licence Document is required',
            ],
            proveDocumentValidation: [
                v => !!v || 'Owner Prove Document is required',
            ],
        };
    },
    watch: {
        uen(newValue) {
            this.uen = newValue.toUpperCase();
        },
        licenceNumber(newValue) {
            this.licenceNumber = newValue.toUpperCase();
        },
        ownerNric(newValue) {
            this.ownerNric = newValue.toUpperCase();
        }
    },
    computed: {
        ...mapState(useAuthStore, ['profile']),
    },
    methods: {
        onImageChange(field, e) {
            const file = e.target.files[0];
            if (file) {
                this[field] = file;
            }
        },
        async submitForm() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid) {
                let data = new FormData();
                data.append('uen', this.uen);
                data.append('acra_document', this.acraDocument);
                data.append('licence_number', this.licenceNumber);
                data.append('licence_document', this.licenceDocument);
                data.append('owner_full_name', this.ownerFullName);
                data.append('owner_nric', this.ownerNric);
                data.append('owner_document', this.proveDocument);

                const config = {
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                let id = toast.loading('Please wait...');
                this.isLoading = true

                await httpPost('/api/v1/travel_agents/users/update/submit-documents', data, id, config)
                    .then(async response => {
                        toast.update(id, {
                            render: response.data.message,
                            type: "success",
                            isLoading: false,
                            autoClose: 5000,
                        });
                        this.getProfile()
                            .then(result => {
                                this.isLoading = false;
                            })
                            .catch(error => {
                                console.error('Error:', error);
                            });
                    })
                    .catch(error => {
                        this.isLoading = false
                        console.error(error);
                    });
            }
        },
        ...mapActions(useAuthStore, ['getProfile']),
    }
};
</script>

<style src="@/assets/css/job.css" scoped></style>
<style scoped>
form {
    width: 60%;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.info-container h3 {
    color: var(--primary-blue);
}

@media screen and (max-width: 660px) {
    form {
        width: 100%;
    }
}

.info-container form .title {
    font-weight: bold;
}

.info-container form>*:not(.title, .owner-nric-warning) {
    text-align: initial;
}
</style>
