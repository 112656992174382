import { toast } from 'vue3-toastify';
import { useAuthStore } from '@/stores/AuthStore';
const axios = require('axios');

const baseURL = process.env.VUE_APP_API_BASE_URL;
let toastId = null;
// let isRefreshing = false;
let failedQueue = [];

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 300000,
    'Accept': 'application/json',
    headers: { 'Content-Type': 'application/json' },
});

axiosInstance.interceptors.request.use(
    config => {
        const authStore = useAuthStore(); // Access authStore
        let accessToken = authStore.accessToken; // Get the access token from authStore
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401) {
            const authStore = useAuthStore();
            if (!authStore.isLogin) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return axiosInstance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            if (!authStore.isRefreshing) {
                authStore.isRefreshing = true;
                const refreshTokenSuccess = await refreshAccessToken();
                authStore.isRefreshing = false;

                console.log('Refresh token successfully')

                if (refreshTokenSuccess) {
                    const authStore = useAuthStore(); // Access authStore
                    if (authStore.isLogin) {
                        originalRequest.headers['Authorization'] = `Bearer ${authStore.accessToken}`;
                        processQueue(null, authStore.accessToken);
                        return axiosInstance(originalRequest);
                    }
                } else {
                    failedQueue = []
                    authStore.logout(true); // Log out after max retries
                }
            } else {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return axiosInstance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }
        } else {
            const errorEntity = createErrorEntity(error);
            onError(errorEntity);
            return Promise.reject(error);
        }
    }
);

function processQueue(error, token = null) {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
}

class ErrorEntity {
    constructor(code, message) {
        this.code = code;
        this.message = message;
    }
}

function createErrorEntity(error) {
    let message = "Unexpected error occurred!";
    const statusCode = error.response ? error.response.status : -1;

    if (error.response) {
        message = error.response.data.message || error.response.statusText || "Unknown server error";
    } else if (error.request) {
        message = "No response was received";
    } else {
        message = error.message;
    }

    // Handle specific status codes with custom messages or actions
    switch (statusCode) {
        case 400: message = `${message}`; break;
        case 401: message = "Unauthorized access"; break;
        case 403: message = "Forbidden"; break;
        case 404: message = "Not found"; break;
        case 405: message = "Method not allowed"; break;
        case 429: message = "Too many requests, please try again later."; break;
        case 500: message = "Internal server error"; break;
        case 502: message = "Bad gateway"; break;
        case 503: message = "Service unavailable"; break;
        case 504: message = "Gateway timeout"; break;
        case 505: message = "HTTP version not supported"; break;
    }

    return new ErrorEntity(statusCode, message);
}

function onError(errorEntity) {
    if (toastId != null) {
        toast.update(toastId, {
            render: errorEntity.message,
            type: "error",
            isLoading: false,
            autoClose: 5000
        });
        toastId = null;
    }
}

async function refreshAccessToken() {
    try {
        const authStore = useAuthStore(); // Access authStore
        let data = new FormData();
        data.append('grant_type', 'refresh_token');
        data.append('client_id', process.env.VUE_APP_CLIENT_ID);
        data.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);
        data.append('refresh_token', authStore.refreshToken);
        data.append('scope', '');

        const config = {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' }
        };
        const response = await axios.post(`${baseURL}/oauth/token`, data, config);
        const newAccessToken = response.data.access_token;
        const newRefreshToken = response.data.refresh_token;
        await authStore.setTokens(newAccessToken, newRefreshToken); // Update the tokens in authStore
        return true;
    } catch (error) {
        toast.error('You have been kicked out by another logged in device!');
        return false;
    }
}

const httpGet = (url, config = {}) => {
    return axiosInstance.get(url, config);
};
const httpPost = (url, data = {}, tId = null, config = {}) => {
    toastId = tId;
    return axiosInstance.post(url, data, config);
};

export { httpGet, httpPost };
